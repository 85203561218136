<template>
  <div>
    <v-card>
      <v-divider class="ma-0"></v-divider>

      <v-card-text class="pt-7">
        <v-alert type="info" text>
          {{ $t("erp.lang_simpleGoodsIssueSearchItemsMSG") }}
        </v-alert>
        <div class="text-subtitle-1 text-medium-emphasis mt-6">
          {{ $t("erp.lang_searchbyitemnameoritemID") }}
        </div>
        <v-text-field
          v-model="search"
          :loading="loading"
          :data-layout="KEYBOARD.KEYSETS.NORMAL"
          @focus="showTouchKeyboard"
          dense
          outlined
          clearable
          @keyup.enter="searchGoods($event, true)"
          @click:clear="clearSearch()"
        />

        <v-data-table
          v-if="Array.isArray(items) && items.length > 0"
          :items="items"
          :headers="datatableHeaders"
          @click:row="goodsDetails"
          class="elevation-1"
        >
          <template v-slot:item.unitPrice="{ item }">
            {{ item.unitPrice | currency }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </div>
</template>

<style></style>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";

export default {
  name: "SimpleGoodsIssueComponent",

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      items: [],
      search: null,
      awaitingSearch: null,
      // --- Datatable ---
      datatableHeaders: [
        { text: this.$t("erp.lang_ware_create_id"), value: "ean" },
        { text: this.$t("generic.lang_name"), value: "name" },
        { text: this.$t("erp.lang_totalQTY"), value: "quantity" },
        { text: this.$t("erp.lang_ware_UnitPrice"), value: "unitPrice" },
      ],
    };
  },
  watch: {
    search(value) {
      if (!(value && value.length > 0)) return;

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(async () => {
        await this.searchGoods(value, false);
      }, 1000);
    },
  },

  methods: {
    clearSearch() {
      this.search = null;
    },
    goodsDetails(entry) {
      this.$router.replace("/erp/storage/SimpleGoodsIssue/details/" + entry.id);
    },
    async searchGoods(evt, isRedirect) {
      this.loadingItems = true;

      let search;

      if (isRedirect) {
        search = evt.target.value;
      } else {
        search = evt;
      }

      let params = {
        sEcho: 1,
        iDisplayStart: 0,
        iDisplayLength: 100,
        sSearch: search,
      };

      let items = [];
      this.items = [];

      await this.axios
        .get(ENDPOINTS.DATATABLES.ERP.ITEMS, {
          params: params,
        })
        .then((res) => {
          items = [...res.data.aaData];

          items = items.map((elm) => {
            return {
              id: elm[0],
              ean: elm[1],
              name: elm[2],
              quantity: elm[5],
              unitPrice: elm[6],
            };
          });

          if (isRedirect) {
            let item = items.find(({ ean }) => ean === search);

            if (item) {
              this.$router.replace(
                "/erp/storage/SimpleGoodsIssue/details/" + item.id
              );
            }
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });

          items = [];
        })
        .finally(() => {
          this.loadingItems = false;
        });

      if (Array.isArray(items) && items.length > 0) this.items = [...items];
      else this.items = [];
    },
  },
};
</script>

<style lang="css">
.v-data-table tr:hover {
  cursor: pointer;
}
</style>
