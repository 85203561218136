<template>
    <div class="mb-3 card transparent">

        <div>
            <v-tabs v-model="tab" class="rounded-t">
                <v-tab key="list">
                    {{ $t('erp.lang_nav_voucherCards') }}
                </v-tab>
                <v-tab key="listOld">
                    {{ $t('erp.lang_oldGiftCardist') }}
                </v-tab>
                <v-tab key="bulk">{{ $t('erp.lang_createGiftcardCodes') }}</v-tab>
                <v-tab key="marketing" v-if="$store.getters['permissions/checkPermission']('marketingGiftCards') && $store.getters['permissions/checkModule'](82)">{{
                    $t('erp.lang_marketingGiftcard') }}</v-tab>
                <v-tab key="settings">{{ $t('settings.lang_settings') }}</v-tab>
                <v-tabs-items touchless v-model="tab">
                    <v-tab-item key="list">
                        <!-- Table -->

                        <Datatable v-if="showTable" ref="giftCardDatatable"
                            :api-endpoint="ENDPOINTS.DATATABLES.ERP.GIFTCARD" :datatable-headers="datatableHeaders"
                            :excel-columns="excelColumns" excel-file-name="Gutscheinkarten" @displayEntry="displayEntry"
                            show-display-buttons />
                    </v-tab-item>
                    <v-tab-item key="listOld">

                        <!-- Table -->

                        <Datatable v-if="showTable" ref="giftCardDatatable"
                            :api-endpoint="ENDPOINTS.DATATABLES.ERP.GIFTCARDOLD"
                            :datatable-headers="datatableHeaders.filter(object => { return object.value !== 'originOfSale' && object.value !== 'minRedeemValue'; })"
                            :excel-columns="excelColumns" excel-file-name="Gutscheinkarten" />

                        <!-- Table: Click -->
                        <v-container v-if="showUpdate" fluid>
                            <v-layout>
                                <v-flex md12>
                                    <div>
                                        <v-layout>
                                            <v-flex md4 mr-2 ml-2>
                                                <v-text-field outlined @focus="showTouchKeyboard"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="giftCardBalance"
                                                    :label="$t('erp.lang_GiftCardValue')" autocomplete="off"
                                                    disabled></v-text-field>
                                            </v-flex>
                                            <v-flex md4 mr-2 ml-2>
                                                <v-text-field outlined @focus="showTouchKeyboard"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="issuingDate"
                                                    :label="$t('erp.VoucherIssuingDate')" autocomplete="off"
                                                    disabled></v-text-field>
                                            </v-flex>
                                            <v-flex md4 mr-2 ml-2>
                                                <v-text-field outlined @focus="showTouchKeyboard"
                                                    :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="lastUse"
                                                    :label="$t('generic.lang_lastchange')" autocomplete="off"
                                                    disabled></v-text-field>
                                            </v-flex>
                                        </v-layout>

                                        <v-layout>
                                            <v-flex class="text-right">
                                                <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev')
                                                    }}
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="bulk">
                        <v-container fluid>
                            <v-layout>
                                <v-flex>
                                    <p><b>{{ $t('erp.lang_createGiftcardCodesSubject') }}</b></p>
                                    <p>{{ $t('erp.lang_createGiftcardCodesBody') }}</p>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex md8 mr-2 ml-2>
                                    <v-text-field outlined type="number" @focus="showTouchKeyboard"
                                        :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="giftCardCodeQty"
                                        :label="$t('erp.lang_createGiftcardCodesLabel')" autocomplete="off"
                                        required></v-text-field>
                                </v-flex>
                                <v-flex md4 mr-2 ml-2>
                                    <v-btn text color="error" @click="downloadGiftCardCodes">{{
                                        $t('generic.lang_download') }}</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item key="marketing" v-if="$store.getters['permissions/checkPermission']('marketingGiftCards') && $store.getters['permissions/checkModule'](82)">
                        <v-container fluid>
                            <v-layout>
                                <v-flex>
                                    <p>
                                        <b>{{ $t('erp.lang_createMarketingGiftcardCodesSubject') }}</b>
                                    </p>
                                    <p>{{ $t("erp.lang_createGiftcardCodesBody") }}</p>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex mr-2 ml-2>
                                    <v-text-field outlined type="number" @focus="showTouchKeyboard"
                                        :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="giftCardCodeQty"
                                        :label="$t('erp.lang_createGiftcardCodesLabel')" autocomplete="off"
                                        required></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex md6 mr-2 ml-2>
                                    <v-currency-field v-model="giftCardValue" :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                        @focus="showTouchKeyboard" :label="$t('erp.lang_GiftCardValue')"
                                        :suffix="this.settings.settings.currencySign" :auto-decimal-mode="true"
                                        :allow-negative="false" outlined required></v-currency-field>
                                </v-flex>

                                <v-flex md6 mr-2 ml-2>
                                    <v-currency-field v-model="giftCardMinRedeemableValue"
                                        :data-layout="KEYBOARD.KEYSETS.NUMERIC" @focus="showTouchKeyboard"
                                        :label="$t('erp.lang_minimumRedeemableValue')"
                                        :suffix="this.settings.settings.currencySign" :auto-decimal-mode="true"
                                        :allow-negative="false" outlined required></v-currency-field>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="downloadMarketingGiftCardCodes">{{
                                        $t('generic.lang_download') }}</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="settings">
                        <v-container fluid><br><br>
                            <v-layout>
                                <v-flex md12 mr-2 ml-2>

                                    <v-select :items="itemGroups" :label="$t('generic.lang_waregroup')" item-text="name"
                                        item-value="id" outlined v-model="giftCardItemGroup"></v-select>

                                </v-flex>

                            </v-layout>

                            <v-layout>
                                <v-flex class="text-right">
                                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                                    <v-btn color="success" @click="setSettings">{{
                                        $t('generic.lang_apply') }}
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </div>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
                :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
        </div>
    </div>
</template>


<script>
import Datatable from "../../datatable/Datatable";
import { ENDPOINTS } from '../../../config'
import { Events } from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import { mapState } from "vuex";

export default {
    components: {
        Datatable
    },
    mixins: [mixin],
    data() {
        return {
            ENDPOINTS,
            datatableHeaders: [
                {
                    text: this.$t('generic.lang_id'),
                    align: 'left',
                    value: "id"
                },
                { text: this.$t('erp.lang_voucherIssuingDate'), value: "issueDate", sort: "desc" },
                { text: this.$t('erp.lang_voucherIssuerID'), value: "issueID" },
                { text: this.$t('erp.lang_GiftCardValue'), value: "value" },
                { text: this.$t('customers.lang_cust_prename'), value: "prename" },
                { text: this.$t('customers.lang_cust_name'), value: "lastname" },
                { text: this.$t('customers.lang_customerID'), value: "custID" },
                { text: this.$t('generic.lang_lastchange'), value: "lastChange" },
                { text: this.$t('generic.lang_originOfSale'), value: "originOfSale" },
                { text: this.$t('erp.lang_minimumRedeemableValue'), value: "minRedeemValue" },
            ],
            excelColumns: [
                { label: this.$t('generic.lang_id'), field: "id" },
                { label: this.$t('erp.lang_voucherIssuingDate'), field: "issueDate" },
                { label: this.$t('erp.lang_voucherIssuerID'), field: "issueID" },
                { label: this.$t('erp.lang_GiftCardValue'), field: "value" },
                { label: this.$t('customers.lang_cust_prename'), field: "prename" },
                { label: this.$t('customers.lang_cust_name'), field: "lastname" },
                { label: this.$t('customers.lang_customerID'), field: "custID" },
                { label: this.$t('generic.lang_lastchange'), field: "lastChange" },
                { label: this.$t('generic.lang_originOfSale'), field: "originOfSale" },
                { label: this.$t('erp.lang_minimumRedeemableValue'), field: "minRedeemValue" },
            ],
            id: null,
            giftCardBalance: "",
            issuingDate: null,
            lastUse: null,
            giftCardBalanceAdd: "",
            customerIdAdd: null,
            giftCardCodeQty: 0,
            giftCardValue: 0,
            giftCardMinRedeemableValue: 0,
            search: "",
            addSearch: null,
            items: [],
            loading: false,
            showTable: true,
            showCreate: false,
            showUpdate: false,
            tab: 0,
            giftCardData: [],
            dataTable: null,
            itemGroups: [],
            giftCardItemGroup: null,
        }
    },
    computed: {
        ...mapState([
            'api', 'settings'
        ])
    },
    watch: {
        addSearch(val) {
            val && val !== this.select && this.querySelections(val)
        },
        tab: function () {
            if (this.tab === 1) {
                this.showTable = true;
                this.showUpdate = false;
            }
        }
    },

    async mounted() {
        this.getSettings();
    },

    methods: {
        getSettings() {
            this.axios.post(ENDPOINTS.ERP.GIFTCARD.GETITEMGROUP, {
            }).then((res) => {
                if (res.status === 200) {

                    this.giftCardItemGroup = res.data.formfillData.textFields.giftCardItemGroup;
                    this.itemGroups = res.data.formfillData.textFields.itemgroups.map(el => ({
                        id: el[0],
                        name: el[1],
                    }));
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(() => {

            })
        },
        setSettings() {
            this.axios.post(ENDPOINTS.ERP.GIFTCARD.UPDATEITEMGROUP, {
                giftCardItemGroup: this.giftCardItemGroup
            }).then((res) => {
                if (res.status === 200) {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_actionSuccessful'),
                        color: "success"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(() => {

            })
        },
        displayEntry(entry) {
            this.$router.push('/erp/baseData/giftCard/details/' + entry.id);
        },


        updateData: function () {
            let self = this;
            this.axios.post(ENDPOINTS.ERP.GIFTCARD.UPDATE, {
                editGiftCardID: this.id,
                giftCardName: this.text
            }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_actionSuccessful'),
                        color: "success"
                    });

                    self.resetData();
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        resetData: function () {
            this.id = null;
            this.giftCardBalance = "";
            this.issuingDate = null;
            this.lastUse = null;
            this.giftCardBalanceAdd = "";
            this.customerIdAdd = null;
            this.showCreate = false;
            this.showUpdate = false;
            this.showTable = true;
        },
        showList: function () {
            this.resetData();
            this.tab = 0;
        },
        querySelections: function (searchText) {
            let self = this;
            self.loading = true;
            self.axios.get(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + searchText)
                .then((res) => {
                    let items = res.data.results;
                    for (let i = 0; i < items.length; i++) {
                        let item = items[i];
                        self.items.push({ text: item.text, value: item.id });
                    }

                    self.loading = false;
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: self.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
        },
        downloadGiftCardCodes: function () {
            let apiServerIP = process.env.VUE_APP_API_HOST;
            if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
                let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
            }
            window.open(apiServerIP + "/get/reports/erp/giftCardCodes/?qty=" + this.giftCardCodeQty + "&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");
        },
        downloadMarketingGiftCardCodes: function () {
            let apiServerIP = process.env.VUE_APP_API_HOST;
            if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
                let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
            }
            window.open(apiServerIP + "/create/erp/giftCardCodesBulkMarketing/?qty=" + this.giftCardCodeQty + "&value=" + this.giftCardValue + "&minRedeemValue=" + this.giftCardMinRedeemableValue + "&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");
        },
    },
}
</script>