import axios from "axios";
import store from "../index";

let syncTimer;

export default {
  namespaced: true,

  state: {
    lastSync: 0,
    lastRow: 0,
    currentSyncing: false,
    isHybridServerOffline: false,
    isHybridServerOfflineOrInStop: false,
  },

  mutations: {
    IS_HybridServerOffline(state, payload) {
      state.isHybridServerOffline = payload;
    },
    IS_HybridServerOfflineOrInStop(state, payload) {
      state.isHybridServerOfflineOrInStop = payload;
    },
    start(state, payload) {
      state.currentSyncing = false;

      let versionToCheck = 260; // -> ALSO SET in store/modules/app.js

      // ---- STEP 1 ----
      //CHECK IF WE NEED TO RESET VUEX PERSIST STATES
      if (store.state.app.vuexStoreVersion !== versionToCheck) {
        store.dispatch("backgroundSync/fullSync").then(() => {
          store.commit("app/updateVuexStoreVersion", versionToCheck);
        });
      }

      //SET LAST API SYNC
      if (payload.lastUpdate !== null && payload.lastRow !== null) {
        state.lastSync = payload.lastUpdate;
        state.lastRow = payload.lastRow;
      }

      syncTimer = setInterval(() => {
        if (store.state.app.initDataLoaded && store.state.user.loggedIn) {
          state.currentSyncing = true;

          if (store.getters["permissions/checkModule"](81)) {
            axios
              .post("/3rdPartyIntegrations/mergeport/get/orders/")
              .catch((err) => {
                console.log(err);
              });
          }

          axios
            .post("get/dbSync/", {
              lastSync: state.lastSync,
              lastRow: state.lastRow,
            })
            .then((res) => {
              //show dialog to inform the user if hybrid is offline
              /*window.store.commit('hybrid/turnOfflineForCloudServer');
                        window.store.commit('hybrid/turnOfflineForHybridServer');*/
              window.store.commit("hybrid/checkStatus", res.data);
              /*  if (res.data.hybridStatus && res.data.hybridStatus.status && window.store.getters["permissions/checkHybridEnabled"]("Hybrid")) {
                              //calc current time and last hybrid sync diff
                              let diff = (Math.round(new Date().getTime()) - (res.data.hybridStatus.lastHybridContact * 1000)) / 60000;
                              console.log(diff >= 2 && diff < (60 * 24 * 7))
                              //check if the hybrid server is offline 10 minutes ago
                              if (diff >= 2 && diff < (60 * 24 * 7)) {
                                  state.isHybridServerOffline = true;
                                  return
                              } else if (diff >= (60 * 24 * 7)) {
                                  state.isHybridServerOfflineOrInStop = true;
                                  return;
                              }
                          }*/
              if (res.data === "FULLSYNC") {
                store.dispatch("backgroundSync/fullSync");
              } else if (typeof res.data.syncData === "object") {
                Array.from(res.data.syncData).forEach(function (syncElements) {
                  const syncID = syncElements.transactionID;
                  const syncTransaction = JSON.parse(syncElements.transaction);
                  const syncTimestamp = syncElements.transactionTS;
                  const syncTransactionType = syncElements.transactionType;

                  if (syncElements.transactionRealm === "POSLAYOUTTEMPLATES") {
                    store.commit(
                      "posLayoutTemplates/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "SETTINGS") {
                    store.dispatch("settings/initSettings", null, {
                      root: true,
                    });
                  }

                  if (syncElements.transactionRealm === "POSBUTTONSTEMPLATE") {
                    store.dispatch("posButtonsTemplates/init", null, {
                      root: true,
                    });
                  } else if (
                    syncElements.transactionRealm === "ITEM" ||
                    syncElements.transactionRealm === "ITEMVARIATION"
                  ) {
                    store.commit(
                      "items/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "ITEMSTATE") {
                    store.commit(
                      "items/backgroundSync.itemState",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "WAREGROUP") {
                    store.commit(
                      "itemgroups/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "FREETEXTTAG") {
                    store.commit(
                      "freetexttags/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "GARNISH") {
                    store.commit(
                      "extras/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "GARNISHTAB") {
                    store.commit(
                      "extratabs/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "DEPOSIT") {
                    store.commit(
                      "deposit/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "COURSE") {
                    store.commit(
                      "courses/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "MEALSIZE") {
                    store.commit(
                      "mealsizes/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "PRINTER") {
                    store.commit(
                      "printer/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "VOIDREASON") {
                    store.commit(
                      "voidreasons/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (syncElements.transactionRealm === "PAYMENTS") {
                    store.commit(
                      "payments/backgroundSync",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (
                    syncElements.transactionRealm === "FISCAL_DE_DEVICES"
                  ) {
                    store.commit(
                      "tse/backgroundSyncDevices",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  } else if (
                    syncElements.transactionRealm === "FISCAL_DE_CLIENTS"
                  ) {
                    store.commit(
                      "tse/backgroundSyncClients",
                      {
                        type: syncTransactionType,
                        transaction: syncTransaction,
                      },
                      {
                        root: true,
                      }
                    );
                  }

                  state.lastSync = parseInt(syncTimestamp);
                  state.lastRow = parseInt(syncID);
                });
              }

              state.currentSyncing = false;
            })
            .catch(() => {
              state.currentSyncing = false;
            });
        }
      }, 30000); // was 15000
    },
    stop() {
      clearInterval(syncTimer);
    },
  },

  actions: {
    async start({ commit }, data) {
      //CHECK IF WE NEED TO INIT FIRST
      if (!store.state.app.initDataLoaded) {
        await store.dispatch("itemgroups/init");
        await store.dispatch("items/init");
        await store.dispatch("courses/init");
        await store.dispatch("deposit/init");
        await store.dispatch("extratabs/init");
        await store.dispatch("extras/init");
        await store.dispatch("freetexttags/init");
        await store.dispatch("payments/init");
        await store.dispatch("mealsizes/init");
        await store.dispatch("posLayoutTemplates/init");
        await store.dispatch("posButtonsTemplates/init");
        await store.dispatch("settings/initSettings");
        await store.dispatch("voidreasons/init");
        await store.dispatch("printer/initPrinters");
        await store.dispatch("tse/init");

        commit("app/updateInitDataLoadedState", true, { root: true });
        commit("start", data);
      } else {
        commit("start", data);
      }
    },
    fullSync({ commit }) {
      //FIRST WE NEED TO GET LAST SYNC VALUES
      axios.post("get/3pos/").then((res) => {
        if (res.data !== "LOCK") {
          //SET APP IN LOADING STATE
          commit("app/updateInitDataLoadedState", false, { root: true });

          //START SYNCING
          store
            .dispatch("backgroundSync/start", {
              lastUpdate: res.data.dbLastUpdate,
              lastRow: res.data.dbLastSyncRow,
            })
            .then(() => {});
        }
      });
    },
  },

  getters: {
    IsHybridServerOffline: (state) => {
      return state.isHybridServerOffline;
    },
    IsHybridServerOfflineOrInStop: (state) => {
      return state.isHybridServerOfflineOrInStop;
    },
  },
};
