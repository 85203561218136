<template>
  <div>
    <b-card no-body class="transparent">
      <b-card-header header-tag="header" v-b-toggle.accordion4
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']" role="tab">
        <v-row>
          <v-col>
            <div class="pa-5 d-block">
              {{ this.$t("generic.lang_gui_messages") }}
            </div>
          </v-col>

          <v-col align="end" class="pa-5">
            <v-btn depressed color="success" @click="updateMessages" :loading="loadingLayout">
              {{ $t("generic.lang_save") }}
            </v-btn>
          </v-col>
        </v-row>
      </b-card-header>

      <b-card-body>
        <v-row class="pa-0" no-gutters>
          <v-col class="" cols="12">
            <label>{{ $t('generic.lang_subsectionText') }}</label>
            <v-textarea type="number" outlined @focus="showTouchKeyboard" counter maxlength="126"
              :data-layout="KEYBOARD.KEYSETS.NORMAL" dense v-model="subSectionText"
              autocomplete="off" />
          </v-col>
        </v-row>
      </b-card-body>
    </b-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input" :layout="touchKeyboard.layout" :options="touchKeyboard.options"
        class="internalWidth" id="onScreenKeyboard" v-if="touchKeyboard.visible" />
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "Messages",
  mixins: [mixin],
  data() {
    return {
      loadingLayout: false,
      subSectionText: null
    };
  },
  computed: {

  },
  methods: {
    updateMessages() {
      this.loadig = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.CUSTOMETEXT.UPDATE, {
          subSectionText: this.subSectionText
        })
        .then((res) => {
          if (res.status === 200) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loadig = false;
        });
    },
    getMessages() {
      this.loadig = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.CUSTOMETEXT.GET,)
        .then((res) => {
          if (res.status === 200) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred") + " " + err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loadig = false;
        });
    },
  },
  mounted() {
    this.getMessages();
  }
};
</script>

<style scoped>
.quill-editor {
  height: 300px !important;
}
</style>