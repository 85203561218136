<template>
  <v-container fluid>
    <v-card elevation="6">
      <v-card-title
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-header"
      >
        <span>
          {{ (this.item && this.item.ware_name) || "" }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation onsubmit="return false">
          <v-row>
            <v-col
              cols="12"
              class="mt-2"
              style="font-size: medium; line-height: 55px"
            >
              <div>
                <b> {{ $t("erp.lang_ware_create_id") }}: </b>
                {{ item.ware_ID }}
              </div>
              <div>
                <b> {{ $t("generic.lang_description") }}: </b>
                {{ item.editItemDescr }}
              </div>
              <div>
                <b> {{ $t("erp.lang_stockinventory") }}: </b>
                {{ item.editItemStorageQTY }}
              </div>
              <div>
                <b> {{ $t("erp.lang_netPurchasePricePerUnit") }}: </b>
                {{ item.ware_buypriceNet | currency }}
              </div>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="item.targetStorageID"
                :items="storageSelect"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                :label="$t('erp.lang_targetStore')"
                outlined
                required
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="item.incommingWareCount"
                @focus="showTouchKeyboard"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                type="number"
                min="0"
                :label="$t('erp.lang_incommingWareCount')"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <div class="text-subtitle-1 text-medium-emphasis">
                {{
                  $t(
                    "erp.lang_buyingPricePerUnit_netPriceAutomaticallyAdjustsToAverage"
                  )
                }}
              </div>
              <v-text-field
                v-model="item.netBuyPrice"
                @focus="showTouchKeyboard"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                type="number"
                outlined
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="isLoading"
          :loading="isLoading"
          @click="save"
        >
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidthExpanded"
      />
    </div>
  </v-container>
</template>

<script>
//configs
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import { Events } from "@/plugins/events";
import Birthdaypicker from "@/components/common/birthdaypicker";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import Template from "@/components/settings/customerdisplay/Template";

export default {
  name: "SimpleGoodsReceiptDetailsComponent",
  components: { Template, Birthdaypicker, BaseDateInput },

  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      isLoading: false,
      item: [],
      storageSelect: [],
    };
  },
  methods: {
    loadStorages: async function () {
      let self = this;

      await self.axios
        .get(ENDPOINTS.ERP.STORAGES.GET)
        .then((res) => {
          if (res.status === 200) {
            res.data.storageInformation.forEach((row) => {
              this.storageSelect.push({ name: row[1], id: parseInt(row[0]) });
            });

            this.item.targetStorageID = this.storageSelect[0].id;
          } else {
            Events.$emit("showSnackbar", {
              message: self.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: self.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        });
    },
    loadData: async function () {
      this.isLoading = true;
      this.id = parseInt(this.$parent.id);

      await this.axios
        .post(ENDPOINTS.ERP.EDITITEM.GET, {
          editItemID: this.id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.item = res.data.formfillData.textFields;
            this.item.netBuyPrice = 0;
            this.item.incommingWareCount = 0;
          }
        })
        .catch((e) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    save() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      let id = parseInt(this.$parent.id);

      this.axios
        .post(ENDPOINTS.ERP.GOODSRECEIPT.UPDATE_SIMPLE_GOODS, {
          id: id,
          netBuyPrice: this.item.netBuyPrice,
          targetStorageID: this.item.targetStorageID,
          incommingWareCount: this.item.incommingWareCount,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_savedSuccessfully"),
              color: "success",
            });

            this.$router.push("/erp/storage/GoodsReceipt");
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    await this.loadData();
    await this.loadStorages();
  },
};
</script>

<style>
.colors {
  width: 10px !important;
  height: 25px !important;
}
</style>
