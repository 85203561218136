<template>
    <v-dialog :value="showSignatureDialog" fullscreen persistent transition="dialog-bottom-transition" hide-overlay>
        <v-card>
            <v-toolbar color="primary" dark>
                <v-toolbar-title>{{ $t('generic.lang_invoiceSignature') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <div ref="signatureContainer" class="signature-container"></div>
            </v-card-text>

            <div class="position-fixed w-100" style="bottom:0;">
                <v-divider/>
                <v-card-actions>
                    <v-btn color="error" text @click="cancel">{{$t('generic.lang_cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="clearCanvas">{{$t('generic.lang_clear')}}</v-btn>
                    <v-btn color="success" text @click="submitSignature()" :disabled="!isValidCanvas">Submit</v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import Konva from "konva";

export default {
    name: "SignatureDialog",
    data() {
        return {
            showSignatureDialog: false,
            dialogPromise: null,
            stage: null,
            layer: null,
            lowestRatio: 0.2,
        };
    },
    computed: {
        isValidCanvas(){
            return this.layer?.hasChildren() || false;
        }
    },
    methods: {
        initializeCanvas() {
            const container = this.$refs.signatureContainer;

            // Create a new stage and layer
            this.stage = new Konva.Stage({
                container: container,
                width: container.offsetWidth,
                height: container.offsetHeight,
            });

            this.layer = new Konva.Layer();
            this.stage.add(this.layer);

            // Add a drawing line
            let line = null;

            this.stage.on("mousedown touchstart", () => {
                line = new Konva.Line({
                    stroke: "black",
                    // tension: 1,
                    bezier: true,
                    hitStrokeWidth: 0,
                    listening: false,
                    strokeWidth: 2,
                    globalCompositeOperation: "source-over",
                    points: [],
                    lineCap: "round",
                    lineJoin: "round",
                });
                this.layer.add(line);
                line.points([]); // Reset the points
            });

            this.stage.on("mousemove touchmove", (e) => {
                if (!e.evt.buttons && e.evt.type !== "touchmove") return;
                const pos = this.stage.getPointerPosition();
                line.points([...line.points(), pos.x, pos.y]);
                this.layer.batchDraw();
            });

            this.stage.on("mouseup touchend", () => {
                this.layer.batchDraw();
            });
            this.$forceUpdate();
        },
        resetCanvas(){
            this.stage.off("mousedown touchstart")
            this.stage.off("mousemove touchmove")
            this.stage.off("mouseup touchend")
            this.stage = null;
            this.layer = null;
        },
        clearCanvas() {
            this.layer.destroyChildren();
            this.layer.draw();
        },

        getBase64SizeInKB(base64) {
            const sizeInBytes = Math.ceil((base64.length - "data:image/png;base64,".length) * 3 / 4);
            return Math.round((sizeInBytes/1024) * 100) / 100;
        },

        submitSignature(ratio = 0.7) {
            // Export canvas as a PNG image
            const boundingBox = this.layer.getClientRect({relativeTo: this.stage});
            let base64Data = this.layer.toDataURL({...boundingBox, pixelRatio: ratio });
            
            if(this.getBase64SizeInKB(base64Data) > 30){
                const newRatio = ratio - 0.1;
                if( newRatio < this.lowestRatio){
                    // warning;
                    return;
                }
                return this.submitSignature(newRatio);
            }

            if (this.dialogPromise){
                this.dialogPromise({
                    signature: base64Data,
                    success: true,
                    reason: undefined,
                });
                
                this.showSignatureDialog = false;
            }

        },

        cancel() {
            if (this.dialogPromise){
                this.dialogPromise({
                    reason: 'cancel',
                    success: false,
                });
            }
            this.showSignatureDialog = false;
        },

        open() {
            return new Promise((resolve) => {
                this.dialogPromise = resolve;
                this.showSignatureDialog = true;
            });
        },
    },
    watch: {
        showSignatureDialog(val) {
            if (val) {
                 this.$nextTick(()=>{
                    setTimeout(()=>{
                        this.initializeCanvas();
                    })
                 })
            } else {
                this.clearCanvas();
                this.resetCanvas();
            } 

        },
    },
    mounted(){
    }
};
</script>

<style>
.signature-container {
    width: 100%;
    height: 70vh;
    background: #f9f9f9;
    border: 1px solid #ccc;
    overflow: hidden;
    position: relative;
}
</style>