<template>
  <b-card no-body class="transparent">
    <b-card-header header-tag="header" v-b-toggle.accordion1 :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']"
      role="tab">
      <v-row>
        <v-col>
          <div class="pa-5 d-block">
            {{ $t("tablebee.lang_theme_colors") }}
          </div>
        </v-col>

        <v-col align="end" class="pa-5">
          <v-btn depressed color="success" @click="updateColor" :loading="loadig">
            {{ $t("generic.lang_save") }}
          </v-btn>
        </v-col>
      </v-row>
    </b-card-header>

    <b-card-body>
      <v-row>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_subSectionBg") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.subSectionBg" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_showcaseSectionBg") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.showcaseSectionBg" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_headerInPrepBg") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.headerInPrepBg" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_headerReadyBg") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.headerReadyBg" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_ordersReadyBg") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.ordersReadyBg" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_ordersInPrepBg") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.ordersInPrepBg" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_readyCardColor") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.readyCardColor" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="main-card mb-3 card shadow-sm">
            <div class="pa-0 ma-0 dropdown-menu-header">
              <div class="card-header bg-info text-white">
                {{ $t("generic.lang_inPrepCardColor") }}
              </div>
            </div>
            <div class="card-body pa-0">
              <v-color-picker mode="hexa" v-model="colors.inPrepCardColor" class="ma-0" width="100%" hide-canvas
                show-swatches swatches-max-height="100"></v-color-picker>
            </div>
          </div>
        </v-col>
      </v-row>
    </b-card-body>
  </b-card>
</template>
  
  <script>
  import { ENDPOINTS } from "@/config";
  import { Events } from "@/plugins/events";
  
  export default {
    name: "ThemeColors",
    data() {
      return {
        loadig: false,
        colors: {
          subSectionBg: '',
          showcaseSectionBg: '',
          headerInPrepBg: '',
          headerReadyBg: '',
          ordersReadyBg: '',
          ordersInPrepBg: '',
          readyCardColor: '',
          inPrepCardColor: '',
        },
      };
    },
    methods: {
      updateColor() {
        this.loadig = true;
  
        this.axios
          .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.COLORS.UPDATE, {
            ...this.colors
          })
          .then((res) => {
            if (res.status === 200) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_actionSuccessful"),
                color: "success",
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred") + " " + err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loadig = false;
          });
      },
      getColor() {
        this.loadig = true;
  
        this.axios
          .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.COLORS.GET)
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_actionSuccessful"),
                color: "success",
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred") + " " + err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loadig = false;
          });
      },
    },
    mounted(){
      this.getColor();
    }
  };
  </script>
  
  <style scoped>
  .dropdown-menu-header {
    z-index: 1 !important;
  }
  </style>
