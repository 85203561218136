<template>
    <b-card no-body class="transparent">
        <b-card-header header-tag="header" v-b-toggle.accordion1
            :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'p-1']" role="tab">
            <v-row>
                <v-col>
                    <div class="pa-5 d-block">
                        {{ $t("generic.lang_globalSettings") }}
                    </div>
                </v-col>

                <v-col align="end" class="pa-5">
                    <v-btn depressed color="success" @click="updateSettings" :loading="loading">
                        {{ $t("generic.lang_save") }}
                    </v-btn>
                </v-col>
            </v-row>
        </b-card-header>

        <b-card-body>
            <v-row>
            <v-col cols="12">
                <v-switch v-model="pickupmon_disableDisplayingOfOrders" :label="$t(
                    'generic.lang_disableDisplayingOfOrdersWithoutOrderbonOrKitchenmonitirAssignment'
                )
                    " hide-details :disabled="loading" :loading="loading"></v-switch>
            </v-col>

            <v-col cols="8">
                <label>{{ $t('generic.lang_orderTimeOut') }}</label>
                <v-text-field v-model.number="pickupmon_OrdersTimeOut" type="number" outlined dense  />
            </v-col>
        </v-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import { Events } from "@/plugins/events";

export default {
    name: "GeneralSettings",
    data() {
        return {
            loading: false,
            pickupmon_disableDisplayingOfOrders: false,
            pickupmon_OrdersTimeOut: null,
        }
    },
    methods: {
        getSettings() {
            this.loading = true;

            this.axios
                .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.GENERAL.GET)
                .then((res) => {
                    if (res.data.success) {
                        this.pickupmon_disableDisplayingOfOrders =
                            res.data.pickupmon_disableDisplayingOfOrders;
                        this.pickupmon_OrdersTimeOut =
                            res.data.pickupmon_OrdersTimeOut;
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_errorOccurred"),
                            color: "error",
                        });
                    }
                })
                .catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        updateSettings() {
            this.loading = true;

            this.axios
                .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.GENERAL.UPDATE, {
                    pickupmon_disableDisplayingOfOrders:
                        this.pickupmon_disableDisplayingOfOrders,
                    pickupmon_OrdersTimeOut:
                        this.pickupmon_OrdersTimeOut,
                })
                .then((res) => {
                    if (res.data.success) {
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_ActionSuccessful"),
                            color: "success",
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t("generic.lang_errorOccurred"),
                            color: "error",
                        });
                    }
                })
                .catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: this.$t("generic.lang_errorOccurred"),
                        color: "error",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getSettings();
    },
}

</script>