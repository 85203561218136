<template>
  <div style="height:calc(100% - 360px) !important;">
    <v-card flat>
      <v-toolbar :style="{borderRadius: (posType === 'gastro' ? '0 0 9px 9px' : 0), marginTop: '10px'}">
        <v-toolbar-title>
          <span class="invoiceTitle" v-if="!showCustomerChip && !showPagerNo"
                style="text-transform: capitalize;">{{ $t('accounting.lang_bill') }}</span>

          <v-chip v-if="showCustomerChip" v-model="showCustomerChip">
            <v-btn v-if="!pos.gastro.isDeliveryService" x-small fab color="black lighten-1" width="13" height="13"
                   class="ma-0" elevation="0" @click="removeCustomer">
              <v-icon x-small color="white" style="margin-top: 2px !important;">
                mdi-close
              </v-icon>
            </v-btn>

            <span v-if="(pos[posType].customer.first_name && pos[posType].customer.first_name!=='') || (pos[posType].customer.last_name  && pos[posType].customer.last_name!=='')" style="margin-left: 5px;">
              {{ (pos[posType].customer.first_name ? pos[posType].customer.first_name : '') + ' ' + (pos[posType].customer.last_name ? pos[posType].customer.last_name : '') }}
            </span>
            <span v-else style="margin-left: 5px;">
              {{pos[posType].customer.company}}
            </span>

            <v-avatar style="cursor: pointer;" @click="showCustomerDialog = true">
              <img :src="customerAvatar">
            </v-avatar>
          </v-chip>

          <v-chip v-if="showPagerNo" v-model="showPagerNo" close @click:close="removePagerNo">
            {{ $t('generic.lang_Pagernummer') + " : " + pos["gastro"].pagerNo }}
          </v-chip>
        </v-toolbar-title>

        <v-spacer/>

        <InvoiceCustomerSearch v-if="!isRetailSelfCheckout" :pos-type="posType"/>

        <!-- Tischwechsel -->
        <v-tooltip v-if="posType === 'gastro' && this.$store.getters['permissions/checkPermission']('allowChangeTable')" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="changeTable">
              <v-icon size="35">autorenew</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('generic.lang_changeTable') }}</span>
        </v-tooltip>
      </v-toolbar>

      <div class="overflow-auto" ref="scrollList" id="scrollList" :style="{backgroundColor: (isMultiUser?activeUserColor: '')}">
        <v-subheader v-if="posType === 'gastro'"
                     style="color: #3F6AD8; font-weight: bold; height: 100px; justify-content: center;">
          <p style="border: 1px #3F6AD8 solid; text-transform: uppercase; border-radius: 3px; padding: 4px; margin-top: 20px; width: 200px; text-align: center;" :style="{backgroundColor: ($vuetify.theme.dark?'var(--dark)':'white')}">
            <span v-if="gastroTableName !== 0 && !pos.gastro.isDeliveryService">{{ $t('erp.lang_ToOrder') }}: {{
                $t('generic.lang_table')
              }} {{ gastroTableName }}</span>
            <span v-else-if="pos.gastro.isDeliveryService">{{ $t('generic.lang_lieferdienst') }}</span>
            <span v-else-if="gastroTableName === 0">{{ $t('generic.lang_table_Tresenverkau') }}</span><br>
            <span v-if="pos.gastro.isDeliveryService && delivery && deliveryInfo"> {{ $t('generic.lang_min') }} : {{
                deliveryInfo.minOrder | currency
              }}</span>
          </p>
        </v-subheader>
        <v-subheader v-if="posType === 'wholesaleRetail'"
                     style="color: #3F6AD8; font-weight: bold; height: 100px; justify-content: center;">
          <p style="border: 1px #3F6AD8 solid; text-transform: uppercase; border-radius: 3px; padding: 4px; margin-top: 20px; width: 200px; text-align: center;">
            <span>{{ $t('generic.lang_scannedItems') }}: {{ bookedItemsCount }}</span>
          </p>
        </v-subheader>
        <v-divider class="ma-0"/>
        <v-list v-if="posType === 'gastro'" name="itemList" class="py-0">
          <div id="items-list">
            <InvoiceOpenItem :id="'open_'+item.randomPositionID" v-for="(item, index) in openItems"
                             :key="'open_'+item.randomPositionID"
                             :item="item"
                             :item-index="index">
              {{ item === pos.gastro.selectedOpenItem }}
            </InvoiceOpenItem>
          </div>
        </v-list>

        <v-divider v-if="posType === 'gastro'"/>

        <v-subheader v-if="posType === 'gastro' && (gastroTableName !== 0||pos.gastro.isDeliveryService)"
                     style="margin-top: -20px; color: #3AC47D; font-weight: bold; justify-content: center;">
          <p style="border: 1px #3AC47D solid; border-radius: 3px; text-transform: uppercase; padding: 4px; margin-top: 20px; width: 200px; text-align: center;">
            {{ $t('erp.lang_ordered') }}
          </p>
        </v-subheader>

        <v-list name="itemList" id="booked-items-list" class="py-0">
          <!-- Show Vouchers / Giftcards always on top -->
          <InvoiceOrderedItem :id="'booked_'+item.randomPositionID" v-for="(item, index) in orderedVouchers" @customerVoidItem="customerVoidItem"
                              :key="'booked_'+item.randomPositionID" :quickVoid="selfCheckoutCustomerCanVoide"
                              :item="item" :item-index="index" :pos-type="posType"/>

          <!-- THEN OUR ITEMS -->
          <InvoiceOrderedItem :id="'booked_'+item.randomPositionID" v-for="(item, index) in orderedItemsOnly" @customerVoidItem="customerVoidItem" 
                              :key="'booked_'+item.randomPositionID" :quickVoid="selfCheckoutCustomerCanVoide"
                              :item="item" :item-index="index" :pos-type="posType"/>
        </v-list>
      </div>
    </v-card>

    <InvoiceCustomerInfoDialog v-if="showCustomerChip" :show-dialog="showCustomerDialog"
                               :customer="pos[posType].customer"
                               @close="showCustomerDialog = false"></InvoiceCustomerInfoDialog>
  </div>
</template>

<script>
import InvoiceOpenItem from "./invoice/InvoiceOpenItem";
import InvoiceOrderedItem from "./invoice/InvoiceOrderedItem";
import InvoiceCustomerSearch from "./invoice/InvoiceCustomerSearch";
import {mapGetters, mapState} from "vuex";
import {Events} from "@/plugins/events";
import InvoiceCustomerInfoDialog from "@/components/pos/invoice/InvoiceCustomerInfoDialog";
import {ENDPOINTS} from "../../config";

export default {
  name: "Invoice",

  components: {
    InvoiceCustomerInfoDialog,
    InvoiceCustomerSearch,
    InvoiceOrderedItem,
    InvoiceOpenItem
  },

  props: {
    posType: String,
    isRetailSelfCheckout: Boolean
  },

  data() {
    return {
      customerNoAvatar: require("@/assets/images/no_avatar.png"),
      showCustomerDialog: false,
      tseLoading: false
    }
  },

  computed: {
    ...mapGetters('pos/gastro', {
      gastroOpenItems: 'openItems',
      gastroOrderedItems: 'orderedItems',
      gastroTableName: 'tableName',
    }),
    ...mapGetters('pos/wholesaleRetail', {
      bookedItemsCount: 'bookedItemsCount',
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
      delivery: 'pos/gastro/getDelivery',
      deliveryInfo: 'pos/gastro/getDeliveryInfo',
      selectedItem: 'pos/retail/selectedItem'
    }),
    ...mapState([
      'api',
      'pos',
      'tse',
      'deposit',
      'posLayoutTemplates'
    ]),
    selfCheckoutCustomerCanVoide(){
      return this.isRetailSelfCheckout && Number(this.$store.getters["settings/getSettingValue"]("selfServicePosCustCanVoid")) === 1;
    },
    isMultiUser() {
      const templateID = Number(this.$store.getters['settings/getSettingValue']('posGastroLayoutTemplate'));
      const isDirectSale = this.pos.gastro.table && this.pos.gastro.table.name === 0 && this.posType === 'gastro';
      const isMultiUsers = this.$store.getters['multiUser/allUsers'].length > 1;
      if (templateID > 0) {
        //FIND TEMPLATE
        return this.posLayoutTemplates.templates.some(template => {
          if (templateID === template.id) {
            return (template.multiUser === 1 || template.multiUser === true) && isDirectSale && isMultiUsers
          }
        });
      }
      return false;
    },
    activeUserColor() {
      return this.$store.getters['multiUser/activeUser'].tokenColor  || 'red';
    },
    showCustomerChip() {
      if (this.pos[this.posType].customer !== null) {
        return true;
      }
      return false;
    },
    showPagerNo() {
      if (this.posType === "gastro") {
        if (this.pos.gastro.pagerNo !== "" && this.pos.gastro.pagerNo !== null) {
          return true;
        }
      }
      return false;
    },
    openItems() {
      if (this.posType === "gastro") {
        return this.gastroOpenItems;
      }

      return [];
    },
    orderedItems() {
      if (this.posType === "retail") {
        return this.pos.retail.orderedItems;
      }
      
      if (this.posType === "wholesaleRetail") {
        return this.pos.wholesaleRetail.orderedItems;
      }

      if (this.posType === "gastro") {
        return this.gastroOrderedItems;
      }

      return [];
    },
    orderedItemsOnly() {
      return this.orderedItems.filter((orderedItem) => {
        if (!orderedItem.ean.includes("V$") && !orderedItem.ean.includes("GF$") && !orderedItem.ean.includes("ZMYLE$") && !orderedItem.ean.includes("MYWORLD$")) {
          return true;
        }
      });
    },
    orderedVouchers() {
      return this.orderedItems.filter((orderedItem) => {
        if (orderedItem.ean.includes("V$") || orderedItem.ean.includes("GF$") || orderedItem.ean.includes("ZMYLE$") || orderedItem.ean.includes("MYWORLD$")) {
          return true;
        }
      });
    },
    customerAvatar() {
      if (!this.showCustomerChip)
        return this.customerNoAvatar;

      if (this.pos[this.posType].customer.image !== null)
        if (this.pos[this.posType].customer.image.length > 0)
          return this.pos[this.posType].customer.image;

      return this.customerNoAvatar;
    },
    customerDisplayItems() {
      let items = [];
      if (this.posType === "gastro") {
        items = this.gastroOpenItems;
      } else if (this.posType === "retail") {
        items = this.pos.retail.orderedItems;
      } else if (this.posType === "wholesaleRetail") {
        items = this.pos.wholesaleRetail.orderedItems;
      }

      items = items.map((item) => {
        //CHECK FOR DEPOSIT
        item.depositgroup = null;

        if (item.hasOwnProperty("depositgroupID")) {
          if (item.depositgroupID > 0) {
            //FIND DEPOSIT GROUP
            let depositGroup = this.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (depositGroup)
              item.depositgroup = depositGroup;
          }
        }

        return item;
      });

      return items;
    },
    gastroTableName() {
      if (this.posType !== "gastro")
        return "";

      if (this.pos.gastro.table !== null)
        if (this.pos.gastro.table.hasOwnProperty("name"))
          return this.pos.gastro.table?.name;

      return "";
    }
  },

  destroyed() {
    //KUNDENDISPLAY
    if (this.posType === "retail" ||this.posType === "wholesaleRetail" || (this.posType === "gastro" && this.pos.gastro.table?.name === 0))
      this.$socket.emit("customerdisplay.changePage", {
        cashierID: this.api.auth.cashierID
      });

      if(this.$store.getters["permissions/checkModule"](85)){
        this.$socket.emit("pos.selfcheckout.changePage", {
          cashierID: this.api.auth.cashierID
        });
      }
    window.removeEventListener('resize', this.getWindowWidth);
  },

  sockets: {
    userConnected: function (payload) {
      if (payload.cashierID === this.api.auth.cashierID) {
        if (this.posType === "retail" ||this.posType === "wholesaleRetail" || (this.posType === "gastro" && this.pos.gastro.table?.name === 0))
          this.$socket.emit("customerdisplay.initBookedItems", {
            cashierID: this.api.auth.cashierID,
            items: this.customerDisplayItems,
            posType: this.posType
          });
      }

      if (this.isRetailSelfCheckout && this.posType === "retail") {
        this.$socket.emit("pos.selfcheckout.initBookedItems", {
          cashierID: this.api.auth.cashierID,
          items: this.customerDisplayItems,
        });
      }
    },
    "pos.selfcheckout.refreshSelfServiceCashiers": function(){
      if (this.isRetailSelfCheckout) {
        this.$socket.emit("pos.selfcheckout.initBookedItems", {
          cashierID: this.api.auth.cashierID,
          items: this.customerDisplayItems,
        });
      }
    },
    "pos.selfcheckout.voidItem": function(data){
      if (this.isRetailSelfCheckout) {
        if(this.selectedItem?.randomPositionID !== data.position)
          this.$store.commit("pos/retail/selectInvoiceItem", this.getOrderedItem(data.position))
        this.voidItem(data, true);
      }
    },
  },

  watch: {
    showCustomerChip: function (val) {
      if (!val) {
        this.removeCustomer();
      }
    },
    customerDisplayItems: {
      deep: true,
      handler(items) {
        if (this.posType === "retail" || this.posType === "wholesaleRetail" || (this.posType === "gastro" && this.gastroTableName === 0)) {
          this.$socket.emit("customerdisplay.updateItems", {
            cashierID: this.api.auth.cashierID,
            items: items,
            posType: this.posType
          });
          if (this.isRetailSelfCheckout) {
            this.$socket.emit("pos.selfcheckout.updateItems", {
              cashierID: this.api.auth.cashierID,
              items: items,
            });
          }
        }
      }
    },
    openItems() {
      if (this.openItems.length !== 0 && !this.tseLoading) {
        if (this.posType === "retail") {
          if (Object.keys(this.pos.retail.transactionPayload).length === 0) {
            this.checkTSEStartTransaction();
          }
        }
        
        if (this.posType === "wholesaleRetail") {
          if (Object.keys(this.pos.wholesaleRetail.transactionPayload).length === 0) {
            this.checkTSEStartTransaction();
          }
        }

        if (this.posType === "gastro") {
          if (this.gastroTableName === 0) {
            if (Object.keys(this.pos.gastro.transactionPayload).length === 0) {
              this.checkTSEStartTransaction();
            }
          }
        }
      }
    },
    orderedItems() {
      if (this.orderedItems.length !== 0 && !this.tseLoading 
        && ((Object.keys(this.pos.retail.transactionPayload).length === 0 && this.posType === "retail")
        || (Object.keys(this.pos.wholesaleRetail.transactionPayload).length === 0 && this.posType === "wholesaleRetail"))
      )
        this.checkTSEStartTransaction();
    },
    isRetailSelfCheckout (val) {
      if (!val) {
        this.$socket.emit("pos.selfcheckout.changePage", {cashierID: this.api.auth.cashierID});  
      }else {
        this.$socket.emit("pos.selfcheckout.initBookedItems", {
          cashierID: this.api.auth.cashierID,
          items: this.customerDisplayItems,
        });
      }
    }
  },

  mounted() {
    document.getElementById("scrollList").addEventListener("DOMNodeInserted", function (e) {
      let id = e.target.id;
      
      if (id != "" && id != undefined && id != null) {
        if (id.includes("open_")) {
          let container = document.getElementById("items-list");
          if (container && document.getElementById(id))
            document.getElementById("scrollList").scrollTop = document.getElementById(id).offsetTop - container.offsetTop
        } else if (id.includes("booked_")) {
          let container = document.getElementById("booked-items-list");
          if (container && document.getElementById(id))
            document.getElementById("scrollList").scrollTop = document.getElementById(id).offsetTop - container.offsetTop
        }
      }
    }, false);

    /**
     * listen on selected items
     */
    Events.$on("itemSelected", (id) => {

      if (id.includes("open_")) {
        let container = document.getElementById("items-list");
        if (container && document.getElementById(id))
          document.getElementById("scrollList").scrollTop = document.getElementById(id).offsetTop - container.offsetTop
      } else if (id.includes("booked_")) {
        let container = document.getElementById("booked-items-list");
        if (container && document.getElementById(id))
          document.getElementById("scrollList").scrollTop = document.getElementById(id).offsetTop - container.offsetTop
      }
    });
    // --- RESIZE ---
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });

    if (this.posType === "retail" || this.posType === "wholesaleRetail" || (this.posType === "gastro" && this.pos.gastro.table?.name === 0)){
      this.$socket.emit("customerdisplay.updateItems", {
        cashierID: this.api.auth.cashierID,
        items: this.customerDisplayItems,
        posType: this.posType
      });
    }

    if(this.posType === "retail"){
      if (this.isRetailSelfCheckout) {
        this.$socket.emit("pos.selfcheckout.updateItems", {
          cashierID: this.api.auth.cashierID,
          items: this.customerDisplayItems,
        });
      }
    }
  },
  beforeDestroy() {
    Events.$off("itemSelected")
  },
  methods: {
    getOrderedItem(randomPositionID){
      return this.orderedItems.find(item=> item.randomPositionID === randomPositionID)
    },
    customerVoidItem(item){
      this.$swal({
        title: this.$t('generic.lang_removeCartItem'),
        text: this.$t('generic.lang_removeCartItemMSG'),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if(this.selectedItem?.randomPositionID !== item.randomPositionID)
            this.$store.commit("pos/retail/selectInvoiceItem", item)

          this.voidItem({
            id: null,
            posType: this.posType,
            voidReason: null,
            amount: item.amount
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    voidItem(data) {
      this.$store.dispatch("pos/stornoItem", {
        id: data.id,
        posType: data.posType,
        voidReason: data.voidReason,
        amount: data.amount
      })
    },
    checkTSEStartTransaction() {
      if (this.fiscalClient !== null) {

        if (this.fiscalClient.device.type === null) {
          this.fiscalClient.device.type = "epsonTSE";
        }

        //IF TYPE IS FISKALY
        if (this.fiscalClient.device.type === "fiskaly") {
          this.tseLoading = true;

          //START FISKALY TRANSACTION AND RETURN TX ID INTO THE VUEX STORE
          this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICEFISKALY.STARTTX, {
          }).then((res) => {
            this.tseLoading = false;
            if (res.status === 200) {
              if (this.posType === "retail") {
                this.$store.dispatch("pos/retail/setTransactionPayload", res.data);
              }
              else if (this.posType === "wholesaleRetail") {
                this.$store.dispatch("pos/wholesaleRetail/setTransactionPayload", res.data)
              }
              else if (this.posType === "gastro") {
                this.$store.dispatch("pos/gastro/setTransactionPayload", res.data)
              }
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
            this.tseLoading = false;
          });

        }else{
          //USE TYPE EPSON TSE
          let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

          if (tseDevice !== null) {
            if (tseDevice.tseReady) {
              this.tseLoading = true;

              // SINGLE CASHIER MODE
              tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

              Events.$once("GFE_OnReceive", (payload) => {
                if (payload.result.function === "StartTransaction") {
                  if (payload.result.result === "EXECUTION_OK") {
                    if (this.posType === "retail") {
                      this.$store.dispatch("pos/retail/setTransactionPayload", payload.result.output).then(() => {
                        this.tseLoading = false;
                      });
                    }

                    if (this.posType === "wholesaleRetail") {
                      this.$store.dispatch("pos/wholesaleRetail/setTransactionPayload", payload.result.output).then(() => {
                        this.tseLoading = false;
                      });
                    }

                    if (this.posType === "gastro") {
                      this.$store.dispatch("pos/gastro/setTransactionPayload", payload.result.output).then(() => {
                        this.tseLoading = false;
                      });
                    }
                  }
                }
              });
            }
          }
        }

      }
    },
    getWindowWidth() {
      //SET INVOICE HEIGHT
      let divClientHeight = document.documentElement.clientHeight;
      if(this.$refs.hasOwnProperty('scrollList') && this.$refs.scrollList)
        this.$refs.scrollList.style.height = (divClientHeight - 213) + 'px'; // 130
    },
    removeCustomer() {
      this.$store.dispatch("pos/setCustomer", {
        posType: this.posType,
        customer: null
      });
    },
    removePagerNo() {
      this.$store.dispatch("pos/gastro/setPagerNo", null);
    },
    changeTable() {
      this.$swal({
        text: this.$t("generic.lang_you_really_want_change_table"),
        cancelButtonText: this.$t("generic.lang_no"),
        confirmButtonText: this.$t("generic.lang_yes"),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          // CHECK IF WE USE RFID CASHIER
          if (this.$store.getters["permissions/checkModule"](50) && this.pos.gastro.table?.name !== 0) {
            this.$router.replace({
              path: '/pos/rfid',
              query: {
                replaceUID: true
              }
            })
          } else {
            this.$router.replace("/pos/gastro/tables/" + true)
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      });
    },
  }
}
</script>

<style scoped>
.itemList-enter-active {
  transition: all 0.5s;
}

/*
::v-deep .v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right:5px;
}

::v-deep .v-chip__close {
  position: absolute;
  top: 5px;
  right: 0;
  width: 28px;
  padding-right:3px;
}
 */

.itemList-leave-active {
  transition: all 0.3s;
}

.itemList-enter, .itemList-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

@media (max-width: 1024px) {
  .invoiceTitle {
    font-size: 17px;
  }
}

@media (min-width: 1025px) {
  .invoiceTitle {
    font-size: 20px;
  }
}

</style>
