<template>
  <div>
    <page-title
      :heading="$t('erp.lang_wareOut')"
      :icon="icon"
      :subheading="$t('erp.lang_wareOut')"
      show-previous-button
      url-previous="/erp/storage/GoodsIssue"
    ></page-title>

    <div class="app-main__inner">
      <SimpleGoodsIssueDetailsComponent />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import SimpleGoodsIssueDetailsComponent from "../../../../components/erp/storage/goodissue/simpleGoods/SimpleGoodsIssueDetailsComponent";

export default {
  components: { PageTitle, SimpleGoodsIssueDetailsComponent },
  props: ["id"],
  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
