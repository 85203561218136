<template>
  <div class="mb-3 card transparent">

    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="list">
          {{ $t('erp.lang_nav_vouchers') }}
        </v-tab>
        <v-tab key="listOld">
          {{ $t('erp.lang_oldVoucherList') }}
        </v-tab>
        <v-tab key="add">{{ $t('erp.lang_issue_voucher') }}</v-tab>
        <v-tab key="bulk" v-if="this.$store.getters['permissions/checkPermission']('bulkCreateVouchers')">{{ $t('erp.lang_BulkCreateVoucher') }}</v-tab>
        <v-tab key="settings">{{ $t('settings.lang_settings') }}</v-tab>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item key="list">
            <!-- Table -->

            <Datatable v-if="showTable" ref="voucherDatatable" :api-endpoint="ENDPOINTS.DATATABLES.ERP.VOUCHER"
              :datatable-headers="datatableHeaders" :excel-columns="excelColumns" excel-file-name="GiftCards"
              @displayEntry="displayEntry" show-display-buttons>
              <template v-slot:item.value="{ item }">
                {{ item.value | currency }}
              </template>
              <template v-slot:item.devalued="{ item }">
                {{ item.devalued == 1 ? $t('generic.lang_yes') : $t('generic.lang_no') }}
              </template>
            </Datatable>
          </v-tab-item>

          <v-tab-item key="listOld">
            <!-- Table -->

            <Datatable v-if="showTable" ref="oldVoucherDatatable" :api-endpoint="ENDPOINTS.DATATABLES.ERP.VOUCHEROLD"
              :datatable-headers="datatableHeaders.filter(object => { return object.value !== 'devalued' && object.value !== 'originOfSale'; })"
              :excel-columns="excelColumns" excel-file-name="GiftCards">
              <template v-slot:item.value="{ item }">
                {{ item.value | currency }}
              </template>
            </Datatable>

            <v-container v-if="showUpdate" fluid>
              <v-layout>
                <v-flex md12>
                  <!-- Table: Click -->
                  <div>
                    <v-layout>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="voucherBalance" :label="$t('erp.lang_VoucherValue')" autocomplete="off"
                          disabled></v-text-field>
                      </v-flex>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="issuingDate" :label="$t('erp.lang_voucherIssuerID')" autocomplete="off"
                          disabled></v-text-field>
                      </v-flex>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="lastUse" :label="$t('generic.lang_lastchange')" autocomplete="off"
                          disabled></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex class="text-right">
                        <v-btn text color="error" @click="resetData">{{
                          $t('generic.lang_prev')
                        }}
                        </v-btn>
                        <v-btn color="success"
                          v-if="this.$store.getters['permissions/checkPermission']('reprintVouchers')"
                          :disabled="loadingPrinting" :loading="loadingPrinting"
                          @click="reprintVoucher(id, voucherBalance)">{{
                            $t('erp.lang_reprintVoucher')
                          }}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item key="add">
            <v-container fluid>
              <v-layout>
                <v-flex md6 mr-2 ml-2>
                  <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    v-model="voucherBalanceAdd" @keypress="acceptOnlyDecimal" :label="$t('erp.lang_VoucherValue')"
                    autocomplete="off" required></v-text-field>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                  <v-autocomplete outlined v-model="customerIdAdd" :loading="loading" :items="items"
                    :search-input.sync="addSearch" cache-items class="mx-3" flat hide-no-data hide-details
                    :label="$t('customers.lang_nav_searchcust')"></v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn :loading="this.add_loader" color="success"
                    :disabled="voucherBalanceAdd.length < 1 || this.add_loader" @click="addData(false)">{{
                      $t('generic.lang_add')
                    }}
                  </v-btn>
                  <v-btn :loading="this.add_loader" color="success"
                    :disabled="voucherBalanceAdd.length < 1 || this.add_loader" @click="addData(true)">{{
                      $t('accounting.lang_andPrint')
                    }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item key="bulk" v-if="this.$store.getters['permissions/checkPermission']('bulkCreateVouchers')">
            <v-container fluid>
              <v-layout>
                <v-flex>
                  <p><b>{{ $t('erp.lang_createVoucherCodesSubject') }}</b></p>
                  <p>{{ $t('erp.lang_createVoucherCodesBody') }}</p>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex md6 mr-2 ml-2>
                  <v-text-field outlined type="number" @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    v-model="voucherCodeQty" :label="$t('erp.lang_createGiftcardCodesLabel')" autocomplete="off"
                    required></v-text-field>
                </v-flex>

                <v-flex md6 mr-2 ml-2>
                  <v-currency-field v-model="voucherValue" :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                    @focus="showTouchKeyboard" :label="$t('erp.lang_VoucherValue')"
                    :suffix="this.settings.settings.currencySign" :auto-decimal-mode="true" :allow-negative="false"
                    outlined required></v-currency-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="downloadVoucherCodes">
                    {{ $t("generic.lang_download") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item key="settings">
            <v-container fluid><br><br>
              <v-layout>
                <v-flex md12 mr-2 ml-2>

                  <v-select :items="itemGroups" :label="$t('generic.lang_waregroup')" item-text="name" item-value="id"
                    outlined v-model="voucherItemGroup"></v-select>

                </v-flex>

              </v-layout>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn color="success" @click="setSettings">{{
                    $t('generic.lang_apply') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
    </div>
    <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="300">
      <v-card>
        <v-card-title>Info</v-card-title>
        <v-card-text class="text-center">{{ errorDialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="errorDialogMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import Datatable from "../../datatable/Datatable";
import { ENDPOINTS } from '../../../config'
import { Events } from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import { createVoucherPrintingData } from "../../../plugins/printing/printVoucher";
import { mapState } from "vuex";
import FileSaver from 'file-saver';
import { printDataFromPrinter } from "../../../plugins/printing/printerController";

export default {
  components: {
    Datatable
  },

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      add_loader: false,
      datatableHeaders: [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "id"
        },
        { text: this.$t('erp.lang_voucherIssuingDate'), value: "issueDate", sort: "desc" },
        { text: this.$t('erp.lang_voucherIssuerID'), value: "issueID" },
        { text: this.$t('erp.lang_VoucherValue'), value: "value" },
        { text: this.$t('customers.lang_cust_prename'), value: "prename" },
        { text: this.$t('customers.lang_cust_name'), value: "lastname" },
        { text: this.$t('customers.lang_customerID'), value: "custID" },
        { text: this.$t('generic.lang_lastchange'), value: "lastChange" },
        { text: this.$t('generic.lang_devalued'), value: "devalued" },
        { text: this.$t('generic.lang_originOfSale'), value: "originOfSale" },
      ],
      excelColumns: [
        { label: this.$t('generic.lang_id'), field: "id" },
        { label: this.$t('erp.lang_voucherIssuingDate'), field: "issueDate" },
        { label: this.$t('erp.lang_voucherIssuerID'), field: "issueID" },
        { label: this.$t('erp.lang_VoucherValue'), field: "value" },
        { label: this.$t('customers.lang_cust_prename'), field: "prename" },
        { label: this.$t('customers.lang_cust_name'), field: "lastname" },
        { label: this.$t('customers.lang_customerID'), field: "custID" },
        { label: this.$t('generic.lang_lastchange'), field: "lastChange" },
        { label: this.$t('generic.lang_devalued'), field: "devalued" },
        { label: this.$t('generic.lang_originOfSale'), field: "originOfSale" }
      ],
      id: null,
      voucherBalance: "",
      issuingDate: null,
      lastUse: null,
      voucherBalanceAdd: "",
      customerIdAdd: null,
      addSearch: null,
      items: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      voucherData: [],
      itemGroups: [],
      voucherItemGroup: null,
      dataTable: null,
      loadingPrinting: false,
      errorDialogMessage: "",
      voucherCodeQty: 0,
      voucherValue: 0,
    }
  },

  watch: {
    addSearch(val) {
      val && val !== this.select && this.querySelections(val)
    },
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  computed: {
    ...mapState([
      'api', 'settings'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  async mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      this.axios.post(ENDPOINTS.ERP.VOUCHER.GETITEMGROUP, {
      }).then((res) => {
        if (res.status === 200) {

          this.voucherItemGroup = res.data.formfillData.textFields.voucherItemGroup;
          this.itemGroups = res.data.formfillData.textFields.itemgroups.map(el=>({
            id: el[0],
            name: el[1],
          }));

        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {

      })
    },
    setSettings() {
      this.axios.post(ENDPOINTS.ERP.VOUCHER.UPDATEITEMGROUP, {
        voucherItemGroup: this.voucherItemGroup
      }).then((res) => {
        if (res.status === 200) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {

      })
    },
    acceptOnlyDecimal(event) {
      var value = this.voucherBalanceAdd;

      if (isNaN(event.key)) {
        if (event.key === "," || event.key === ".") {
          if (value != '') return;
        }

        event.preventDefault();
      }
    },
    displayEntry(entry) {
      this.$router.push('/erp/baseData/voucher/details/' + entry.id);
    },
    addData(can_print = false) {

      let self = this;
      this.add_loader = true;
      this.axios.post(ENDPOINTS.ERP.VOUCHER.CREATE, {
        voucherBalance: this.voucherBalanceAdd,
        customerID2: this.customerIdAdd
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_voucher_added'),
            color: "success"
          });


          self.voucherAdd = "";
          self.showList();

          self.$refs.voucherDatatable.getDataFromApi();
          self.$refs.voucherDatatable.resetSelectedRows();

          if (can_print && res.data.voucherId) {
            this.reprintVoucher(res.data.voucherId, res.data.voucherBalance);
          }
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.add_loader = false;
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    updateData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.VOUCHER.UPDATE, {
        editVoucherID: this.id,
        voucherName: this.text
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          self.resetData();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
    querySelections: function (searchText) {
      let self = this;
      self.loading = true;
      self.axios.get(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + searchText)
        .then((res) => {
          let items = res.data.results;
          for (let i = 0; i < items.length; i++) {
            let item = items[i];
            self.items.push({ text: item.text + (item.company && item.company !== '' ? ' (' + item.company + ')' : ''), value: item.id });
          }
          self.loading = false;
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        });
    },
    reprintVoucher(id = this.id, balance = this.voucherBalance) {
      // CHECK IF PRINTER EXIST
      if (!this.invoicePrinter) {
        Events.$emit("showSnackbar", {
          message: this.$t('erp.lang_noPrinterSelected'),
          color: "warning"
        });
        return;
      }
      let printVouchersData = createVoucherPrintingData("V$" + id, balance);

      this.loadingPrinting = true;

      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter, printVouchersData).then(() => {

      }).catch((err) => {
        this.errorDialogMessage = this.$t('erp.lang_voucherCouldNotBeReprinted');
      }).finally(() => {
        this.loadingPrinting = false;
      })

    },
    downloadVoucherCodes: function () {
      let apiServerIP = process.env.VUE_APP_API_HOST;
      if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
        let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.open(apiServerIP + "/create/erp/voucherCodesBulk/?qty=" + this.voucherCodeQty + "&value=" + this.voucherValue + "&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");
    },
  },
}
</script>
