<template>
  <v-card>
    <v-container class="ma-0 pa-0" fluid>
      <v-card elevation="0" outlined>
        <v-card-text class="ma-0 pa-0">
          <v-card elevation="0">
            <v-card-title class="pa-0 ma-0">
              <div class="card-header-tab card-header w-100">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <b-input-group width="100%">
                    <b-form-input :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" :placeholder="$t('datatables.lang_search')"
                      @focus="showTouchKeyboard" v-model="search" />
                  </b-input-group>
                </div>
                <v-spacer />
                <v-btn @click="openDiscountDialog" depressed color="success">
                  {{ this.$t("generic.lang_discount") }}
                </v-btn>
              </div>
            </v-card-title>

            <v-card-text class="px-0">
              <v-data-table :footer-props="footerProps" :headers="filteredDatatableHeaders"
                :items="filteredDatatableEntries" :loading="loading" sortBy="kiosk_position" :options.sync="pagination"
                :server-items-length="total" footer-props.items-per-page-text="Einträge pro Seite"
                v-model="selectedRows">
                <template v-slot:body="props">
                  <draggable :list="props.items" tag="tbody" v-bind="dragOptions" @end="changePosition($event)">
                    <tr v-for="(waregroup, index) in props.items" :key="index">
                      <td>
                        <font-awesome-icon :icon="['fas', 'grip-vertical']" style="color: lightgray; cursor: pointer"
                          class="page__grab-icon text-lighter handle" />
                      </td>

                      <td>{{ waregroup.cat_name_de }}</td>

                      <td>
                        <b-form-input :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" type="number"
                          @blur="editEntry(waregroup)" @keypress.enter="editEntry(waregroup)"
                          v-model="waregroup.kiosk_position" />
                      </td>

                      <td>
                        <b-form-input :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" type="number"
                          @blur="editEntry(waregroup)" @keypress.enter="editEntry(waregroup)"
                          v-model="waregroup.kiosk_discount" @keyup="discountInput(waregroup, 'kiosk_discount')" />
                      </td>

                      <td>
                        <b-input-group>
                          <b-input-group-append is-text>
                            <input type="checkbox" v-model="waregroup.kiosk_active" @change="editEntry(waregroup)" />
                          </b-input-group-append>
                        </b-input-group>
                      </td>

                      <td>
                        <b-input-group>
                          <b-input-group-append is-text>
                            <input type="checkbox" v-model="waregroup.kiosk_is_featured"
                              @change="editEntry(waregroup)" />
                          </b-input-group-append>
                        </b-input-group>
                      </td>

                      <td>
                        <b-form-input :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" @blur="editEntry(waregroup)"
                          @keypress.enter="editEntry(waregroup)" v-model="waregroup.kiosk_featured_name" />
                      </td>

                      <td>
                        <v-menu v-model="menu[waregroup.id]" :close-on-content-click="false" offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <b-form-input v-bind="attrs" v-on="on" readonly
                              :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                              v-model="waregroup.kiosk_featured_color" />
                          </template>

                          <v-card>
                            <v-color-picker v-model="colorPicker" dot-size="25" hide-mode-switch
                              mode="hexa"></v-color-picker>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn text @click="closeFeaturedColor(waregroup)">
                                {{ $t("generic.lang_cancel") }}
                              </v-btn>
                              <v-btn color="primary" text @click="applyFeaturedColor(waregroup)">
                                {{ $t("generic.lang_save") }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </td>
                      <td>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <input type="checkbox" v-model="waregroup.kiosk_service_automated"
                              @change="editEntry(waregroup)" />
                          </b-input-group-prepend>
                          <b-input-group-append>
                            <b-button variant="primary" :disabled="!waregroup.kiosk_service_automated"
                              @click="openItemGroupAutomationDialog(waregroup)">
                              <v-icon dark size="20">mdi-pencil</v-icon>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group>
                          <template #prepend>
                            <b-button @click="editEntry({...waregroup, kiosk_items_view: 0})" size="sm" :variant="waregroup.kiosk_items_view == '0'?'primary':'light'">
                            {{ $t('generic.lang_grid') }}
                          </b-button>  
                          </template>
                          <template #append>
                            <b-button @click="editEntry({...waregroup, kiosk_items_view: 1})" size="sm" :variant="waregroup.kiosk_items_view == '1'?'primary':'light'">
                            {{ $t('generic.lang_list') }}
                          </b-button>
                          </template>
                        </b-input-group>
                      </td>
                      <td>
                        <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>mdi-cog-outline</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item @click="toggleAllItems(true, waregroup.id)">
                              <v-list-item-title>{{$t("generic.lang_enableAllItems")}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="toggleAllItems(false, waregroup.id)">
                              <v-list-item-title>{{$t("generic.lang_disableAllItems")}}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <ItemGroupAutomationDialog v-if="showItemGroupAutomation" @close="showItemGroupAutomation = false"
            :group-id="selectedGroupId" />
          <!--start: discount dialog-->
          <v-dialog max-width="600" v-model="discount_dialog">
            <v-card>
              <v-card-title> {{ this.$t("generic.lang_bulk_discount") }} </v-card-title>
              <v-card-text class="py-2 px-3">
                <v-form v-on:submit.prevent lazy-validation ref="discountForm">
                  <v-text-field @keypress.enter="applyDiscount" :rules="[
                      (v) => !!v && parseFloat(v) >= 0 && parseFloat(v) <= 100,
                    ]" :max="100" :min="0" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                    type="number" hide-details dense outlined v-model="global_discount"
                    :label="this.$t('generic.lang_discount')" />
                </v-form>
              </v-card-text>

              <v-card-actions class="py-2 px-3">
                <v-btn :loading="this.discountLoader" :disabled="this.discountLoader" @click="applyDiscount" depressed
                  color="primary" block class="mx-0">
                  {{ this.$t("generic.lang_apply") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--end: discount dialog-->

          <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
              :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input" :layout="touchKeyboard.layout"
              :options="touchKeyboard.options" class="internalWidthExpanded" id="onScreenKeyboard"
              v-if="touchKeyboard.visible" />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import draggable from "vuedraggable";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons";
import ItemGroupAutomationDialog from "./ItemGroupAutomationDialog.vue";

library.add(faGripVertical);

export default {
  name: "KioskCategoriesComponent",
  components: {
    draggable,
    "font-awesome-icon": FontAwesomeIcon,
    ItemGroupAutomationDialog
  },
  mixins: [mixin],
  data() {
    return {
      menu: {},
      colorPicker: null,
      discount_dialog: false,
      global_discount: "",
      discountLoader: false,
      showItemGroupAutomation: false,
      selectedGroupId: null,
      // 'id', 'cat_name_de',  'kiosk_position', 'kiosk_alias', 'kiosk_discount', 'kiosk_active','kiosk_is_featured'
      loading: false,
      drag: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [
        [1, "cat1", "localbee cat", 50, 1, 2],
        [2, "cat2", "localbee brot", 10, 1, 3],
        [3, "cat3", "localbee snacks", 10, 1, 4],
        [4, "cat4", "localbee cakes", 10, 1, 5],
        [5, "cat5", "localbee ice cream", 10, 1, 6],
      ],
      search: "",
      total: 10,
      pagination: {},
      footerProps: { "items-per-page-options": [15, 30, 50, 100] },
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
    };
  },
  computed: {
    datatableHeaders(){
      return [
        {
          text: "",
          value: "crud",
          sortable: false,
          width: 50,
        },
        {
          text: "ID",
          value: "id",
          hidden: true,
        },
        {
          text: this.$t("erp.lang_itemgroup_name"),
          value: "cat_name_de",
        },
        {
          text: this.$t("generic.lang_sorting"),
          value: "kiosk_position",
          align: "center",
          width: "100",
        },
        {
          text: this.$t("generic.lang_alias"),
          value: "kiosk_alias",
          width: 100,
          hidden: true,
        },
        {
          text: this.$t("generic.lang_discount"),
          value: "kiosk_discount",
          width: "100",
        },
        {
          text: this.$t("generic.lang_enabled"),
          value: "kiosk_active",
          width: 100,
        },
        {
          text: this.$t("eventbee.lang_featured"),
          value: "kiosk_is_featured",
          width: 100,
        },
        {
          text: this.$t("generic.lang_featured_name"),
          value: "kiosk_featured_name",
          width: 200,
        },
        {
          text: this.$t("generic.lang_featured_color"),
          value: "kiosk_featured_color",
          align: "start",
          width: 200,
        },
        {
          text: 'Automate',
          value: "kiosk_service_automated",
          width: "120",
          sortable: false,
        },
        {
          text: this.$t("generic.lang_itemsView"),
          value: "kiosk_items_view",
          align: "start",
          width: 150,
        },
        {
          text: this.$t("generic.lang_actions"),
          value: "actions",
          width: "120",
          sortable: false,
        },
      ]
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hidden")) {
          if (header.hidden) {
            return;
          }
        }
        headers.push(header);
      });

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];
      if (this.dataToShow)
        this.dataToShow.forEach((data) => {
          let row = {};
          let index = 0;
          this.datatableHeaders.forEach((header) => {
            if (header.value !== "crud") {
              if (header.value === "kiosk_position") {
                row[header.value] = parseInt(Number(data[index]));
              } else {
                row[header.value] = data[index];
              }
            } else {
              return;
            }
            index++;
          });
          dataToRender.push(row);
        });

      return dataToRender;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    },
  },
  methods: {
    toggleAllItems(enable, id) {
      let endpoint = enable ? ENDPOINTS.KIOSK.PRODUCTSGROUP.ITEMS.ENABLEALL : ENDPOINTS.KIOSK.PRODUCTSGROUP.ITEMS.DISABLEALL;
      this.loading = true;
      this.axios.post(endpoint, {id}).then((res) => {
          if (res.data.status==='SUCCESS') {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_ActionSuccessful'),
              color: "success"
            });
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg || res.data.status,
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        }).finally(() => {
          this.loading = false;
        })


    },
    openItemGroupAutomationDialog(entry) {
      this.showItemGroupAutomation = true;
      this.selectedGroupId = Number(entry.id);
    },
    openDiscountDialog() {
      this.discount_dialog = true;
      this.global_discount = "";
    },
    applyDiscount() {
      if (!this.$refs.discountForm.validate()) return;
      this.discountLoader = true;
      this.axios
        .post(ENDPOINTS.KIOSK.PRODUCTSGROUP.BULKDISCOUNT, {
          discount: this.global_discount,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            this.discount_dialog = false;
            this.getDataFromApi();
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg || res.data.status,
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => (this.discountLoader = false));
    },
    discountInput(item, attr) {
      if (Number(item[attr]) > 100) {
        item[attr] = 100;
      }
    },
    applyFeaturedColor(waregroup) {
      waregroup.kiosk_featured_color = this.colorPicker.hex;
      this.editEntry(waregroup);
      this.colorPicker = null;
      this.menu[waregroup.id] = false;
    },
    closeFeaturedColor(waregroup) {
      this.colorPicker = null;
      this.menu[waregroup.id] = false;
    },
    editEntry(entry, event = null) {
      this.axios
        .post(ENDPOINTS.KIOSK.PRODUCTSGROUP.UPDATE, {
          id: entry.id,
          position: entry.kiosk_position,
          kiosk_alias: entry.kiosk_alias,
          kiosk_discount: entry.kiosk_discount,
          kiosk_active: entry.kiosk_active,
          kiosk_is_featured: entry.kiosk_is_featured,
          kiosk_featured_name: entry.kiosk_featured_name,
          kiosk_featured_color: entry.kiosk_featured_color,
          kiosk_service_automated: entry.kiosk_service_automated,
          kiosk_items_view: entry.kiosk_items_view,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            this.getDataFromApi();
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg || res.data.status,
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        });
    },
    changePosition(event) {
      let item = this.filteredDatatableEntries[event.oldIndex];
      let itemReplaced = this.filteredDatatableEntries[event.newIndex];
      if (event.oldIndex < event.newIndex) {
        if (item.id !== itemReplaced.id)
          item.position = parseInt(Number(itemReplaced.position)) + 1;
      } else if (event.oldIndex > event.newIndex) {
        if (item.id !== itemReplaced.id)
          if (parseInt(Number(itemReplaced.position)) - 1 === 0) {
            item.position = 1;
          } else {
            item.position = parseInt(Number(itemReplaced.position)) - 1;
          }
      }
      if (item.id !== itemReplaced.id) {
        this.editEntry(item);
      }
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart:
          this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(
          (header) => header.value === this.pagination.sortBy[0]
        );
        index -= 1;
        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? "desc" : "asc";
      }

      /* SPECIFY ADDITIONAL PARAMS
       * params['customerID'] = parseInt(this.$props.customerId);
       *
       * */

      this.axios
        .get(ENDPOINTS.DATATABLES.KIOSK.PRODUCTSGROUP, {
          params: params,
        })
        .then((res) => {
          this.total = parseInt(Number(res.data.iTotalDisplayRecords));
          this.dataToShow = res.data.aaData;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
          this.dataToShow = [];
        })
        .finally((fin) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getDataFromApi();
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
