<template>
  <div>
    <page-title :heading="$t('settings.lang_invoiceSigning')" :subheading="$t('settings.lang_invoiceSigning')" :icon=icon></page-title>
    <div class="app-main__inner">
      <InvoiceSignatureSettings/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import InvoiceSignatureSettings from "../../../components/settings/moduleSettings/InvoiceSignatureSettings.vue";


export default {
  name: "InvoiceSignature",
  components: {PageTitle, InvoiceSignatureSettings},
  data(){
    return{
      icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    }
  }
}
</script>

<style scoped>

</style>
