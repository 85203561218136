<template>
    <v-container class="pr-5 ma-0" fluid>
        <v-row class="pa-0 ma-0" justify="center">
            <v-col class="pb-0 mb-0" cols="8">
                <v-autocomplete :items="filteredItems" :loading="loadingSearch"
                    :search-input.sync="search" item-text="name" item-value="id" return-object @change="updateItems"
                    :label="$t('erp.lang_ware_search')" clearable outlined flat hide-details/>
            </v-col>
            <v-col cols="12">
                <v-data-table :headers="dataTableHeader" :items="selectedItems" :show-select="false">
                    <template v-slot:item.weight="{ item }">
                        <v-text-field v-model.number="item.weight" type="number" hide-details dense outlined :rules="[rules.numberRange(item.weight, 0, 10)]"/>
                    </template>
                    <template v-slot:item.crud="{ index }">
                        <v-btn text fab color="error" @click="removeItem(index)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import validation from "../../../mixins/validation/validation";

export default {
    name: "CrossSellingAssignments",
    props: {
        value: {
            type: Array,
            default: ()=> []
        }
    },
    mixins:[validation],
    data() {
        return {
            loading: false,
            search: null,
            selectedItems: [],
            items: [],
            awaitingSearch: null,
            loadingSearch: false
        }
    },
    computed:{
        filteredItems(){
            return this.items.filter(item=>{
                return !this.selectedItems.some(selectedItem => selectedItem.id===item.id)
            }).map(item=>({
                id: item.id,
                name: item.name,
                weight: 0,
            }))
        },
        dataTableHeader(){
            return [
                {
                    text: "ID",
                    value: "id",
                    sortable: false,
                    hide: true
                },
                {
                    text: this.$t('erp.lang_posItemName'),
                    value: "name",
                    sortable: true,
                },
                {
                    text: `${this.$t('erp.lang_crossSellingWeight')} (${this.rules.numberRange(11, 0, 10)})`,
                    width: 300,
                    value: "weight",
                },
                {
                    text: '',
                    value: "crud",
                    align: "center",
                    sortable: false,
                },
            ]
        }
    },
    watch:{
        selectedItems(v){
            this.$emit('input', v);
        },
        search(val) {
            clearTimeout(this.awaitingSearch);

            this.awaitingSearch = setTimeout(() => {
                if ((val && val.length === 0) || !val) {
                    this.items = [];
                    return;
                }

                this.loadingSearch = true;

                this.axios
                    .post(ENDPOINTS.ERP.ITEM.SEARCH, {
                        query: val,
                    })
                    .then((res) => {
                        if (res.data.status === "SUCCESS") {
                            this.items = res.data.data.foundItems;
                        }
                    })
                    .catch(() => { })
                    .finally(() => {
                        this.loadingSearch = false;
                    });
            }, 500);
        },
    },
    methods: {
        updateItems(entry){
            this.selectedItems.push(entry);
        },
        removeItem(index){
            this.selectedItems.splice(index, 1)
        }
    },
    mounted(){
        if (this.value && this.value?.length > 0) {
            this.selectedItems = [...this.value]
        }
    }
}
</script>