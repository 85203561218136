<template>
    <v-container>
        <v-row v-if="loading">
            <v-col cols="12">
                <div style="text-align: center;">
                    <v-progress-circular indeterminate width="3" color="primary"></v-progress-circular>
                </div>
            </v-col>
        </v-row>

        <v-card v-if="!loading">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2>{{ template.name }}</h2>
                    </v-col>
                </v-row>
            </v-container>

            <v-container>
                <v-row>
                    <v-col offset="1" cols="11" style="margin-top: -20px;">
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-slider
                                            min="1"
                                            max="8"
                                            :label="$t('settings.lang_width')"
                                            v-model="template.gridWidth"
                                    ></v-slider>
                                </v-col>

                                <v-col cols="1">
                                    <v-text-field style="margin-top: -10px;" outlined type="number" v-model="template.gridWidth" :label="$t('settings.lang_width')"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>

            <v-container style="margin-top: -100px;">
                <v-row v-if="!loading">
                    <v-col cols="1">
                        <v-slider
                                style="margin-top: 50px;"
                                min="1"
                                max="8"
                                vertical
                                :label="$t('settings.lang_height')"
                                v-model="template.gridHeight"
                        ></v-slider>

                        <v-text-field type="number" outlined v-model="template.gridHeight" :label="$t('settings.lang_height')"></v-text-field>
                    </v-col>

                    <v-col cols="11" style="margin-top: 30px;">
                        <div id="itemsGrid" style="width: 800px;">
                            <Grid @update="getTemplate" :template="template"  :multiUser="!!template.multiUser" :grid-item-width="gridItemWidth" :grid-item-height="gridItemHeight" :grid-height="template.gridHeight" :grid-width="template.gridWidth"></Grid>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
    import Grid from "./posgrideditor/Grid";
    import {ENDPOINTS} from "../../../../config";
    import mixin from '@/mixins/KeyboardMixIns';

    export default {
        name: "POSGridEditor",

        components: {
            Grid
        },

        props: {
            templateID: Number
        },

        mixins: [mixin],
        data() {
            return {
                mounted: false,
                loading: true,
                template: null,
            }
        },

        mounted() {
            this.mounted = true;

            this.getTemplate();
        },

        computed: {
            gridItemWidth() {
                /*
                if(!this.mounted || this.template === null || this.loading) {
                    return "0px";
                }

                let container = document.getElementById("itemsGrid");

                if(container === null) {
                    return "0px";
                }

                let containerWidth = container.offsetWidth - (this.width * 20);
                */
                let containerWidth = 800;

                if(this.template === null)
                    return "0px";

                if(!this.template.hasOwnProperty("gridWidth"))
                    return "0px";

                return containerWidth / this.template.gridWidth+"px";
            },
            gridItemHeight() {
                /*
                if(!this.mounted || this.template === null || this.loading) {
                    return "0px";
                }

                let container = document.getElementById("itemsGrid");

                if(container === null) {
                    return "0px";
                }

                let containerWidth = container.offsetWidth - (this.width * 20);
                */
                let containerWidth = 800;

                if(this.template === null)
                    return "0px";

                if(!this.template.hasOwnProperty("gridHeight"))
                    return "0px";

                return containerWidth / this.template.gridHeight+"px";
            }
        },

        methods: {
            getTemplate() {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.POSLAYOUT.TEMPLATE.GET, {
                    templateID: this.templateID
                }).then((res) => {
                    if(res.data.success) {
                        this.template = res.data.template;

                        this.loading = false;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .break {
        flex-basis: 100%;
        height: 0;
    }
</style>
