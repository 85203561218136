import store from "../../store/index";
import lodash from "lodash";
import { geol } from "../../main";
import { format } from "mathjs";
import { formatCurrency } from "../currencyFormater";
import { roundNumber } from "@/plugins/round";
import { i18n } from "../../main";

function convertPaymentType(paymentType) {
  // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
  // we re gonna use this work around for now until we find a better way

  const countryCode = store.getters["settings/getSettingValue"](
    "geoloc_systemcountry"
  );
  if (countryCode) {
    geol.locale = countryCode === "ma" ? "fr" : countryCode;
  }
  paymentType = parseInt(paymentType);
  // CHECKING ADDITIONAL PAYMENTS
  if (
    paymentType >= 500 &&
    store.state.payments.payments &&
    store.state.payments.payments.length > 0
  ) {
    let payment = store.state.payments.payments.find(
      (search) => search.paymentID === paymentType
    );
    if (payment) return payment.paymentName;
    else return paymentType;
  }

  if (paymentType === 1) return geol.t("generic.lang_cash");
  else if (paymentType === 2) return geol.t("erp.lang_voucher");
  else if (paymentType === 3) return "EC";
  else if (paymentType === 4) return geol.t("accounting.lang_bill");
  else if (paymentType === 6) return geol.t("generic.lang_parMaison");
  else if (paymentType === 7) return geol.t("generic.lang_split_table");
  else if (paymentType === 9) return geol.t("generic.lang_creditCard");
  else if (paymentType === 101 || paymentType === 102 || paymentType === 103)
    return "AUFS ZIMMER";
  else if (paymentType === 11) return "Paypal";

  return paymentType;
}

//GETTING THE PAYMENT NOTE FROM SYSTEM SETTINGS

function getPaymentTypeNote(paymentType) {
  paymentType = parseInt(paymentType);

  if (paymentType === 1) {
    if (store.state.settings.settings.cashPaymentText != "0") {
      return store.state.settings.settings.cashPaymentText;
    } else {
      return "";
    }
  } else if (paymentType === 3) {
    if (store.state.settings.settings.ecPaymentText != "0") {
      return store.state.settings.settings.ecPaymentText;
    } else {
      return "";
    }
  } else if (paymentType === 4) {
    if (store.state.settings.settings.invoicePaymentRecieptFreeText != "0") {
      return store.state.settings.settings.invoicePaymentRecieptFreeText;
    } else {
      return "";
    }
  } else if (paymentType === 9) {
    if (store.state.settings.settings.ecPaymentText != "0") {
      return store.state.settings.settings.ecPaymentText;
    } else {
      return "";
    }
  }

  return "";
}

export function formatDateAndTime(timestamp) {
  let date = new Date(timestamp * 1000);
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  let yyyy = date.getFullYear();
  let hours = String(date.getHours()).padStart(2, "0");
  let minutes = String(date.getMinutes()).padStart(2, "0");

  return {
    date: `${dd}.${mm}.${yyyy}`,
    time: `${hours}:${minutes}`,
  };
}

function ignoreItem(item) {
  return (
    item.isVoid ||
    item.ean.includes("GF$") ||
    item.ean.includes("V$") ||
    item.ean.includes("ZMYLE$") ||
    item.sellPrice < 0
  );
}

function calculateTotalForItem(item) {
  let itemSellPrice = item.sellPrice;

  // Check if it's a deposit item
  if (item.hasOwnProperty("depositgroupID") && item.depositgroupID > 0) {
    let deposit = store.getters["deposit/depositgroups"].find(
      (depositGroup) => depositGroup.id === item.depositgroupID
    );
    if (deposit) itemSellPrice += deposit.priceTotal;
  }

  // Calculate total price for the item
  return itemSellPrice;
}

function calculateTaxForItem(item, taxCalcObj, itemSellPrice, itemAmount) {
  if (ignoreItem(item)) return;
  if (item.isMenuItem === 1) {
    // Calculate tax for menu item
    for (let taxValue in item.menuTaxRules) {
      if (!item.menuTaxRules.hasOwnProperty(taxValue)) continue;

      let taxPercentage = item.menuTaxRules[taxValue] / 100;
      taxValue = parseFloat(taxValue);

      if (typeof taxCalcObj[taxValue] === "undefined") {
        taxCalcObj[taxValue] = { net: 0.0, total: 0.0, tax: 0.0 };
      }

      let calcNet = parseFloat(
        format((itemSellPrice * taxPercentage) / (1 + taxValue / 100), {
          precision: 14,
        }) * itemAmount
      );
      let calcTax =
        parseFloat(
          format(itemSellPrice * taxPercentage, { precision: 14 }) * itemAmount
        ) - calcNet;

      taxCalcObj[taxValue]["net"] += calcNet;
      taxCalcObj[taxValue]["tax"] += calcTax;
      taxCalcObj[taxValue]["total"] += parseFloat(
        itemSellPrice * taxPercentage * itemAmount
      );
    }
  } else {
    // Calculate tax for non-menu item
    if (typeof taxCalcObj[item.taxValue] === "undefined") {
      taxCalcObj[item.taxValue] = { net: 0.0, total: 0.0, tax: 0.0 };
    }
    let calcNet = parseFloat(
      format((itemSellPrice * itemAmount) / (1 + item.taxValue / 100), {
        precision: 14,
      })
    );
    let calcTax = parseFloat(itemSellPrice * itemAmount) - calcNet;

    taxCalcObj[item.taxValue]["net"] += calcNet;
    taxCalcObj[item.taxValue]["tax"] += calcTax;
    taxCalcObj[item.taxValue]["total"] += parseFloat(
      itemSellPrice * itemAmount
    );
  }
}

//CALC TAX AND TOTAL
//TAX DECIMAL => array(NET,TOTAL,TAXTOTAL)
//NUMBER OF ITEMS FOR DELIVERY SERVICE
function calculateItemsTotalAndTax(items) {
  let totalprice = 0.0;
  const taxCalcObj = {};
  let itemsNumber = 0;

  // Calculate total price and format invoice items
  items.forEach((item) => {
    if (item.isVoid) return;
    let itemAmount = Math.abs(item.amount);
    let itemSellPrice = calculateTotalForItem(item);
    itemsNumber += itemAmount;

    calculateTaxForItem(item, taxCalcObj, itemSellPrice, itemAmount);
    //CALC TOTAL PRICE
    totalprice += roundNumber(
      parseFloat(itemSellPrice * Math.abs(item.amount)),
      2
    );
  });

  return {
    totalprice,
    taxCalcObj,
    itemsNumber,
  };
}

function formatCustomerData(data) {
  let customerData = "";
  let customerAddress = "";
  let customerPhone = "";
  let customerFloor = "";

  if (data.hasOwnProperty("customer") && data.customer) {
    if (data.customer.company !== null) {
      customerData += data.customer.company + " \n";
    }
    if (data.customer.departmentName !== null) {
      customerData += data.departmentName + " \n";
    }
    if (data.customer.salutationName !== null) {
      customerData += data.customer.salutationName + " ";
    }
    if (data.customer.last_name !== null) {
      customerData +=
        data.customer.first_name + " " + data.customer.last_name + " \n";
    }
    if (data.isDelivery) {
      if (data.customer.street !== null) {
        customerData +=
          data.customer.street +
          " " +
          data.customer.street_no +
          "\n " +
          data.customer.zip +
          " " +
          data.customer.city +
          " \n";
        customerAddress += data.customer.street
          ? data.customer.street +
            " " +
            data.customer.street_no +
            " " +
            data.customer.zip +
            " " +
            data.customer.city +
            ""
          : "";
      }
      if (data.customer.mobil_private) {
        customerPhone += data.customer.mobil_private;
      }
      if (
        data.customer.BuildingInfo &&
        data.customer.BuildingInfo.hasOwnProperty("floor") &&
        data.customer.BuildingInfo.floor
      ) {
        customerFloor = data.customer.BuildingInfo.floor;
      }
    }
  }

  // Return extracted customer information
  return {
    customerData: customerData.trim(),
    customerAddress: customerAddress.trim(),
    customerPhone: customerPhone.trim(),
    customerFloor: customerFloor.trim(),
  };
}

function calculateMoneyBackAndTip(data, totalprice) {
  let moneyBack = 0;
  let tipValue = 0;

  // Calculate money back
  if (data.moneyGiven > totalprice) {
    moneyBack = data.moneyGiven - totalprice;
  }

  // Handle voucher case
  if (totalprice <= 0) {
    moneyBack = 0;
    if (data.moneyGiven > 0.0) {
      moneyBack = data.moneyGiven;
    }
  }

  // Handle tip
  if (data.restAsTip) {
    tipValue = Math.max(data.moneyGiven - totalprice, 0);
    moneyBack = 0;
  }

  return { moneyBack, tipValue };
}

function processPaymentType(data) {
  // Convert paymentType to string
  let paymentType = data.paymentType?.toString() || "";

  // Parse paymentTypeID as an integer
  let paymentTypeID = parseInt(paymentType);

  // Get paymentNote using getPaymentTypeNote, or set it to an empty string if not found
  let paymentNote = getPaymentTypeNote(paymentType) || "";

  // Convert paymentType using convertPaymentType
  paymentType = convertPaymentType(paymentType);

  // Return the processed payment data as an object
  return {
    paymentType,
    paymentTypeID,
    paymentNote,
  };
}

function addTraineeData(activeUser, printObj) {
  // check if it's a trainee
  if (activeUser && activeUser.userID === 101) {
    printObj.push({ feed: 1 });
    printObj.push({ align: "center" });
    printObj.push({ fontSize: 2 });
    printObj.push({ reverse: "on" });
    printObj.push({ text: geol.t("generic.lang_training").toUpperCase() });
    printObj.push({ reverse: "off" });
    printObj.push({ fontSize: 1 });
    printObj.push({ feed: 1 });
  }
}

export function createInvoicePrintingData(
  items,
  data,
  printerOpts,
  activeUser,
  interimsPrint = false,
  deliveryNotePrint = false,
  goodsValue = 0,
  vouchersValue = 0
) {
  let printObj = [];
  let taxCalcObj = {};
  let totalprice = 0.0;


  if(!data.hasOwnProperty('pagerNo')){
    data.pagerNo = store.state.pos.gastro.pagerNo
  }

  if(!data.hasOwnProperty('partyName')){
    data.partyName = store.state.pos.gastro.party?.name
  }

  if(!data.hasOwnProperty('takeAway')){
    data.takeAway = store.state.pos.gastro.takeAway
  }

  if(!data.hasOwnProperty('pickUpNo')){
    data.pickUpNo = store.state.pos.gastro.pickup;
  }

  if (activeUser && activeUser.userID === 101) {
    printObj.push({ feed: 1 });
    printObj.push({ align: "center" });
    printObj.push({ fontSize: 2 });
    printObj.push({ reverse: "on" });
    printObj.push({ text: geol.t("generic.lang_training").toUpperCase() });
    printObj.push({ reverse: "off" });
    printObj.push({ fontSize: 1 });
    printObj.push({ feed: 1 });
  }

  var pickUpNo = "";
  if (
    data.hasOwnProperty("isDelivery") &&
    data.hasOwnProperty("pickUpNo") &&
    data.pickUpNo
  ) {
    pickUpNo = data.pickUpNo;
  } else if (data.pickUpNo) {
    pickUpNo = data.pickUpNo;
  } else {
    pickUpNo = "";
  }

  let customerData = "";
  let customerAddress = "";
  let customerPhone = "";
  let customerFloor = "";

  if ( data.customer && (data.hasOwnProperty("isDelivery") || data.reprint) ) {
    if (data.customer.company) {
      customerData += data.customer.company + " \n";
    }
    if (data.customer.departmentName) {
      customerData += data.departmentName + " \n";
    }
    
    if (data.customer.salutationName) {
      customerData += data.customer.salutationName + " ";
    }

    if (data.customer.first_name) {
      customerData += `${data.customer.first_name || ""} ${data.customer.last_name}\n`;
    }
    
    if (data.isDelivery) {
      if (data.customer.street !== null) {
        customerData +=
          data.customer.street +
          " " +
          data.customer.street_no +
          "\n " +
          data.customer.zip +
          " " +
          data.customer.city +
          " \n";
        customerAddress += data.customer.street
          ? data.customer.street +
            " " +
            data.customer.street_no +
            " " +
            data.customer.zip +
            " " +
            data.customer.city +
            ""
          : "";
      }
      if (data.customer.mobil_private) {
        customerPhone += data.customer.mobil_private;
      }
      if (
        data.customer.BuildingInfo &&
        data.customer.BuildingInfo.hasOwnProperty("floor") &&
        data.customer.BuildingInfo.floor
      ) {
        customerFloor = data.customer.BuildingInfo.floor;
      }
    }else if(data.reprint) {
        customerData += `${data.customer.street ? data.customer.street + " " : ""}${data.customer.street_no || ""}\n${data.customer.zip || ""} ${data.customer.city || ""}\n`;
    }

  } else if (
    typeof store.state.pos.gastro.customer !== "undefined" &&
    store.state.pos.gastro.customer !== null
  ) {
    if (store.state.pos.gastro.customer.company !== null) {
      customerData += store.state.pos.gastro.customer.company + " \n";
    }
    if (store.state.pos.gastro.customer.departmentName !== null) {
      customerData += store.state.pos.gastro.customer.departmentName + " \n";
    }
    if (store.state.pos.gastro.customer.salutationName !== null) {
      customerData += store.state.pos.gastro.customer.salutationName + " ";
    }
    if (store.state.pos.gastro.customer.last_name !== null) {
      customerData +=
        store.state.pos.gastro.customer.first_name +
        " " +
        store.state.pos.gastro.customer.last_name +
        " \n";
    }
    if (
      (data.hasOwnProperty("isDelivery") && data.isDelivery) ||
      !data.hasOwnProperty("isDelivery")
    ) {
      if (store.state.pos.gastro.customer.street !== null) {
        customerData +=
          store.state.pos.gastro.customer.street +
          " " +
          store.state.pos.gastro.customer.street_no +
          "\n " +
          store.state.pos.gastro.customer.zip +
          " " +
          store.state.pos.gastro.customer.city +
          " \n";
        customerAddress += store.state.pos.gastro.customer.street
          ? store.state.pos.gastro.customer.street +
            " " +
            store.state.pos.gastro.customer.street_no +
            " " +
            store.state.pos.gastro.customer.zip +
            " " +
            store.state.pos.gastro.customer.city +
            ""
          : "";
      }
      if (store.state.pos.gastro.customer.mobil_private) {
        customerPhone += store.state.pos.gastro.customer.mobil_private;
      }
      if (
        store.state.pos.gastro.customer.BuildingInfo &&
        store.state.pos.gastro.customer.BuildingInfo.hasOwnProperty("floor") &&
        store.state.pos.gastro.customer.BuildingInfo.floor
      ) {
        customerFloor = store.state.pos.gastro.customer.BuildingInfo.floor;
      }
    }
  } else if (
    typeof store.state.pos.retail.customer !== "undefined" &&
    store.state.pos.retail.customer !== null
  ) {
    if (store.state.pos.retail.customer.company !== null) {
      customerData += store.state.pos.retail.customer.company + " \n";
    }
    if (store.state.pos.retail.customer.departmentName !== null) {
      customerData += store.state.pos.retail.customer.departmentName + " \n";
    }
    if (store.state.pos.retail.customer.salutationName !== null) {
      customerData += store.state.pos.retail.customer.salutationName + " ";
    }
    if (store.state.pos.retail.customer.last_name !== null) {
      customerData +=
        store.state.pos.retail.customer.first_name +
        " " +
        store.state.pos.retail.customer.last_name +
        " \n";
    }
    if (store.state.pos.retail.customer.street !== null) {
      customerData +=
        store.state.pos.retail.customer.street +
        " " +
        store.state.pos.retail.customer.street_no +
        "\n " +
        store.state.pos.retail.customer.zip +
        " " +
        store.state.pos.retail.customer.city +
        " \n";
    }
  }

  //CALC TAX AND TOTAL
  //TAX DECIMAL => array(NET,TOTAL,TAXTOTAL)
  //NUMBER OF ITEMS FOR DELIVERY SERVICE
  let itemsNumber = 0;
  Array.from(items).forEach((item) => {
    if (item.isVoid) return;

    let itemSellPrice = item.sellPrice;

    // INCREMENTING ITEMSNUMBER
    itemsNumber += Math.abs(item.amount);

    //CHECK IF DEPOSIT
    if (item.hasOwnProperty("depositgroupID")) {
      if (item.depositgroupID > 0) {
        let deposit = store.getters["deposit/depositgroups"].find(
          (depositGroup) => {
            return depositGroup.id === item.depositgroupID;
          }
        );

        if (deposit) itemSellPrice += deposit.priceTotal;
      }
    }

    //CALC TOTAL PRICE
    totalprice += roundNumber(
      parseFloat(itemSellPrice * Math.abs(item.amount)),
      2
    );

    //CHECK IF VOUCHER
    if (
      (item.ean.includes("GF$") ||
        item.ean.includes("V$") ||
        item.ean.includes("ZMYLE$")) &&
      item.sellPrice < 0
    )
      return;

    if (item.isMenuItem === 1 && item.takeAwayTaxing > 0 && data.offSite > 0) {
      // MENU ITEM
      // GET TAX VALUES FROM ITEM
      for (let taxValue in item.menuTaxRules) {
        if (!item.menuTaxRules.hasOwnProperty(taxValue)) continue;

        let taxPercentage = item.menuTaxRules[taxValue];
        taxPercentage = taxPercentage / 100;

        taxValue = parseFloat(taxValue);

        //CHECK IF OBJECT VALUE EXISTS
        if (typeof taxCalcObj[taxValue] === "undefined") {
          taxCalcObj[taxValue] = [];
          taxCalcObj[taxValue]["net"] = 0.0;
          taxCalcObj[taxValue]["total"] = 0.0;
          taxCalcObj[taxValue]["tax"] = 0.0;
        }

        // CALC TAX OF PERC.

        let calcNet = parseFloat(
          format((itemSellPrice * taxPercentage) / (1 + taxValue / 100), {
            precision: 14,
          }) * Math.abs(item.amount)
        );
        let calcTax =
          parseFloat(
            format(itemSellPrice * taxPercentage, { precision: 14 }) *
              Math.abs(item.amount)
          ) - calcNet;

        taxCalcObj[taxValue]["net"] += calcNet;
        taxCalcObj[taxValue]["tax"] += calcTax;
        taxCalcObj[taxValue]["total"] += parseFloat(
          itemSellPrice * taxPercentage * Math.abs(item.amount)
        );
      }
    } else {
      //CHECK IF OBJECT VALUE EXISTS
      if (typeof taxCalcObj[item.taxValue] === "undefined") {
        taxCalcObj[item.taxValue] = [];
        taxCalcObj[item.taxValue]["net"] = 0.0;
        taxCalcObj[item.taxValue]["total"] = 0.0;
        taxCalcObj[item.taxValue]["tax"] = 0.0;
      }

      let calcNet = parseFloat(
        format(
          (itemSellPrice * Math.abs(item.amount)) / (1 + item.taxValue / 100),
          { precision: 14 }
        )
      );
      //let calcNet = roundNumber(parseFloat((format(roundNumber((item.sellPrice * item.qty),2) / (1 + (item.tax / 100)), {precision: 14}))),2);
      let calcTax = parseFloat(itemSellPrice * Math.abs(item.amount)) - calcNet;
      taxCalcObj[item.taxValue]["net"] += calcNet;
      taxCalcObj[item.taxValue]["tax"] += calcTax;
      taxCalcObj[item.taxValue]["total"] += parseFloat(
        itemSellPrice * Math.abs(item.amount)
      );
    }
  });

  if (vouchersValue > 0) {
    //PREPARE 0% TAX ARRAY
    taxCalcObj[0] = [];
    taxCalcObj[0]["net"] = 0;
    taxCalcObj[0]["total"] = 0;
    taxCalcObj[0]["tax"] = 0;

    //CHECK IF VOUCHERS VALUE IS GREATER THEN GOODS VALUE
    if (vouchersValue >= goodsValue) {
      let calcNet = parseFloat(goodsValue / (1 + 0 / 100));
      let calcTax = parseFloat(goodsValue) - calcNet;

      taxCalcObj[0]["net"] += calcNet * -1;
      taxCalcObj[0]["tax"] += calcTax * -1;
      taxCalcObj[0]["total"] += parseFloat(goodsValue) * -1;

      totalprice = 0;
    } else {
      let calcNet = parseFloat((vouchersValue / (1 + 0 / 100)).toFixed(2));
      let calcTax = parseFloat(vouchersValue) - calcNet;

      taxCalcObj[0]["net"] += calcNet * -1;
      taxCalcObj[0]["tax"] += calcTax * -1;
      taxCalcObj[0]["total"] += parseFloat(vouchersValue) * -1;
    }
  }

  // --- DATE ---
  let today = new Date(data["ts"] * 1000);

  if (data["ts"] < 1000) {
    today = new Date();
  }

  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();
  let hours, minutes;

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  hours = today.getHours();
  minutes = today.getMinutes();

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  //CALC MONEY BACK
  let moneyBack = data.moneyGiven - totalprice;
  if (moneyBack < 0) moneyBack = 0;

  //VOUCHER CASE:
  if (totalprice <= 0) {
    moneyBack = 0;
    if (data.moneyGiven > 0.0) {
      moneyBack = data.moneyGiven;
    }
  }

  let tipValue = 0.0;
  if (data.restAsTip) {
    tipValue = parseFloat(roundNumber(data.moneyGiven - totalprice, 2));
    moneyBack = 0;
  }

  //PAYMENT TYPE
  let paymentType = data.paymentType.toString();
  let paymentTypeID = parseInt(paymentType);
  let paymentNote = getPaymentTypeNote(paymentType)
    ? getPaymentTypeNote(paymentType)
    : "";
  paymentType = convertPaymentType(paymentType);

  //PRINTER TEMPLATE
  let printWidth = printerOpts.paperType;
  let printerTmpl = lodash.cloneDeep(store.getters["printer/templatesRetail"]);
  let printerTmplSettings = lodash.cloneDeep(
    store.getters["printer/templatesRetailSetting"]
  );
  let usePercentForDiscount = true;
  let printEanAfterItemName = printerTmplSettings.hasOwnProperty(
    "printEanAfterItemName"
  )
    ? Number(printerTmplSettings.printEanAfterItemName) > 0
    : false;

  if (printerTmplSettings.hasOwnProperty("discountDisplay")) {
    if (printerTmplSettings.discountDisplay) {
      if (printerTmplSettings.discountDisplay === "percent") {
        usePercentForDiscount = true;
      } else if (printerTmplSettings.discountDisplay === "number") {
        usePercentForDiscount = false;
      }
    }
  }

  let displayPriceChange = 0;
  if (typeof printerTmplSettings.displayPriceChangesOnBon === "undefined") {
    displayPriceChange = 0;
  } else if (parseInt(printerTmplSettings.displayPriceChangesOnBon) > 0) {
    displayPriceChange = 1;
  }
  Array.from(printerTmpl).forEach(function (templateElement) {
    let templateElementCopy = Object.assign({}, templateElement);

    if (templateElement["barcode128"]) {
      //CHECK FOR INTERIMS PRINT
      if (interimsPrint || deliveryNotePrint) {
        if (templateElement.barcode128.includes("@@INVOICEID@@")) {
          return;
        }
      }

      templateElement["barcode128"] = templateElement["barcode128"].replace(
        "@@INVOICEID@@",
        data.saleID
      );

      printObj.push(templateElement);

      // ADD PAGER NUMBER
      // PRINT ALSO PAGER NUMBER
      if (data.hasOwnProperty("pagerNo"))
        if (data.pagerNo !== null) {
          printObj.push({ feed: 1 });
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({
            text:
              geol.t("generic.lang_Pagernummer") +
              ": " +
              data.pagerNo,
          });
          printObj.push({ fontSize: 1 });
          printObj.push({ reverse: "off" });
        }
    } else if (templateElement["qrCode"]) {
      if (
        !data.hasOwnProperty("tableNo") ||
        !templateElement["qrCode"].includes("@@TABLENO@@") ||
        !(data.tableNo && data.tableNo.toString() !== "0")
      ) {
        return;
      }
      templateElement["qrCode"] = templateElement["qrCode"].replace(
        "@@TABLENO@@",
        data.tableNo
      );

      printObj.push(templateElement);
    } else if (templateElement["invoiceSignature"]) {
      if (!data.hasOwnProperty("invoiceSignature") || !data.invoiceSignature) {
        return;
      }
      printObj.push({ signature: data.invoiceSignature });
    } else if (templateElement["text"]) {
      //CHECK FOR INTERIMS PRINT
      if (interimsPrint || deliveryNotePrint) {
        if (
          templateElement.text.includes("@@INVOICEID@@") ||
          templateElement.text.includes("@@MONEYBACK@@") ||
          templateElement.text.includes("@@MONEYGIVEN@@")
        ) {
          return;
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICEDATE@@",
        dd + "." + mm + "." + yyyy
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICETIME@@",
        hours + ":" + minutes
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICEID@@",
        data.saleID
      );

      if(data.partyName)
        templateElement["text"] = templateElement["text"].replace(
          "@@PARTYNAME@@",
          data.partyName
        );

      if (templateElement.text.includes("@@CASHIERID@@")) {
        // AUSSER HAUS
        if (data.hasOwnProperty("takeAway")) {
          if (data.takeAway === 1) {
            printObj.push({
              text: geol.t("generic.lang_offSiteSale"),
            });
          }
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@CASHIERID@@",
        data.cashierID
      );

      // CHECK TABLE AND SKIPPING IF ITS DELIVERY SERVICE
      if (templateElement.text.includes("@@TABLENO@@")) {
        if (
          data.tableNo.length === 0 ||
          data.tableNo.toString() === "0" ||
          data.hasOwnProperty("isDelivery")
        ) {
          return; // SKIP THIS TEXT ELEMENT
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@TABLENO@@",
        data.tableNo
      );

      let sellerPrintText = parseInt(data.userID);

      if (printerOpts.hasOwnProperty("showWaiterName")) {
        if (parseInt(printerOpts.showWaiterName) === 1) {
          sellerPrintText =
            activeUser.name +
            " " +
            activeUser.last_name +
            " (" +
            activeUser.userID +
            ")";
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@SALESCLERKID@@",
        sellerPrintText
      );

      templateElement["text"] = templateElement["text"].replace(
        "@@SALESTYPE@@",
        ""
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@TOTAL@@",
        formatCurrency(totalprice)
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@MONEYBACK@@",
        formatCurrency(moneyBack)
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@TIPP@@",
        formatCurrency(tipValue)
      );

      // CHECK IF SPLIT PAYMENT
      if (paymentTypeID !== 7) {
        if (paymentTypeID === 1) {
          templateElement["text"] = templateElement["text"].replace(
            "@@MONEYGIVEN@@",
            formatCurrency(data.moneyGiven)
          );
        } else {
          //templateElement["text"] = templateElement["text"].replace("@@MONEYGIVEN@@", totalprice.toFixed(2).toString().replace(".", ","));
          templateElement["text"] = templateElement["text"].replace(
            "@@MONEYGIVEN@@",
            formatCurrency(data.moneyGiven)
          );
        }
        templateElement["text"] = templateElement["text"].replace(
          "@@PAYMENTTYPE@@",
          paymentType
        );
      } else {
        if (templateElement.hasOwnProperty("text")) {
          if (templateElement.text.includes("@@PAYMENTTYPE@@")) {
            if (data.hasOwnProperty("splitPayments")) {
              let splitPrintText = geol.t("generic.lang_splitpay") + ":";

              data.splitPayments.forEach((splitPayment) => {
                if (splitPayment.amount === 0) return;

                splitPrintText +=
                  "\n" +
                  splitPayment.label +
                  ": " +
                  formatCurrency(splitPayment.amount);
              });

              templateElement["text"] = splitPrintText;
            }
          }
        }
      }

      printObj.push(templateElement);

      if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
        if (
          data.tableNo.toString() === "0" &&
          pickUpNo.toString().length > 0 &&
          store.state.settings.settings.pickup_enable > 0 &&
          !data.hasOwnProperty("isDelivery")
        ) {
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });

          if (data.pagerNo === null) {
            printObj.push({
              text: geol.t("generic.lang_pickUpNumber") + ": " + pickUpNo,
            });
          }

          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
          printObj.push({ align: "center" });
        }

        if (!data.hasOwnProperty("isDelivery")) {
          printObj.push({ align: "center" });
          printObj.push({ text: customerData });
          printObj.push({ align: "left" });
        }
      }

      //CHECK INTERIMS BILL
      if (interimsPrint) {
        if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
          //INSERT INTERIMS BILL TEXT
          printObj.push({ feed: 1 });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({ reverse: "on" });
          printObj.push({ text: geol.t("accounting.lang_Zwischenrechnung") });
          printObj.push({ text: geol.t("accounting.langINValid") });
          printObj.push({ reverse: "off" });
          printObj.push({ feed: 1 });
          if (!data.hasOwnProperty("isDelivery")) {
            printObj.push({ align: "center" });
            printObj.push({ fontSize: 1 });
            printObj.push({ text: customerData });
            printObj.push({ align: "left" });
          }
        }
      }

      if (deliveryNotePrint) {
        if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
          //INSERT INTERIMS BILL TEXT
          printObj.push({ feed: 1 });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({ reverse: "on" });
          printObj.push({ text: geol.t("generic.lang_deliveryNote") });
          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
          printObj.push({ feed: 1 });
          printObj.push({ text: customerData });
          printObj.push({ align: "left" });
        }
      }

      //INSERTING DELIVERY SERVICE DATA
      if (data.hasOwnProperty("isDelivery")) {
        if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
          // START PRINT PICK PICKUP NUMBER
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({
            text: geol.t("erp.lang_orderNo") + ": " + pickUpNo,
          });
          // START PRINTING THE VOR BASED ON THE isAsap ATTRIBUTE
          if (!data.isAsap) {
            printObj.push({ feed: 1 });
            printObj.push({
              text: geol.t("datatables.lang_dtNext").toUpperCase(),
            });
          }
          // END PRINTING
          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
          printObj.push({ feed: 1 });
          // END PRINT PICK PICKUP NUMBER

          // START
          printObj.push({ align: "left" });
          printObj.push({ fontSize: 2 });
          printObj.push({
            text: data.isDelivery
              ? geol.t("delivery.lang_delivery") +
                " " +
                (data.serviceTime ? data.serviceTime : "")
              : geol.t("delivery.lang_pickup") +
                " " +
                (data.serviceTime ? data.serviceTime : ""),
          });
          // END PRINT SERVICE TYPE DELIVERY/PICKUP

          // START PRINT CUSTOMER DATA
          if (data.isDelivery) {
            printObj.push({ reverse: "on" });
            printObj.push({ text: customerPhone });
            printObj.push({ reverse: "off" });
            printObj.push({ text: customerData });
            if (customerFloor && customerFloor.toString().length > 0) {
              printObj.push({
                text: geol.t("generic.lang_floorNo") + " : " + customerFloor,
              });
            }
            //printing the qr code
            if (customerAddress.toString().length > 0) {
              printObj.push({ align: "right" });
              printObj.push({
                qrCode: "https://www.google.com/maps?daddr=" + customerAddress,
              });
            }
            // adding the payment method only for delivery
            printObj.push({ align: "center" });
            printObj.push({ reverse: "on" });
            printObj.push({ text: paymentType });
            printObj.push({ reverse: "off" });
            printObj.push({ align: "left" });
          } else {
            printObj.push({ text: customerData });
          }
          // END PRINT CUSTOMER DATA

          printObj.push({ feed: 1 });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({ text: geol.t("generic.lang_numberOfArticles") });
          printObj.push({ reverse: "on" });
          printObj.push({ text: itemsNumber.toString() });
          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
        }
      }
    } else if (templateElement["shoppingCart"]) {
      let voucherLeftToPay = goodsValue;
      let hasWeightedItems = false;
      if (store.getters["scale/isEnabled"]) {
        hasWeightedItems =
          Array.from(items).filter((item) => item.isWeighted).length > 0;
      }

      let weightQTYSpace =
        store.getters["scale/isEnabled"] && hasWeightedItems ? 7 : 5;

      Array.from(items).forEach((item) => {
        //CHECK IF ITEM IS VOIDED
        if (item.isVoid || (item.ean && item.ean.includes("$TIP$"))) return;

        let singlePrice = formatCurrency(item.sellPrice);
        let totalsinglePrice = formatCurrency(
          parseFloat(item.sellPrice) * parseFloat(item.amount)
        );
        let oldPrice = formatCurrency(item.originalSellPrice);
        let showKg = "";

        if (store.getters["scale/isEnabled"] && item.isWeighted) {
          showKg = "/kg";
        }

        const paddingSpace = item.isWeighted
          ? "".padEnd(weightQTYSpace, " ")
          : "".padEnd(weightQTYSpace + 3, " ");

        //CHECK IF VOUCHER VALUE IS GREATER THEN TOTAL VALUE
        if (
          (item.ean.includes("GF$") ||
            item.ean.includes("V$") ||
            item.ean.includes("ZMYLE$")) &&
          item.sellPrice < 0
        ) {
          let voucherValue = item.sellPrice * -1;

          if (voucherLeftToPay > 0) {
            if (voucherValue >= voucherLeftToPay) {
              voucherValue = voucherLeftToPay;
              voucherLeftToPay = 0;
            } else {
              voucherLeftToPay -= voucherValue;
            }
          }

          voucherValue = voucherValue * -1;

          singlePrice = formatCurrency(voucherValue);
          totalsinglePrice = formatCurrency(voucherValue);
        }

        if (printWidth === 50) {
          printObj.push({ align: "left" });
          printObj.push({ text: "    " + item.name.substr(0, 45) });
          printObj.push({ align: "left" });
          printObj.push({
            text:
              "    " +
              ((item.isWeighted
                ? "{{" + item.scalePositionId + ".weight}}"
                : item.amount.toString()) +
                " X ") +
              " " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".price}}" + showKg
                : singlePrice) +
              " = " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".weightedPrice}}"
                : totalsinglePrice),
          });
          // SERIAL NUMBER PRINTING
          if (item.hasOwnProperty("serialNo") && item.serialNo) {
            printObj.push({
              text: paddingSpace + item.serialNo,
            });
          }

          if (printEanAfterItemName) {
            printObj.push({ text: paddingSpace + item.ean });
          }
          printObj.push({ feed: 1 });
        } else {
          let itemNameLines = wordBreakItemName(item.name);
          printObj.push({
            text:
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".weight}}"
                : item.amount
                    .toString()
                    .substr(0, weightQTYSpace)
                    .padEnd(weightQTYSpace, " ") + " X ") +
              itemNameLines[0].trim().padEnd(9, " ") +
              " " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".price}}" + showKg
                : singlePrice.padStart(11, " ")) +
              " " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".weightedPrice}}"
                : totalsinglePrice.padStart(11, " ")),
          });

          if (itemNameLines.length > 1) {
            printObj.push({
              text: paddingSpace + itemNameLines[1],
            });
          }
          // SERIAL NUMBER PRINTING
          if (item.hasOwnProperty("serialNo") && item.serialNo) {
            printObj.push({
              text: paddingSpace + item.serialNo,
            });
          }

          if (printEanAfterItemName) {
            printObj.push({ text: paddingSpace + item.ean });
          }

          if (
            displayPriceChange > 0 &&
            item.sellPrice.toFixed(2) != item.originalSellPrice.toFixed(2) &&
            item.discount == 0
          ) {
            printObj.push({
              text:
                paddingSpace + geol.t("generic.lang_oldPrice") + " " + oldPrice,
            });
          } else if (
            displayPriceChange > 0 &&
            item.discount > 0 &&
            usePercentForDiscount
          ) {
            printObj.push({
              text:
                paddingSpace +
                item.discount.toFixed(2) +
                geol.t("generic.lang_discountRedeemed"),
            });
          } else if (
            displayPriceChange > 0 &&
            item.discount > 0 &&
            !usePercentForDiscount
          ) {
            let priceDiff = item.originalSellPrice - item.sellPrice;
            printObj.push({
              text:
                paddingSpace +
                priceDiff.toFixed(2) +
                geol.t("generic.lang_discountRedeemedEuro"),
            });
          }

          //GARNISH PRINTING
          Array.from(item.selectedExtras || []).forEach((itemExtra) => {
            // BUG FIXING (IF NAME IS EMPTY)
            if (itemExtra.name === undefined || itemExtra.name === null) return;

            if (itemExtra.name.trim().length === 0) return;

            printObj.push({
              text:
                "  +  " +
                (itemExtra.amount.toString() + "X ")
                  .substr(0, 5)
                  .padEnd(5, " ") +
                itemExtra.name.substr(0, 21).padEnd(21, " "),
            });
          });

          //DEPOSIT PRINTING
          if (item.hasOwnProperty("depositgroupID")) {
            if (item.depositgroupID > 0) {
              let deposit = store.getters["deposit/depositgroups"].find(
                (depositGroup) => {
                  return depositGroup.id === item.depositgroupID;
                }
              );

              if (deposit) {
                printObj.push({
                  text:
                    paddingSpace +
                    deposit.name.substr(0, 21).padEnd(21, " ") +
                    " " +
                    formatCurrency(deposit.priceTotal)
                      .toString()
                      .padEnd(9, " ") +
                    " " +
                    formatCurrency(deposit.priceTotal * item.amount)
                      .toString()
                      .padEnd(8, " "),
                });
              }
            }
          }
        }
      });

      if (hasWeightedItems) {
        printObj.push({ align: "left" });
        printObj.push({ text: "___________________________________________" });
        printObj.push({
          text:
            geol.t("generic.lang_sum").padEnd(37, " ") +
            formatCurrency(totalprice).substr(0, 8).padEnd(8, " "),
        });
        printObj.push({ text: "___________________________________________" });
        printObj.push({
          text:
            paymentType.padEnd(37, " ") +
            formatCurrency(totalprice).substr(0, 8).padEnd(8, " "),
        });
      }
    } else if (templateElement["taxElement"] && !deliveryNotePrint) {
      if (printWidth === 50) {
        printObj.push({ align: "center" });
        printObj.push({ text: geol.t("generic.lang_taxOverview") });
        printObj.push({ align: "left" });

        Object.keys(taxCalcObj).forEach(function (element) {
          printObj.push({
            text:
              geol.t("accounting.lang_turnover") +
              " " +
              parseFloat(element).toFixed(2).toString().replace(".", ",") +
              "%   " +
              formatCurrency(taxCalcObj[element]["total"]),
          });
          printObj.push({
            text:
              geol.t("generic.lang_subtotal") +
              " " +
              parseFloat(element).toFixed(2).toString().replace(".", ",") +
              "%   " +
              formatCurrency(taxCalcObj[element]["net"]),
          });
          printObj.push({
            text:
              geol.t("accounting.lang_taxes") +
              " " +
              parseFloat(element).toFixed(2).toString().replace(".", ",") +
              "%   " +
              formatCurrency(taxCalcObj[element]["tax"]),
          });
        });

        printObj.push({ feed: 1 });
      } else {
        printObj.push({ align: "center" });
        printObj.push({ text: geol.t("generic.lang_taxOverview") });
        printObj.push({ align: "left" });
        printObj.push({
          text:
            centerPad("ID", 8) +
            centerPad(geol.t("accounting.lang_taxes") + "%", 13) +
            centerPad("Total", 13) +
            centerPad("Net", 13) +
            centerPad(geol.t("accounting.lang_taxes"), 8),
        });
        var taxID = 0;
        Object.keys(taxCalcObj).forEach(function (element) {
          taxID += 1;
          let taxRate = (
            parseFloat(element).toFixed(2).toString().replace(".", ",") + "%"
          ).padStart(8);
          let total = formatCurrency(taxCalcObj[element]["total"]).padStart(13);
          let net = formatCurrency(taxCalcObj[element]["net"]).padStart(13);
          let tax = formatCurrency(taxCalcObj[element]["tax"]).padStart(13);

          printObj.push({
            text: centerPad(taxID.toString(), 8) + taxRate + total + net + tax,
          });
        });

        printObj.push({ feed: 1 });
      }
    } else {
      printObj.push(templateElement);
    }
  });

  if (interimsPrint) {

    if(data.hasOwnProperty('savedOrderUUID') && data.savedOrderUUID){
      printObj.push({feed: 1});
      printObj.push({align: "left"});
      printObj.push({fontSize: 1,});
      printObj.push({text: geol.t("erp.lang_orderID") + ": " + data.savedOrderUUID});
      printObj.push({align: "center"});
      printObj.push({qrCode: "SVORD$" + data.savedOrderUUID});
      printObj.push({feed: 1});
    }

    if (printerOpts.hasOwnProperty("showRoomNumber")) {
      if (parseInt(printerOpts.showRoomNumber) === 1) {
        printObj.push({
          fontSize: 2,
        });
        printObj.push({
          feed: 3,
        });
        printObj.push({
          text: geol.t("generic.lang_roomNo") + ": ",
        });
        printObj.push({
          feed: 4,
        });
        printObj.push({
          align: "center",
        });
        printObj.push({
          text: "_____________________________________",
        });
        printObj.push({
          text: geol.t("generic.lang_signature"),
        });
        printObj.push({
          align: "left",
        });
        printObj.push({
          feed: 4,
        });
      }
    }
  }

  if (deliveryNotePrint) {
    printObj.push({
      fontSize: 1,
    });

    printObj.push({
      feed: 2,
    });
    printObj.push({
      align: "center",
    });
    printObj.push({
      text: "_____________________________________",
    });
    printObj.push({
      text: geol.t("generic.lang_signature"),
    });
    printObj.push({
      align: "left",
    });
    printObj.push({
      feed: 4,
    });
  }

  if(data.hasOwnProperty("isDelivery") && data.freeText){
    printObj.push({
      align: "center",
    });
    printObj.push({
      fontSize: 1,
    });
    printObj.push({
      text: data.freeText,
    });
    printObj.push({
      align: "left",
    });
    printObj.push({
      feed: 1,
    });
  }

  if (paymentNote) {
    printObj.push({
      align: "center",
    });
    printObj.push({
      fontSize: 1,
    });
    printObj.push({
      text: paymentNote,
    });
    printObj.push({
      align: "left",
    });
    printObj.push({
      feed: 1,
    });
  }

  if(data.fiscalJSON){
    printObj.push({ fontSize: 1 });

    printObj.push({
      font: "B"
    });
    printObj.push({
      text: geol.t('accounting.lang_eazSerNr') + ": " + data.fiscalJSON.client_id
    });
    printObj.push({
      text: geol.t('accounting.lang_tseSerNr') + ": " + data.fiscalJSON.tss_serial_number
    });
    printObj.push({
      text: geol.t('accounting.lang_tseStart') + ": " + data.fiscalJSON.time_start
    });
    printObj.push({
      text: geol.t('accounting.lang_tseStop') + ":  " + data.fiscalJSON.time_end
    });
    printObj.push({
      text: geol.t('accounting.lang_tseTrxNr') + ": " + data.fiscalJSON.number
    });
    printObj.push({
      text: geol.t('accounting.lang_tseSigCount') + ":" + data.fiscalJSON.signature.counter
    });
    printObj.push({
      text: geol.t('accounting.lang_signatur') + ":  " + data.fiscalJSON.signature.value
    });
  
    printObj.push({
      align: "center"
    });
  
    //ADD QR CODE
    printObj.push({
      qrCode: data.fiscalJSON.qr_code_data
    });
  }
  return printObj;
}

export function createPaperlesInvoicePrintingData(
  items,
  data,
  printerOpts,
  activeUser
) {
  let printObj = [];
  let totalprice = 0.0;

  // --- DATE ---
  let today = new Date(data["ts"] * 1000);

  if (data["ts"] < 1000) {
    today = new Date();
  }

  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();
  let hours, minutes;

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  hours = today.getHours();
  minutes = today.getMinutes();

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  let pickUpNo = "";
  if (data.hasOwnProperty("pickUpNo") && data.pickUpNo) {
    pickUpNo = data.pickUpNo;
  } else if (typeof store.state.pos.gastro.pickup === "undefined") {
    pickUpNo = "";
  } else {
    pickUpNo = store.state.pos.gastro.pickup;
  }
  Array.from(items).forEach((item) => {
    if (item.isVoid) return;

    let itemSellPrice = item.sellPrice;
    //CHECK IF DEPOSIT
    if (item.hasOwnProperty("depositgroupID")) {
      if (item.depositgroupID > 0) {
        let deposit = store.getters["deposit/depositgroups"].find(
          (depositGroup) => {
            return depositGroup.id === item.depositgroupID;
          }
        );

        if (deposit) itemSellPrice += deposit.priceTotal;
      }
    }

    //CALC TOTAL PRICE
    totalprice += parseFloat(
      roundNumber(itemSellPrice, 2) * Math.abs(item.amount)
    );

    //CHECK IF VOUCHER
    if (
      (item.ean.includes("GF$") ||
        item.ean.includes("V$") ||
        item.ean.includes("ZMYLE$")) &&
      item.sellPrice < 0
    )
      return;
  });
  //CALC MONEY BACK
  let moneyBack = data.moneyGiven - totalprice;
  if (moneyBack < 0) moneyBack = 0;

  //VOUCHER CASE:
  if (totalprice <= 0) {
    moneyBack = 0;
    if (data.moneyGiven > 0.0) {
      moneyBack = data.moneyGiven;
    }
  }

  if (data.restAsTip) {
    moneyBack = 0;
  }

  //PAYMENT TYPE
  let paymentType = data.paymentType.toString();
  let paymentTypeID = parseInt(paymentType);
  paymentType = convertPaymentType(paymentType);

  let printerTmpl = lodash.cloneDeep(store.getters["printer/templatesRetail"]);

  Array.from(printerTmpl).forEach(function (templateElement) {
    let templateElementCopy = Object.assign({}, templateElement);
    if (templateElement["barcode128"]) {
      templateElement["barcode128"] = templateElement["barcode128"].replace(
        "@@INVOICEID@@",
        data.saleID
      );

      printObj.push(templateElement);

      // ADD PAGER NUMBER
      // PRINT ALSO PAGER NUMBER
      if (store.state.pos.gastro.hasOwnProperty("pagerNo"))
        if (store.state.pos.gastro.pagerNo !== null) {
          printObj.push({ feed: 1 });
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({
            text:
              geol.t("generic.lang_Pagernummer") +
              ": " +
              store.state.pos.gastro.pagerNo,
          });
          printObj.push({ fontSize: 1 });
          printObj.push({ reverse: "off" });
        }
    } else if (templateElement["text"]) {
      if (
        templateElementCopy.text.includes("@@TOTAL@@") ||
        templateElementCopy.text.includes("@@MONEYBACK@@") ||
        templateElementCopy.text.includes("@@MONEYGIVEN@@") ||
        templateElementCopy.text.includes("@@PAYMENTTYPE@@") ||
        templateElementCopy.text.includes("@@SALESTYPE@@") ||
        templateElementCopy.text.includes(
          "__________________________________________"
        ) ||
        templateElementCopy.text.includes(
          "------------------------------------------"
        ) ||
        templateElementCopy.text.includes(
          "     Artikel             Einz.-Pr.  Ges.-Pr."
        )
      )
        return;
      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICEDATE@@",
        dd + "." + mm + "." + yyyy
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICETIME@@",
        hours + ":" + minutes
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICEID@@",
        data.saleID
      );
        templateElement["text"] = templateElement["text"].replace(
          "@@PARTYNAME@@",
        store.state.pos.gastro.party?.name
        );

      if (templateElement.text.includes("@@CASHIERID@@")) {
        // AUSSER HAUS
        if (store.state.pos.gastro.hasOwnProperty("takeAway")) {
          if (store.state.pos.gastro.takeAway === 1) {
            printObj.push({
              text: geol.t("generic.lang_offSiteSale"),
            });
          }
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@CASHIERID@@",
        data.cashierID
      );

      // CHECK TABLE AND SKIPPING IF ITS DELIVERY SERVICE
      if (templateElement.text.includes("@@TABLENO@@")) {
        if (
          data.tableNo.length === 0 ||
          data.tableNo.toString() === "0" ||
          data.hasOwnProperty("isDelivery")
        ) {
          return; // SKIP THIS TEXT ELEMENT
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@TABLENO@@",
        data.tableNo
      );

      let sellerPrintText = parseInt(data.userID);

      if (printerOpts.hasOwnProperty("showWaiterName")) {
        if (parseInt(printerOpts.showWaiterName) === 1) {
          sellerPrintText =
            activeUser.name +
            " " +
            activeUser.last_name +
            " (" +
            activeUser.userID +
            ")";
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@SALESCLERKID@@",
        sellerPrintText
      );

      printObj.push(templateElement);

      if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
        if (
          data.tableNo.toString() === "0" &&
          pickUpNo.toString().length > 0 &&
          store.state.settings.settings.pickup_enable > 0
        ) {
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });

          if (store.state.pos.gastro.pagerNo === null) {
            printObj.push({
              text: geol.t("generic.lang_pickUpNumber") + ": " + pickUpNo,
            });
          }

          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
          printObj.push({ align: "center" });
        }
      }
    } else if (templateElement["feed"] === 3) {
      return;
    } else if (templateElement["shoppingCart"]) {
      return;
    } else if (templateElement["taxElement"]) {
      return;
    } else {
      printObj.push(templateElement);
    }
  });
  printObj.push({ align: "center" });
  printObj.push({
    qrCode:
      "https://" +
      store.state.settings.settings.servicesSubDomainName +
      ".paperlessinvoice.3pos.de/?i=" +
      data.invoiceUUID,
  });
  printObj.push({ align: "left" });
  printObj.push(
    ...[
      {
        font: "A",
      },
      {
        fontSize: 2,
      },
      {
        align: "left",
      },
      {
        text: geol.t("generic.lang_total") + ": " + formatCurrency(totalprice),
      },
      {
        fontSize: 1,
      },
      {
        text:
          geol.t("generic.lang_receipt_gegeben") +
          "  " +
          paymentType +
          " : " +
          formatCurrency(data.moneyGiven),
      },
      {
        fontSize: 1,
      },
      {
        text: geol.t("generic.lang_back") + ": " + formatCurrency(moneyBack),
      },
    ]
  );
  printObj.push({ cashDrawer: true });
  printObj.push({ cut: true });
  return printObj;
}

function getPickUpNo(data) {
  let pickUpNo = "";

  if (data.hasOwnProperty("pickUpNo") && data.pickUpNo) {
    pickUpNo = data.pickUpNo;
  }
  return pickUpNo;
}

function addRoomNumber(printerOpts, printObj) {
  if (
    printerOpts.hasOwnProperty("showRoomNumber") &&
    Number(printerOpts.showRoomNumber) === 1
  ) {
    printObj.push({ fontSize: 2 });
    printObj.push({ feed: 3 });
    printObj.push({
      text: i18n.t("generic.lang_roomNo", getGeoLocal()) + ": ",
    });
    printObj.push({ feed: 4 });
    printObj.push({ align: "center" });
    printObj.push({ text: "_____________________________________" });
    printObj.push({ text: i18n.t("generic.lang_signature", getGeoLocal()) });
    printObj.push({ align: "left" });
    printObj.push({ feed: 4 });
  }
}

function getGeoLocal() {
  return store.getters["settings/getSettingValue"]("geoloc_systemcountry") ===
    "ma"
    ? "fr"
    : store.getters["settings/getSettingValue"]("geoloc_systemcountry") ===
        "ma";
}

function addPaymentNoteData(paymentNote, printObj) {
  if (paymentNote) {
    printObj.push({
      align: "center",
    });
    printObj.push({
      fontSize: 1,
    });
    printObj.push({
      text: paymentNote,
    });
    printObj.push({
      align: "left",
    });
    printObj.push({
      feed: 1,
    });
  }
}
export function createInterimInvoicePrintingData(
  items,
  invoiceData,
  printerOpts,
  activeUser,
  additionalData = {}
) {
  let printObj = [];

  let { totalprice, taxCalcObj, itemsNumber } =
    calculateItemsTotalAndTax(items);
  let { customerData, customerAddress, customerPhone, customerFloor } =
    formatCustomerData(invoiceData);
  let { date, time } = formatDateAndTime(invoiceData["ts"]);
  let { moneyBack, tipValue } = calculateMoneyBackAndTip(
    invoiceData,
    totalprice
  );
  let { paymentType, paymentTypeID, paymentNote } =
    processPaymentType(invoiceData);
  let pickUpNo = getPickUpNo(invoiceData);

  // TODO: refactor into a function
  if (invoiceData.vouchersValue > 0) {
    //PREPARE 0% TAX ARRAY
    taxCalcObj[0] = [];
    taxCalcObj[0]["net"] = 0;
    taxCalcObj[0]["total"] = 0;
    taxCalcObj[0]["tax"] = 0;

    //CHECK IF VOUCHERS VALUE IS GREATER THEN GOODS VALUE
    if (invoiceData.vouchersValue >= invoiceData.goodsValue) {
      let calcNet = parseFloat(invoiceData.goodsValue / (1 + 0 / 100));
      let calcTax = parseFloat(invoiceData.goodsValue) - calcNet;

      taxCalcObj[0]["net"] += calcNet * -1;
      taxCalcObj[0]["tax"] += calcTax * -1;
      taxCalcObj[0]["total"] += parseFloat(invoiceData.goodsValue) * -1;

      totalprice = 0;
    } else {
      let calcNet = parseFloat(
        (invoiceData.vouchersValue / (1 + 0 / 100)).toFixed(2)
      );
      let calcTax = parseFloat(invoiceData.vouchersValue) - calcNet;

      taxCalcObj[0]["net"] += calcNet * -1;
      taxCalcObj[0]["tax"] += calcTax * -1;
      taxCalcObj[0]["total"] += parseFloat(invoiceData.vouchersValue) * -1;
    }
  }

  // Add header, date, and other invoice details
  addTraineeData(activeUser, printObj);

  // ...
  // TODO: same here refactor to functions and to depend on the vuex state only in case of fixed/constant data like settings, printer template etc...
  //PRINTER TEMPLATE
  let printWidth = printerOpts.paperType;
  let printerTmpl = lodash.cloneDeep(store.getters["printer/templatesRetail"]);
  let printerTmplSettings = lodash.cloneDeep(
    store.getters["printer/templatesRetailSetting"]
  );
  let usePercentForDiscount = true;

  if (printerTmplSettings.hasOwnProperty("discountDisplay")) {
    if (printerTmplSettings.discountDisplay) {
      if (printerTmplSettings.discountDisplay === "percent") {
        usePercentForDiscount = true;
      } else if (printerTmplSettings.discountDisplay === "number") {
        usePercentForDiscount = false;
      }
    }
  }

  let displayPriceChange = 0;
  if (typeof printerTmplSettings.displayPriceChangesOnBon === "undefined") {
    displayPriceChange = 0;
  } else if (parseInt(printerTmplSettings.displayPriceChangesOnBon) > 0) {
    displayPriceChange = 1;
  }
  Array.from(printerTmpl).forEach(function (templateElement) {
    let templateElementCopy = Object.assign({}, templateElement);

    if (templateElement["barcode128"]) {
      //CHECK FOR INTERIMS PRINT
      if (templateElement.barcode128.includes("@@INVOICEID@@")) {
        return;
      }

      templateElement["barcode128"] = templateElement["barcode128"].replace(
        "@@INVOICEID@@",
        invoiceData.saleID
      );

      printObj.push(templateElement);

      // ADD PAGER NUMBER
      // PRINT ALSO PAGER NUMBER
      if (store.state.pos.gastro.hasOwnProperty("pagerNo"))
        if (store.state.pos.gastro.pagerNo !== null) {
          printObj.push({ feed: 1 });
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({
            text:
              geol.t("generic.lang_Pagernummer") +
              ": " +
              store.state.pos.gastro.pagerNo,
          });
          printObj.push({ fontSize: 1 });
          printObj.push({ reverse: "off" });
        }
    } else if (templateElement["text"]) {
      //CHECK FOR INTERIMS PRINT
      if (
        templateElement.text.includes("@@INVOICEID@@") ||
        templateElement.text.includes("@@MONEYBACK@@") ||
        templateElement.text.includes("@@MONEYGIVEN@@")
      ) {
        return;
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICEDATE@@",
        date
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICETIME@@",
        time
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@INVOICEID@@",
        invoiceData.saleID
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@PARTYNAME@@",
        store.state.pos.gastro.party?.name
      );

      if (templateElement.text.includes("@@CASHIERID@@")) {
        // AUSSER HAUS
        if (store.state.pos.gastro.hasOwnProperty("takeAway")) {
          if (store.state.pos.gastro.takeAway === 1) {
            printObj.push({
              text: geol.t("generic.lang_offSiteSale"),
            });
          }
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@CASHIERID@@",
        invoiceData.cashierID
      );

      // CHECK TABLE AND SKIPPING IF ITS DELIVERY SERVICE
      if (templateElement.text.includes("@@TABLENO@@")) {
        if (
          invoiceData.tableNo.length === 0 ||
          invoiceData.tableNo.toString() === "0" ||
          invoiceData.hasOwnProperty("isDelivery")
        ) {
          return; // SKIP THIS TEXT ELEMENT
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@TABLENO@@",
        invoiceData.tableNo
      );

      let sellerPrintText = parseInt(invoiceData.userID);

      if (printerOpts.hasOwnProperty("showWaiterName")) {
        if (parseInt(printerOpts.showWaiterName) === 1) {
          sellerPrintText =
            activeUser.name +
            " " +
            activeUser.last_name +
            " (" +
            activeUser.userID +
            ")";
        }
      }

      templateElement["text"] = templateElement["text"].replace(
        "@@SALESCLERKID@@",
        sellerPrintText
      );

      templateElement["text"] = templateElement["text"].replace(
        "@@SALESTYPE@@",
        ""
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@TOTAL@@",
        formatCurrency(totalprice)
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@MONEYBACK@@",
        formatCurrency(moneyBack)
      );
      templateElement["text"] = templateElement["text"].replace(
        "@@TIPP@@",
        formatCurrency(tipValue)
      );

      // CHECK IF SPLIT PAYMENT
      if (paymentTypeID !== 7) {
        if (paymentTypeID === 1) {
          templateElement["text"] = templateElement["text"].replace(
            "@@MONEYGIVEN@@",
            formatCurrency(invoiceData.moneyGiven)
          );
        } else {
          //templateElement["text"] = templateElement["text"].replace("@@MONEYGIVEN@@", totalprice.toFixed(2).toString().replace(".", ","));
          templateElement["text"] = templateElement["text"].replace(
            "@@MONEYGIVEN@@",
            formatCurrency(invoiceData.moneyGiven)
          );
        }
        templateElement["text"] = templateElement["text"].replace(
          "@@PAYMENTTYPE@@",
          paymentType
        );
      } else {
        if (templateElement.hasOwnProperty("text")) {
          if (templateElement.text.includes("@@PAYMENTTYPE@@")) {
            if (invoiceData.hasOwnProperty("splitPayments")) {
              let splitPrintText = geol.t("generic.lang_splitpay") + ":";

              invoiceData.splitPayments.forEach((splitPayment) => {
                if (splitPayment.amount === 0) return;

                splitPrintText +=
                  "\n" +
                  splitPayment.label +
                  ": " +
                  formatCurrency(splitPayment.amount);
              });

              templateElement["text"] = splitPrintText;
            }
          }
        }
      }

      printObj.push(templateElement);

      if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
        if (
          invoiceData.tableNo.toString() === "0" &&
          pickUpNo.toString().length > 0 &&
          store.state.settings.settings.pickup_enable > 0 &&
          !invoiceData.hasOwnProperty("isDelivery")
        ) {
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });

          if (store.state.pos.gastro.pagerNo === null) {
            printObj.push({
              text: geol.t("generic.lang_pickUpNumber") + ": " + pickUpNo,
            });
          }

          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
          printObj.push({ align: "center" });
        }

        if (!invoiceData.hasOwnProperty("isDelivery")) {
          printObj.push({ align: "center" });
          printObj.push({ text: customerData });
          printObj.push({ align: "left" });
        }
      }

      //CHECK INTERIMS BILL
      if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
        //INSERT INTERIMS BILL TEXT
        printObj.push({ feed: 1 });
        printObj.push({ align: "center" });
        printObj.push({ fontSize: 2 });
        printObj.push({ reverse: "on" });
        printObj.push({ text: geol.t("accounting.lang_Zwischenrechnung") });
        printObj.push({ text: geol.t("accounting.langINValid") });
        printObj.push({ reverse: "off" });
        printObj.push({ feed: 1 });
        if (!invoiceData.hasOwnProperty("isDelivery")) {
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 1 });
          printObj.push({ text: customerData });
          printObj.push({ align: "left" });
        }
      }

      //INSERTING DELIVERY SERVICE DATA
      if (invoiceData.hasOwnProperty("isDelivery")) {
        if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
          // START PRINT PICK PICKUP NUMBER
          printObj.push({ reverse: "on" });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({
            text: geol.t("erp.lang_orderNo") + ": " + pickUpNo,
          });
          // START PRINTING THE VOR BASED ON THE isAsap ATTRIBUTE
          if (!invoiceData.isAsap) {
            printObj.push({ feed: 1 });
            printObj.push({
              text: geol.t("datatables.lang_dtNext").toUpperCase(),
            });
          }
          // END PRINTING
          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
          printObj.push({ feed: 1 });
          // END PRINT PICK PICKUP NUMBER

          // START
          printObj.push({ align: "left" });
          printObj.push({ fontSize: 2 });
          printObj.push({
            text: invoiceData.isDelivery
              ? geol.t("delivery.lang_delivery") +
                " " +
                (invoiceData.serviceTime ? invoiceData.serviceTime : "")
              : geol.t("delivery.lang_pickup") +
                " " +
                (invoiceData.serviceTime ? invoiceData.serviceTime : ""),
          });
          // END PRINT SERVICE TYPE DELIVERY/PICKUP

          // START PRINT CUSTOMER DATA
          if (invoiceData.isDelivery) {
            printObj.push({ reverse: "on" });
            printObj.push({ text: customerPhone });
            printObj.push({ reverse: "off" });
            printObj.push({ text: customerData });
            if (customerFloor && customerFloor.toString().length > 0) {
              printObj.push({
                text: geol.t("generic.lang_floorNo") + " : " + customerFloor,
              });
            }
            //printing the qr code
            if (customerAddress.toString().length > 0) {
              printObj.push({ align: "right" });
              printObj.push({
                qrCode: "https://www.google.com/maps?daddr=" + customerAddress,
              });
            }
            // adding the payment method only for delivery
            printObj.push({ align: "center" });
            printObj.push({ reverse: "on" });
            printObj.push({ text: paymentType });
            printObj.push({ reverse: "off" });
            printObj.push({ align: "left" });
          } else {
            printObj.push({ text: customerData });
          }
          // END PRINT CUSTOMER DATA

          printObj.push({ feed: 1 });
          printObj.push({ align: "center" });
          printObj.push({ fontSize: 2 });
          printObj.push({ text: geol.t("generic.lang_numberOfArticles") });
          printObj.push({ reverse: "on" });
          printObj.push({ text: itemsNumber.toString() });
          printObj.push({ reverse: "off" });
          printObj.push({ fontSize: 1 });
        }
      }
    } else if (templateElement["shoppingCart"]) {
      let voucherLeftToPay = invoiceData.goodsValue;
      let hasWeightedItems = false;
      if (store.getters["scale/isEnabled"]) {
        hasWeightedItems =
          Array.from(items).filter((item) => item.isWeighted).length > 0;
      }
      let weightQTYSpace =
        store.getters["scale/isEnabled"] && hasWeightedItems ? 7 : 5;

      Array.from(items).forEach((item) => {
        //CHECK IF ITEM IS VOIDED
        if (item.isVoid || (item.ean && item.ean.includes("$TIP$"))) return;

        let singlePrice = formatCurrency(item.sellPrice);
        let totalsinglePrice = formatCurrency(
          parseFloat(item.sellPrice) * parseFloat(item.amount)
        );
        let oldPrice = formatCurrency(item.originalSellPrice);
        let showKg = "";

        if (store.getters["scale/isEnabled"] && item.isWeighted) {
          showKg = "/kg";
        }

        //CHECK IF VOUCHER VALUE IS GREATER THEN TOTAL VALUE
        if (
          (item.ean.includes("GF$") ||
            item.ean.includes("V$") ||
            item.ean.includes("ZMYLE$")) &&
          item.sellPrice < 0
        ) {
          let voucherValue = item.sellPrice * -1;

          if (voucherLeftToPay > 0) {
            if (voucherValue >= voucherLeftToPay) {
              voucherValue = voucherLeftToPay;
              voucherLeftToPay = 0;
            } else {
              voucherLeftToPay -= voucherValue;
            }
          }

          voucherValue = voucherValue * -1;

          singlePrice = formatCurrency(voucherValue);
          totalsinglePrice = formatCurrency(voucherValue);
        }

        if (printWidth === 50) {
          printObj.push({ align: "left" });
          printObj.push({ text: "    " + item.name.substr(0, 45) });
          printObj.push({ align: "left" });
          printObj.push({
            text:
              "    " +
              ((item.isWeighted
                ? "{{" + item.scalePositionId + ".weight}}"
                : item.amount.toString()) +
                " X ") +
              " " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".price}}" + showKg
                : singlePrice) +
              " = " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".weightedPrice}}"
                : totalsinglePrice),
          });
          // SERIAL NUMBER PRINTING
          if (item.hasOwnProperty("serialNo") && item.serialNo) {
            printObj.push({ text: "     " + item.serialNo });
          }
          printObj.push({ feed: 1 });
        } else {
          printObj.push({
            text:
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".weight}}"
                : item.amount
                    .toString()
                    .substr(0, weightQTYSpace)
                    .padEnd(weightQTYSpace, " ") + " X ") +
              item.name.substr(0, 19).padEnd(19, " ") +
              " " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".price}}" + showKg
                : singlePrice.substr(0, 9).padEnd(9, " ")) +
              " " +
              (item.isWeighted
                ? "{{" + item.scalePositionId + ".weightedPrice}}"
                : totalsinglePrice.substr(0, 8).padEnd(8, " ")),
          });

          if (item.name.length > 19) {
            printObj.push({ text: "     " + item.name.substr(19, 80) });
          }
          // SERIAL NUMBER PRINTING
          if (item.hasOwnProperty("serialNo") && item.serialNo) {
            printObj.push({ text: "     " + item.serialNo });
          }

          if (
            displayPriceChange > 0 &&
            item.sellPrice.toFixed(2) != item.originalSellPrice.toFixed(2) &&
            item.discount == 0
          ) {
            printObj.push({
              text: "    " + geol.t("generic.lang_oldPrice") + " " + oldPrice,
            });
          } else if (
            displayPriceChange > 0 &&
            item.discount > 0 &&
            usePercentForDiscount
          ) {
            printObj.push({
              text:
                "     " +
                item.discount.toFixed(2) +
                geol.t("generic.lang_discountRedeemed"),
            });
          } else if (
            displayPriceChange > 0 &&
            item.discount > 0 &&
            !usePercentForDiscount
          ) {
            let priceDiff = item.originalSellPrice - item.sellPrice;
            printObj.push({
              text:
                "     " +
                priceDiff.toFixed(2) +
                geol.t("generic.lang_discountRedeemedEuro"),
            });
          }

          //GARNISH PRINTING
          Array.from(item.selectedExtras || []).forEach((itemExtra) => {
            // BUG FIXING (IF NAME IS EMPTY)
            if (itemExtra.name === undefined || itemExtra.name === null) return;

            if (itemExtra.name.trim().length === 0) return;

            printObj.push({
              text:
                "  +  " +
                (itemExtra.amount.toString() + "X ")
                  .substr(0, 5)
                  .padEnd(5, " ") +
                itemExtra.name.substr(0, 21).padEnd(21, " "),
            });
          });
          // TODO: optimize deposit group search by ading it to the item's data to reduce redundant search
          //DEPOSIT PRINTING
          if (item.hasOwnProperty("depositgroupID")) {
            if (item.depositgroupID > 0) {
              let deposit = store.getters["deposit/depositgroups"].find(
                (depositGroup) => {
                  return depositGroup.id === item.depositgroupID;
                }
              );

              if (deposit) {
                printObj.push({
                  text:
                    "     " +
                    deposit.name.substr(0, 21).padEnd(21, " ") +
                    " " +
                    formatCurrency(deposit.priceTotal)
                      .toString()
                      .padEnd(9, " ") +
                    " " +
                    formatCurrency(deposit.priceTotal * item.amount)
                      .toString()
                      .padEnd(8, " "),
                });
              }
            }
          }
        }
      });

      if (hasWeightedItems) {
        printObj.push({ align: "left" });
        printObj.push({ text: "___________________________________________" });
        printObj.push({
          text:
            geol.t("generic.lang_sum").padEnd(37, " ") +
            formatCurrency(totalprice).substr(0, 8).padEnd(8, " "),
        });
        printObj.push({ text: "___________________________________________" });
        printObj.push({
          text:
            paymentType.padEnd(37, " ") +
            formatCurrency(totalprice).substr(0, 8).padEnd(8, " "),
        });
      }
    } else if (templateElement["taxElement"]) {
      if (printWidth === 50) {
        printObj.push({ align: "center" });
        printObj.push({ text: geol.t("generic.lang_taxOverview") });
        printObj.push({ align: "left" });

        Object.keys(taxCalcObj).forEach(function (element) {
          printObj.push({
            text:
              geol.t("accounting.lang_turnover") +
              " " +
              parseFloat(element).toFixed(2).toString().replace(".", ",") +
              "%   " +
              formatCurrency(taxCalcObj[element]["total"]),
          });
          printObj.push({
            text:
              geol.t("generic.lang_subtotal") +
              " " +
              parseFloat(element).toFixed(2).toString().replace(".", ",") +
              "%   " +
              formatCurrency(taxCalcObj[element]["net"]),
          });
          printObj.push({
            text:
              geol.t("accounting.lang_taxes") +
              " " +
              parseFloat(element).toFixed(2).toString().replace(".", ",") +
              "%   " +
              formatCurrency(taxCalcObj[element]["tax"]),
          });
        });

        printObj.push({ feed: 1 });
      } else {
        printObj.push({ align: "center" });
        printObj.push({ text: geol.t("generic.lang_taxOverview") });
        printObj.push({ align: "left" });
        printObj.push({
          text:
            "ID       " +
            geol.t("accounting.lang_taxes") +
            "%       Total       Net       " +
            geol.t("accounting.lang_taxes"),
        });
        var taxID = 0;
        Object.keys(taxCalcObj).forEach(function (element) {
          taxID += 1;
          printObj.push({
            text:
              taxID +
              "       " +
              parseFloat(element).toFixed(2).toString().replace(".", ",") +
              "%       " +
              formatCurrency(taxCalcObj[element]["total"]) +
              "   " +
              formatCurrency(taxCalcObj[element]["net"]) +
              "   " +
              formatCurrency(taxCalcObj[element]["tax"]),
          });
        });

        printObj.push({ feed: 1 });
      }
    } else {
      printObj.push(templateElement);
    }
  });
  // ...

  // Add any additional data
  addRoomNumber(printerOpts, printObj);
  addPaymentNoteData(paymentNote, printObj);

  printObj.push({ cut: true });
  // Return the formatted print object
  // filter the cash drawer
  return printObj.filter((el) => !el.hasOwnProperty("cashDrawer"));
}

function wordBreakItemName(str) {
  if (!str) return [""]; // Return [""] if input is null, undefined, or not provided

  let words = str.split(" ");
  let lines = [];
  let line = "";
  let limit = 10; // Initial limit for the first line

  for (let i = 0; i < words.length; i++) {
    if (i === 0 && words[i].length > 10) {
      // Special case for the first word
      lines.push(words[i].substring(0, 10));
      line = words[i].substring(10, 52);
      limit = 42; // Update limit for the second line
    } else if ((line + " " + words[i]).trim().length <= limit) {
      line += " " + words[i];
    } else {
      lines.push(line);
      line = words[i];
      limit = 80; // Update limit for the remaining lines
    }
  }

  if (line !== "") {
    lines.push(line);
  }

  return lines;
}

function centerPad(str, length) {
  let leftPadding = Math.floor((length - str.length) / 2);
  let rightPadding = length - str.length - leftPadding;
  return str.padStart(str.length + leftPadding).padEnd(length);
}
