<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-alert type="info" outlined>
                  {{ $t('generic.lang_invoiceSignatureDescription') }}
                </v-alert>
                <v-switch :label="$t('settings.lang_enableInvoiceSigning')" v-model="enableInvoiceSigning"/>
                <!-- <v-select
                    v-model="selectedCashierIDs"
                    dense
                    :items="filteredCashierIDs" item-text="text" item-value="value"
                    :label="$t('generic.lang_cashierID')"
                    multiple
                    outlined
                ></v-select> -->
                <v-select
                    v-model="selectedPayments"
                    small-chips
                    deletable-chips
                    :items="mapedPayments" item-text="name" item-value="id"
                    :label="$t('generic.lang_paymentMethods')"
                    multiple
                    outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-0"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" :loading="loading" @click="saveSettings">
              {{ $t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import { mapState } from "vuex";
import payments from "../../../store/modules/payments";

export default {
  name: "InvoiceSignatureSettings",
  data() {
    return {
      loading: false,
      enableInvoiceSigning: false,
      selectedPayments: [],
      selectedCashierIDs:[],
      cashierIDs:[],
    }
  },
  watch:{
    printSmallReceipt(val){
      this.disableReceiptPrint = val==='1'?0:'1';
    },
    disableReceiptPrint(val){
      this.printSmallReceipt = val==='1'?0:'1';
    },
  },
  mounted() {
    this.getSettings();
    // this.getCashiers();
  },
  computed:{
    filteredCashierIDs() {
      return this.cashierIDs.map((cashierData) => {
        return {
          value: cashierData[0],
          text: "Kasse "+ cashierData[0] +((cashierData[1]&&cashierData[1].length>0)?(' ( '+ cashierData[1].replace(',',' ')+' ) '):''),
        };
      });
    },
    mapedPayments(){
      return this.payments.payments.map((payment) => ({
        ...payment,
        id: payment.paymentID,
        name: this.$t(payment.paymentNameTranslate) === payment.paymentNameTranslate ? payment.paymentName : this.$t(payment.paymentNameTranslate),
      }))
    },
    ...mapState([
      "payments",
    ])
  },
  methods: {
    getCashiers() {
      this.loading = true;

      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashierIDs = res.data.cashierIDs;
      }).finally(() => this.loading = false);
    },
    getSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.INVOICE_SIGNATURE.GET).then((res) => {
        if (res.data.success) {
          this.selectedPayments = res.data.selectedPayments || []
          this.enableInvoiceSigning = res.data.enabled || false
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    saveSettings() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.INVOICE_SIGNATURE.UPDATE, {
        selectedPayments: this.selectedPayments,
        enabled: this.enableInvoiceSigning
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  }

}
</script>