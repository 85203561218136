<template>
  <v-container fluid>
    <v-row>
      <!--menu-->
      <v-col cols="12" sm="12" md="3" class="pl-0">
        <b-card :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" class="mb-1" no-body>
          <b-card-header class="p-0" header-tag="header" role="tab">
            <v-list-item @click="opened = !opened" class="ma-0" v-b-toggle.global-settings>
              <v-list-item-title
                :class="[this.$vuetify.theme.dark ? '' : '', this.opened ? 'primary--text text-wrap' : 'text-wrap']">
                {{ this.$t('settings.lang_pickupMonitorInterface') }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon v-if="!this.opened">keyboard_arrow_down</v-icon>
                <v-icon v-if="this.opened">keyboard_arrow_up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </b-card-header>
          <b-collapse :visible="this.opened" accordion="global-settings" id="global-settings" role="tabpanel">
            <b-card-body class="pa-0">
              <b-list-group flush>
                <b-list-group-item :class="[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'general_settings'
                    ? 'primary--text'
                    : '',
                ]" @click="handleClick('general_settings')" class="items">
                  {{ this.$t("generic.lang_globalSettings") }}
                </b-list-group-item>
                <b-list-group-item :class="[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'colors' ? 'primary--text' : '',
                ]" @click="handleClick('colors')" class="items">
                  {{ this.$t("tablebee.lang_theme_colors") }}
                </b-list-group-item>

                <b-list-group-item :class="[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'text_colors' ? 'primary--text' : '',
                ]" @click="handleClick('text_colors')" class="items">
                  {{ this.$t("tablebee.lang_text_settings") }}
                </b-list-group-item>

                <b-list-group-item :class="[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'slideshow' ? 'primary--text' : '',
                ]" @click="handleClick('slideshow')" class="items">
                  {{ this.$t("generic.lang_showcaseDisplay") }}
                </b-list-group-item>

                <b-list-group-item :class="[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'messages' ? 'primary--text' : '',
                ]" @click="handleClick('messages')" class="items">
                  {{ this.$t("generic.lang_gui_messages") }}
                </b-list-group-item>

                <b-list-group-item :class="[
                  this.$vuetify.theme.dark ? 'dark-bg' : '',
                  this.currentTab === 'help' ? 'primary--text' : '',
                ]" @click="handleClick('help')" class="items">
                  {{ this.$t("Help") }}
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-collapse>
        </b-card>
      </v-col>

      <!--content-->
      <v-col cols="12" sm="12" md="9" class="pr-0">
        <v-card>
          <div role="tablist">
            <div v-if="currentTab === 'general_settings'">
              <GeneralSettings />
            </div>
            <div v-if="currentTab === 'colors'">
              <ThemeColors />
            </div>
            <div v-if="currentTab === 'text_colors'">
              <TextSettings />
            </div>
            <div v-if="currentTab === 'slideshow'">
              <!-- <ShowcaseDisplay /> -->
            </div>
            <div v-if="currentTab === 'messages'">
              <Messages />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GeneralSettings from './GeneralSettings.vue';
import Messages from './Messages.vue';
import ShowcaseDisplay from './ShowcaseDisplay.vue';
import TextSettings from './TextSettings.vue';
import ThemeColors from './ThemeColors.vue';


export default {
  name: "PickupMonitorSettings",
  components: {
    GeneralSettings,
    TextSettings,
    ThemeColors,
    Messages,
    ShowcaseDisplay,
  },
  data() {
    return {
      opened: true,
      currentTab: 'general_settings'
    };
  },
  methods: {
    handleClick(newTab) {
      if (newTab && newTab.length > 0) {
        this.currentTab = newTab;
        this.$router.push({ query: { tab: newTab } });
      }
    }
  },
  mounted() {
    this.handleClick(this.$route.query.tab);
  }
};
</script>
<style>
.items {
  cursor: pointer;
  user-select: none;
}
</style>