<template>
  <div></div>
</template>

<script>
export default {
  name: "GastroTresenverkauf",

  data() {
    return {
      posType: "gastro"
    }
  },

  mounted() {
    this.$store.dispatch("pos/gastro/setTable", {
      table: {
        name: 0,
        parties: [
          {
            name: "Partei 1"
          }
        ]
      }
    }).then(() => {
      this.$store.dispatch("pos/gastro/setDirectSaleCachedItems");
      this.$router.replace("/pos/gastro");
    });
  }
}
</script>
