<template>
  <div class="mb-3 card">
    <div>
      <v-tabs v-model="tab">
        <v-tab key="list">
          {{$t('erp.lang_goodsIssues')}}
        </v-tab>
        <v-tab key="add">{{$t('erp.lang_recordGoodsIssue')}}</v-tab>
        <v-tab key="simple">{{ $t('erp.lang_simpleGoodsIssue') }}</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <Datatable :api-endpoint="ENDPOINTS.DATATABLES.ERP.GOODSISSUE"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       @deleteEntries="deleteEntries"
                       @deleteEntry="deleteEntry"
                       @displayEntry="displayEntry"
                       @editEntry="editEntry"
                       @downloadExcel="downloadExcel"
                       excel-file-name="Goods Issue"
                       ref="GoodsIssueDatatable"
                       show-delete-buttons
                       show-display-buttons
                       show-edit-buttons
                       show-excel-buttons
                       v-show="showTable"
            >
              <template v-slot:item.value="{item}">
                {{ item.value | currency }}
              </template>
            </Datatable>


          </v-tab-item>
          <v-tab-item key="add">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                :label="$t('erp.lang_goodsIssueName')"
                                outlined
                                required
                                v-model="GoodsIssueName"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select :items="storageSelect"
                            item-text="name"
                            item-value="id"
                            :label="$t('erp.lang_outBoundWarehouse')"
                            outlined
                            required
                            v-model="sourceStorage"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="pt-0 mt-0">
                  <v-radio-group class="pt-0 d-inline-flex" v-model="with_cost_of_goods">
                    <v-radio :label="$t('erp.lang_costsOfGoodsBook')" :value="1"></v-radio>
                    <v-radio :label="$t('erp.lang_costsOfGoodsWoBook')" :value="0"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn @click="showList" color="error" text>{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn :disabled="(GoodsIssueName.length < 4 || sourceStorage < 1)" @click="addData"
                         color="success">{{
                      $t('generic.lang_next')
                    }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item class="pa-0" key="simple">
            <SimpleGoodsIssueComponent @changeTab="tab = 2" />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<style>
.dataTables_ {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '../../../../config'
import {Events} from "../../../../plugins/events";
import Datatable from "../../../datatable/Datatable";
import mixin from "../../../../mixins/KeyboardMixIns";
import FileSaver from 'file-saver';
import SimpleGoodsIssueComponent from "@/components/erp/storage/goodissue/simpleGoods/SimpleGoodsIssueComponent";

export default {
  components: {
    Datatable,
    SimpleGoodsIssueComponent
  },
  beforeDestroy() {
    localStorage.setItem("tab", this.tab + '')
  },
  mixins: [mixin],
  data() {
    return {
      with_cost_of_goods: 1,
      ENDPOINTS,
      id: null,
      GoodsIssueName: "",
      sourceStorage: 0,
      storageSelect: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      tab: parseInt(localStorage.getItem("tab")) || 0,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('generic.lang_name'), value: "name"},
        {text: this.$t('generic.lang_constant_timing'), value: "time"},
        {text: this.$t('erp.lang_sourceStore'), value: "targetStock"},
        {text: this.$t('erp.lang_numberOfPositions'), value: "stacks"},
        {text: this.$t('erp.lang_totalQTY'), value: "qty"},
        {text: this.$t('erp.lang_PurchaseValue'), value: "value"},
        {text: this.$t('generic.lang_committed'), value: "transferCompleted"},
        {text: this.$t('generic.lang_committedAt'), value: "completed_At"},
      ],
      excelColumns: [
        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('generic.lang_constant_timing'),
          field: 'time',
        },
        {
          label: this.$t('erp.lang_sourceStore'),
          field: 'targetStock',
        },
        {
          label: this.$t('erp.lang_numberOfPositions'),
          field: 'stacks',
        },
        {
          label: this.$t('erp.lang_totalQTY'),
          field: 'qty',
        },
        {
          label: this.$t('erp.lang_PurchaseValue'),
          field: 'value',
        },
        {label: this.$t('generic.lang_committed'), value: "transferCompleted"},
        {label: this.$t('generic.lang_committedAt'), value: "completed_At"},
      ],
    }
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
      }
    }
  },

  methods: {
    deleteData(ids) {
      this.$swal({
        title: this.$t('erp.lang_doYouReallyWantToClearItems'),
        text: this.$t('erp.lang_doYouReallyWantToClearItems'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.CLEAR, {
            storageTransferIDs: ids
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_itemSuccessfulDeleted'),
                color: "success"
              });

              this.$refs.GoodsIssueDatatable.getDataFromApi();
              this.$refs.GoodsIssueDatatable.resetSelectedRows();
            } else if (res.data.status === 'ERROR') {
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error"
              });

              this.$refs.GoodsIssueDatatable.getDataFromApi();
              this.$refs.GoodsIssueDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error"
            });
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    deleteEntries(ids) {
      this.deleteData(ids)
    },
    deleteEntry(entry) {
      if (parseInt(entry.protected) == 1) {
        this.$swal({
          title: this.$t('erp.lang_youCannotDeleteThisItem'),
          text: this.$t('erp.lang_youCannotDeleteThisItem'),
          icon: "warning",
        })
      } else
        this.deleteData([entry.id])
    },
    loadStorages: function () {
      let self = this;
      self.axios.get(ENDPOINTS.ERP.STORAGES.GET)
          .then((res) => {
            if (res.status === 200) {
              res.data.storageInformation.forEach((row) => {
                this.storageSelect.push({name: row[1], id: parseInt(row[0])});
              });
            } else {
              Events.$emit("showSnackbar", {
                message: self.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(() => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    editEntry(entry) {
      this.$router.push('/erp/storage/GoodsIssue/edit/' + entry.id);
    },
    displayEntry(entry) {
      this.$router.push('/erp/storage/GoodsIssue/details/' + entry.id);
    },
    downloadExcel(entry) {
      this.axios.post(ENDPOINTS.ERP.GOODSISSUE.DOWNLOAD_EXCEL, { uuid: entry.id },
        {
          responseType: 'arraybuffer',
        }).then((res) => {
          //DOWNLOAD excel
          FileSaver.saveAs(new Blob([res.data], {
            type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }), "Good issue.xlsx");
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
            color: "error"
          });
        }).finally(() => {
        })
    },
    addData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.GOODSISSUE.CREATE, {
        GoodsIssueName: self.GoodsIssueName,
        sourceStorage: self.sourceStorage,
        with_cost_of_goods: self.with_cost_of_goods
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_goodsIssueEntryStarted'),
            color: "success"
          });

          //NAVIGATE TO GOODSISSUE /DISPLAY PAGE
          this.$router.push({name: 'erp.storage.GoodsIssueDetails', params: {id: res.data.UUID}})

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },


    resetData: function () {
      this.showCreate = false;
      this.showTable = true;
      this.id = null;
      this.GoodsIssueName = "";
      this.sourceStorage = null;
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },

  },
  mounted() {

    this.loadStorages();
  }
}
</script>
