export let erpRoutes = {
  meta: {
    menuTitle: "erp.lang_wawi",
    icon: "pe-7s-graph2",
  },
  groups: [
    {
      meta: {
        menuTitle: "erp.lang_baseData",
        icon: "pe-7s-plugin",
      },
      childs: [
        {
          path: "/erp/baseData/inventoryItems/add",
          name: "erp.baseData.inventoryItems.add",
          childs: [],
          meta: {
            siteTitle: "erp.lang_inventoryItem",
            menuTitle: "erp.lang_inventoryItem",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "ware_create",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/baseData/AddUpdateInventoryItem.vue"),
        },
        {
          path: "/erp/baseData/inventoryItems/edit/:id",
          name: "erp.baseData.inventoryItems.edit",
          props: true,
          childs: [],
          meta: {
            siteTitle: "erp.lang_inventoryItem",
            menuTitle: "erp.lang_inventoryItem",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "ware_edit",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/AddUpdateInventoryItem.vue"),
        },
        {
          path: "/erp/baseData/garnish/add",
          name: "erp.baseData.garnish.add",
          childs: [],
          meta: {
            siteTitle: "erp.lang_garnish",
            menuTitle: "erp.lang_garnish",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: "ware_create",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/baseData/AddUpdateGarnish.vue"),
        },
        {
          path: "/erp/baseData/garnish/edit/:id",
          name: "erp.baseData.garnish.edit",
          props: true,
          childs: [],
          meta: {
            siteTitle: "erp.lang_garnish",
            menuTitle: "erp.lang_garnish",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: "ware_edit",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/AddUpdateGarnish.vue"),
        },
        {
          path: "/erp/baseData/items/edit/:id",
          name: "erp.baseData.items.edit",
          props: true,
          meta: {
            siteTitle: "erp.lang_items",
            menuTitle: "erp.lang_items",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/ItemEdit.vue"),
        },
        {
          path: "/erp/baseData/items",
          name: "erp.baseData.items",
          meta: {
            siteTitle: "erp.lang_items",
            menuTitle: "erp.lang_items",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/Items.vue"),
        },
        {
          path: "/erp/baseData/item/create",
          name: "erp.baseData.item.create",
          meta: {
            siteTitle: "erp.lang_items",
            menuTitle: "erp.lang_items",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/baseData/ItemCreate.vue"),
        },
        {
          path: "/erp/baseData/garnish",
          name: "erp.baseData.garnish",
          meta: {
            siteTitle: "erp.lang_extra",
            menuTitle: "erp.lang_extra",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 19,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/Garnish.vue"),
        },
        {
          path: "/erp/baseData/garnish-of-the-day",
          name: "erp.baseData.garnish.of.the.day",
          meta: {
            siteTitle: "erp.lang_garnishOfTheDay",
            menuTitle: "erp.lang_garnishOfTheDay",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 19,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/garnish/GarnishOftheDay"),
        },
        {
          path: "/erp/baseData/garnishgroup",
          name: "erp.baseData.garnishgroup",
          meta: {
            siteTitle: "erp.lang_garnishGroups",
            menuTitle: "erp.lang_garnishGroups",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 19,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/garnishgroup/GarnishGroups"),
        },
        {
          path: "/erp/baseData/garnishgroup/create",
          name: "erp.baseData.garnishgroup.create",
          meta: {
            siteTitle: "erp.lang_garnishGroups",
            menuTitle: "erp.lang_garnishGroups",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/garnishgroup/CreateGarnishGroup"),
        },
        {
          path: "/erp/baseData/garnishgroup/edit/:uuid",
          name: "erp.baseData.garnishgroup.edit",
          meta: {
            siteTitle: "erp.lang_garnishGroups",
            menuTitle: "erp.lang_garnishGroups",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/garnishgroup/EditGarnishGroup"),
        },
        {
          path: "/erp/baseData/IngredientGroups",
          name: "erp.baseData.IngredientGroups",
          meta: {
            siteTitle: "erp.lang_ingredientGroups",
            menuTitle: "erp.lang_ingredientGroups",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 20,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/ingredientGroups/IngredientGroups"),
        },
        {
          path: "/erp/baseData/IngredientGroups/create",
          name: "erp.baseData.IngredientGroups.create",
          meta: {
            siteTitle: "IngredientGroups Create",
            menuTitle: "IngredientGroups Create",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 20,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import(
              "../views/erp/baseData/ingredientGroups/IngerdientGroupsCreate"
            ),
        },
        {
          path: "/erp/baseData/IngredientGroupsCreate/edit/:uuid",
          name: "erp.baseData.IngredientGroupsCreate.edit",
          meta: {
            siteTitle: "IngredientGroups Edit",
            menuTitle: "IngredientGroups Edit",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 20,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import(
              "../views/erp/baseData/ingredientGroups/IngerdientGroupsEdit"
            ),
        },
        {
          path: "/erp/baseData/itemgroups",
          name: "erp.baseData.itemgroups",
          meta: {
            siteTitle: "erp.lang_nav_waregroups",
            menuTitle: "erp.lang_nav_waregroups",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/Itemgroups.vue"),
        },
        {
          path: "/erp/baseData/itemgroup/edit/:id",
          props: true,
          name: "erp.baseData.itemgroup.edit",
          meta: {
            siteTitle: "erp.lang_nav_waregroups",
            menuTitle: "erp.lang_nav_waregroups",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/ItemgroupsEdit.vue"),
        },
        {
          path: "/erp/baseData/itemgroups/create",
          name: "erp.baseData.itemgroups.create",
          meta: {
            siteTitle: "erp.lang_nav_waregroups",
            menuTitle: "erp.lang_nav_waregroups",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/baseData/ItemgroupsCreate.vue"),
        }, //start of ingredients
        {
          path: "/erp/baseData/ingredients",
          name: "erp.baseData.ingredients",
          meta: {
            siteTitle: "erp.lang_ingredients",
            menuTitle: "erp.lang_ingredients",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 20,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/ingredients/Ingredient"),
        },
        {
          path: "/erp/baseData/ingredients/create",
          name: "erp.baseData.ingredients.create",
          meta: {
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/ingredients/IngredientCreate"),
        },
        {
          path: "/erp/baseData/ingredients/edit/:id",
          name: "erp.baseData.ingredients.edit",
          meta: {
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/ingredients/IngredientEdit"),
        }, //end of ingredients

        {
          path: "/erp/baseData/itemPackages",
          name: "erp.baseData.itemPackages",
          meta: {
            siteTitle: "erp.lang_packagedItems",
            menuTitle: "erp.lang_packagedItems",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 28,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/common/404.vue"),
        },
        {
          path: "/erp/baseData/itemRecipes",
          name: "erp.baseData.itemRecipes",
          meta: {
            siteTitle: "erp.lang_instructions",
            menuTitle: "erp.lang_instructions",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 20,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/ItemRecipes"),
        },
        {
          path: "/erp/baseData/priceTMPL",
          name: "erp.baseData.priceTMPL",
          meta: {
            siteTitle: "erp.lang_priceTemplates",
            menuTitle: "erp.lang_priceTemplates",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 17,
              requiredPermission: "allowCreateEditPriceTMPL",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/baseData/pricetemplate/PriceTemplate"),
        },
        {
          path: "/erp/baseData/priceTMPL/create",
          name: "erp.baseData.priceTMPL.create",
          meta: {
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 17,
              requiredPermission: "allowCreateEditPriceTMPL",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/baseData/pricetemplate/PriceTemplateCreate"),
        },
        {
          path: "/erp/baseData/priceTMPL/edit/:id",
          name: "erp.baseData.priceTMPL.edit",
          meta: {
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 17,
              requiredPermission: "allowCreateEditPriceTMPL",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/baseData/pricetemplate/PriceTemplateEdit"),
        },
        //end of priceTMPL
        {
          path: "/erp/baseData/voucher",
          name: "erp.baseData.voucher",
          meta: {
            siteTitle: "erp.lang_nav_vouchers",
            menuTitle: "erp.lang_nav_vouchers",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 31,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/Vouchers.vue"),
        },
        {
          path: "/erp/baseData/voucher/details/:id",
          name: "erp.baseData.voucherDetails",
          props: true,
          meta: {
            siteTitle: "erp.lang_voucher",
            menuTitle: "erp.lang_voucher",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 31,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/voucher/VoucherDetails"),
        },
        {
          path: "/erp/baseData/giftCard",
          name: "erp.baseData.giftCard",
          meta: {
            siteTitle: "erp.lang_nav_voucherCards",
            menuTitle: "erp.lang_nav_voucherCards",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 31,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/GiftCards.vue"),
        },
        {
          path: "/erp/baseData/giftCard/details/:id",
          name: "erp.baseData.giftCardDetails",
          props: true,
          meta: {
            siteTitle: "analytics.lang_giftCard",
            menuTitle: "analytics.lang_giftCard",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 31,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/baseData/giftCard/GiftCardDetails"),
        },
        {
          path: "/erp/baseData/inventoryItems",
          name: "erp.baseData.inventoryItems",
          meta: {
            siteTitle: "erp.lang_inventoryItem",
            menuTitle: "erp.lang_inventoryItem",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/InventoryItems.vue"),
        },
        //Start of Happy Hour
        {
          path: "/erp/baseData/happyHour",
          name: "erp.baseData.happyHour",
          meta: {
            siteTitle: "erp.lang_happyHour_sets",
            menuTitle: "erp.lang_happyHour_sets",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 22,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/HappyHour.vue"),
        },
        {
          path: "/erp/baseData/happyHour/add",
          name: "erp.baseData.happyHour.add",
          childs: [],
          meta: {
            siteTitle: "setting.lang_happyHour_sets",
            menuTitle: "setting.lang_happyHour_sets",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 22,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/HappyHourAdd"),
        },
        {
          path: "/erp/baseData/happyHour/edit/:id",
          name: "erp.baseData.happyHour.edit",
          childs: [],
          meta: {
            siteTitle: "setting.lang_happyHour_sets",
            menuTitle: "setting.lang_happyHour_sets",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 22,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/editHappyHour"),
        },
        {
          path: "/erp/baseData/specialOffer",
          name: "erp.baseData.specialOffer",
          meta: {
            siteTitle: "erp.lang_specialOffers",
            menuTitle: "erp.lang_specialOffers",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 84,
              requiredPermission: "manage_specialOffers",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/specialoffers/SpecialOffers.vue"),
        },
        {
          path: "/erp/baseData/specialOffer/add",
          name: "erp.baseData.specialOffer.add",
          childs: [],
          meta: {
            siteTitle: "erp.lang_addSpecialOffer",
            menuTitle: "erp.lang_specialOffers",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 84,
              requiredPermission: "manage_specialOffers",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/specialoffers/CreateSpecialOffer.vue"),
        },
        {
          path: "/erp/baseData/specialOffer/edit/:id",
          name: "erp.baseData.specialOffer.edit",
          childs: [],
          meta: {
            siteTitle: "erp.lang_editSpecialOffer",
            menuTitle: "erp.lang_specialOffers",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 84,
              requiredPermission: "manage_specialOffers",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/specialoffers/EditSpecialOffer"),
        },
        {
          path: "/erp/baseData/dailygoods",
          name: "erp.baseData.dailygoods",
          childs: [],
          meta: {
            siteTitle: "erp.lang_dishOfTheDayControl",
            menuTitle: "erp.lang_dishOfTheDayControl",
            layout: "default",
            icon: "",
            hideInNavigation: false,
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/baseData/dailygoods/DailyGoods"),
        },
      ],
    },
    {
      meta: {
        menuTitle: "erp.lang_storageManagement",
        icon: "pe-7s-box1",
        permission: {},
      },
      childs: [
        {
          path: "/erp/storage/itemStocHistory",
          name: "erp.storage.itemStocHistory",
          meta: {
            siteTitle: "erp.lang_itemStockHistory",
            menuTitle: "erp.lang_itemStockHistory",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/storage/ItemStockHistory.vue"),
        },
        {
          path: "/erp/storage/supplier",
          name: "erp.storage.supplier",
          meta: {
            siteTitle: "erp.lang_nav_supplier",
            menuTitle: "erp.lang_nav_supplier",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/storage/Suppliers.vue"),
        },
        {
          path: "/erp/storage/supplier/create",
          name: "erp.storage.supplier.create",
          meta: {
            siteTitle: "erp.lang_nav_supplier",
            menuTitle: "erp.lang_nav_supplier",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/storage/SupplierCreate.vue"),
        },
        {
          path: "/erp/storage/supplier/edit/:id",
          name: "erp.storage.supplier.edit",
          props: true,
          meta: {
            siteTitle: "erp.lang_nav_supplier",
            menuTitle: "erp.lang_nav_supplier",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/storage/SupplierEdit.vue"),
        },
        {
          path: "/erp/storage/producer",
          name: "erp.storage.producer",
          meta: {
            siteTitle: "erp.lang_nav_manufacturer",
            menuTitle: "erp.lang_nav_manufacturer",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/storage/Producers.vue"),
        },
        {
          path: "/erp/storage/producer/create",
          name: "erp.storage.producer.create",
          meta: {
            siteTitle: "erp.lang_nav_manufacturer",
            menuTitle: "erp.lang_nav_manufacturer",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/storage/ProducerCreate.vue"),
        },
        {
          path: "/erp/storage/producer/edit/:id",
          name: "erp.storage.producer.edit",
          props: true,
          meta: {
            siteTitle: "erp.lang_nav_manufacturer",
            menuTitle: "erp.lang_nav_manufacturer",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/storage/ProducerEdit.vue"),
        },
        /* {
                     path: '/erp/storage/realtimeNotifications',
                     name: 'erp.storage.realtimeNotifications',
                     meta: {
                         siteTitle: ('erp.lang_nav_realtimeNotification'),
                         menuTitle: ('erp.lang_nav_realtimeNotification'),
                         layout: 'default',
                         icon: '',
                         permission: {
                             requiredModule: 0,
                             requiredPermission: "",
                             hybridDisplay: "Server"
                         }
                     },
                     component: () => import('../views/common/404.vue')
                 },*/ //start of ware order
        {
          path: "/erp/storage/orderItems",
          name: "erp.storage.orderItems",
          meta: {
            siteTitle: "erp.lang_nav_wareorder",
            menuTitle: "erp.lang_nav_wareorder",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/storage/orderware/OrderWare"),
        },
        {
          path: "/erp/storage/orderdetails/:id",
          name: "erp.storage.orderdetails",
          meta: {
            siteTitle: "erp.lang_nav_wareorder",
            menuTitle: "erp.lang_nav_wareorder",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/storage/orderware/OrderDetails"),
        }, //end of ware order
        {
          path: "/erp/storage/GoodsReceipt",
          name: "erp.storage.GoodsReceipt",
          meta: {
            siteTitle: "erp.lang_nav_warein",
            menuTitle: "erp.lang_nav_warein",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodreceipt/GoodsReceipt.vue"),
        },
        {
          path: "/erp/storage/GoodsReceipt/details/:id",
          name: "erp.storage.GoodsReceiptDetails",
          props: true,
          meta: {
            siteTitle: "erp.lang_nav_warein",
            menuTitle: "erp.lang_nav_warein",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodreceipt/GoodsReceiptDetails.vue"),
        },
        {
          path: "/erp/storage/GoodsReceipt/edit/:id",
          name: "erp.storage.GoodsReceiptEdit",
          props: true,
          meta: {
            siteTitle: "erp.lang_nav_warein",
            menuTitle: "erp.lang_nav_warein",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodreceipt/GoodsReceiptEdit"),
        },
        {
          path: "/erp/storage/SimpleGoodsReceipt/details/:id",
          name: "erp.storage.SimpleGoodsReceiptDetails",
          props: true,
          meta: {
            siteTitle: "erp.lang_nav_warein",
            menuTitle: "erp.lang_nav_warein",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodreceipt/SimpleGoodsReceiptDetails.vue"),
        },
        {
          path: "/erp/storage/GoodsIssue",
          name: "erp.storage.GoodsIssue",
          meta: {
            siteTitle: "erp.lang_wareOut",
            menuTitle: "erp.lang_wareOut",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodissue/GoodsIssue.vue"),
        },
        {
          path: "/erp/storage/GoodsIssue/details/:id",
          name: "erp.storage.GoodsIssueDetails",
          props: true,
          meta: {
            siteTitle: "erp.lang_wareOut",
            menuTitle: "erp.lang_wareOut",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodissue/GoodsIssueDetails"),
        },
        {
          path: "/erp/storage/GoodsIssue/edit/:id",
          name: "erp.storage.GoodsIssueEdit",
          props: true,
          meta: {
            siteTitle: "erp.lang_wareOut",
            menuTitle: "erp.lang_wareOut",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodissue/GoodsIssueEdit"),
        },
        {
          path: "/erp/storage/SimpleGoodsIssue/details/:id",
          name: "erp.storage.SimpleGoodsIssueDetails",
          props: true,
          meta: {
            siteTitle: "erp.lang_wareOut",
            menuTitle: "erp.lang_wareOut",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodissue/SimpleGoodsIssueDetails.vue"),
        },
        {
          path: "/erp/storage/orders/itemscontrol/:id",
          name: "erp.storage.orders.itemscontrol",
          props: true,
          meta: {
            siteTitle: "erp.lang_nav_warein",
            menuTitle: "erp.lang_nav_warein",
            layout: "default",
            hideInNavigation: true,
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/goodreceipt/preorder/ItemsControl"),
        },

        //start storage overview routes
        {
          path: "/erp/storage/storageOverview",
          name: "erp.storage.storageOverview",
          meta: {
            siteTitle: "erp.lang_storageOverview",
            menuTitle: "erp.lang_storageOverview",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () =>
            import("../views/erp/storage/storageoverview/StorageOverview"),
        },
        {
          path: "/erp/storage/storageOverview/details/:id",
          name: "erp.storage.storageOverview.details",
          props: true,
          meta: {
            siteTitle: "erp.lang_storageOverview",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/storage/storageoverview/StorageDetails.vue"),
        },
        {
          path: "/erp/storage/storageOverview/edit/:id",
          name: "erp.storage.storageOverview.edit",
          props: true,
          meta: {
            siteTitle: "erp.lang_storageOverview",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/storage/storageoverview/StorageEdit.vue"),
        },
        {
          path: "/erp/storage/storageOverview/create",
          name: "erp.storage.storageOverview.create",
          props: true,
          meta: {
            siteTitle: "erp.lang_storageOverview",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/storage/storageoverview/StorageCreate"),
        },
        //end storage overview routes
        //start mhd items
        {
          path: "/erp/baseData/mhditems",
          name: "erp.baseData.mhditems",
          meta: {
            siteTitle: "erp.lang_mhdList",
            menuTitle: "erp.lang_mhdList",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/baseData/mhditems/index"),
        },
        //end mhd items
        {
          path: "/erp/storage/storageTransfer",
          name: "erp.storage.storageTransfer",
          meta: {
            siteTitle: "erp.lang_storageTransfer",
            menuTitle: "erp.lang_storageTransfer",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () => import("../views/erp/storage/stocktransfer/"),
        },
        {
          path: "/erp/storage/storageMovement",
          name: "erp.storage.storageMovement",
          meta: {
            siteTitle: "erp.lang_storageMovement",
            menuTitle: "erp.lang_storageMovement",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
            },
          },
          component: () => import("../views/erp/storage/stockmovement/"),
        },

        //start StockTaking routes
        {
          path: "/erp/storage/stockTaking",
          name: "erp.storage.stockTaking",
          meta: {
            siteTitle: "erp.lang_ware_inventur",
            menuTitle: "erp.lang_ware_inventur",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "displayStockTakings",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/storage/stocktaking/StockTaking"),
        },
        {
          path: "/erp/storage/stockTaking/create",
          name: "erp.storage.stockTaking.create",
          props: true,
          meta: {
            siteTitle: "erp.lang_ware_inventur",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "stockTakingCreate",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/storage/stocktaking/StockTakingCreate"),
        },
        {
          path: "/erp/storage/stockTaking/details/:id",
          name: "erp.storage.stockTaking.details",
          props: true,
          meta: {
            siteTitle: "erp.lang_ware_inventur",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "stockTakingEdit",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/storage/stocktaking/StockTakingDetails"),
        },
        {
          path: "/erp/storage/stockTaking/:id/create",
          name: "erp.storage.stockTaking.id.create",
          props: true,
          meta: {
            siteTitle: "erp.lang_ware_inventur",
            hideInNavigation: true,
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "stockTakingCreate",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/storage/stocktaking/StockTakingScanner"),
        },
        //end storage StockTaking routes
        {
          path: "/erp/storage/printLabels",
          name: "erp.storage.printLabels",
          meta: {
            siteTitle: "erp.lang_nav_warelabels",
            menuTitle: "erp.lang_nav_warelabels",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 14,
              requiredPermission: "print_labels",
            },
          },
          component: () => import("../views/erp/storage/PrintWareLabel.vue"),
        },
        {
          path: "/erp/storage/printShelfLabels",
          name: "erp.storage.printShelfLabels",
          meta: {
            siteTitle: "erp.lang_nav_shelflabels",
            menuTitle: "erp.lang_nav_shelflabels",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 14,
              requiredPermission: "print_shelflabels",
            },
          },
          component: () => import("../views/erp/storage/PrintShelfLabel.vue"),
        },
      ],
    },
    {
      meta: {
        menuTitle: "settings.lang_settings",
        icon: "pe-7s-tools",
        permission: {},
      },
      childs: [
        {
          path: "/erp/settings/tax",
          name: "erp.settings.tax",
          meta: {
            siteTitle: "erp.lang_nav_wawi_taxset",
            menuTitle: "erp.lang_nav_wawi_taxset",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "accounting_displayTaxRates",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/settings/TaxSetup.vue"),
        },
        {
          path: "/erp/settings/garnishTabs",
          name: "erp.settings.garnishTabs",
          meta: {
            siteTitle: "erp.lang_extraTabsZwaenge",
            menuTitle: "erp.lang_extraTabsZwaenge",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 19,
              requiredPermission: "garnishTabs",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/settings/ExtraTab.vue"),
        },
        {
          path: "/erp/settings/freeTextTags",
          name: "erp.settings.freeTextTags",
          meta: {
            siteTitle: "erp.lang_freeTextTabs",
            menuTitle: "erp.lang_freeTextTabs",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 2,
              requiredPermission: "freeTextTags",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/settings/FreeTextTags.vue"),
        },
        {
          path: "/erp/settings/mealSizes",
          name: "erp.settings.mealSizes",
          meta: {
            siteTitle: "erp.lang_SizeVariation",
            menuTitle: "erp.lang_SizeVariation",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 24,
              requiredPermission: "mealsizes",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/settings/SizeVariations.vue"),
        },
        {
          path: "/erp/settings/deposit",
          name: "erp.settings.deposit",
          meta: {
            siteTitle: "erp.lang_deposit",
            menuTitle: "erp.lang_deposit",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 7,
              requiredPermission: "deposit",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/settings/Deposit.vue"),
        },
        {
          path: "/erp/settings/wareImport",
          name: "erp.settings.wareImport",
          meta: {
            siteTitle: "erp.lang_wareImport",
            menuTitle: "erp.lang_wareImport",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 32,
              requiredPermission: "itemImport",
              hybridDisplay: "Server",
            },
          },
          component: () => import("../views/erp/settings/ItemImportView.vue"),
        },
        {
          path: "/erp/settings/wareExport",
          name: "erp.settings.wareExport",
          meta: {
            siteTitle: "generic.lang_export",
            menuTitle: "generic.lang_export",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 32,
              requiredPermission: "itemExport",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/settings/ItemExportView.vue"),
        },
        {
          path: "/erp/settings/importItemControllSystemData",
          name: "erp.settings.importItemControllSystemData",
          meta: {
            siteTitle: "erp.lang_importItemControllSystemData",
            menuTitle: "erp.lang_importItemControllSystemData",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 32,
              requiredPermission: "itemImport",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/ImportItemControllSystemData.vue"),
        },
        //start of mealType
        {
          path: "/erp/settings/mealType",
          name: "erp.settings.mealType",
          meta: {
            siteTitle: "erp.lang_mealType",
            menuTitle: "erp.lang_mealType",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/settings/mealType/MealType"),
        },
        {
          path: "/erp/settings/mealType/create",
          name: "erp.settings.mealType.create",
          meta: {
            siteTitle: "mealType",
            menuTitle: "mealType",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/mealType/MealTypeCreate"),
        },
        {
          path: "/erp/settings/mealType/edit/:id",
          name: "erp.settings.mealType.edit",
          meta: {
            siteTitle: "MealType",
            menuTitle: "MealType",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/mealType/MealTypeEdit"),
        },
        //End of mealType

        //start of allergens
        {
          path: "/erp/settings/allergens",
          name: "erp.settings.allergens",
          meta: {
            siteTitle: "erp.lang_Allergene",
            menuTitle: "erp.lang_Allergene",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "allergene",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/settings/allergen/Allergens.vue"),
        },
        {
          path: "/erp/settings/allergens/create",
          name: "erp.settings.allergens.create",
          meta: {
            siteTitle: "erp.lang_Allergene",
            menuTitle: "erp.lang_Allergene",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "allergene",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/allergen/AllergensCreate.vue"),
        },
        {
          path: "/erp/settings/allergens/edit/:id",
          name: "erp.settings.allergens.edit",
          meta: {
            siteTitle: "erp.lang_Allergene",
            menuTitle: "erp.lang_Allergene",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "allergene",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/allergen/AllergensEdit.vue"),
        },
        //End of allergens

        //start of additives
        {
          path: "/erp/settings/additive",
          name: "erp.settings.additive",
          meta: {
            siteTitle: "erp.lang_Zusatzstoffe",
            menuTitle: "erp.lang_Zusatzstoffe",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "additives",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/settings/additive/Additives.vue"),
        },
        {
          path: "/erp/settings/additive/create",
          name: "erp.settings.additive.create",
          meta: {
            siteTitle: "erp.lang_Zusatzstoffe",
            menuTitle: "erp.lang_Zusatzstoffe",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "additives",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/additive/AdditivesCreate.vue"),
        },
        {
          path: "/erp/settings/additive/edit/:id",
          name: "erp.settings.additive.edit",
          meta: {
            siteTitle: "erp.lang_Zusatzstoffe",
            menuTitle: "erp.lang_Zusatzstoffe",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "additives",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/additive/AdditivesEdit.vue"),
        },
        //end of additives
        //begin of packages
        {
          path: "/erp/settings/packages",
          name: "erp.settings.packages",
          meta: {
            siteTitle: "erp.lang_warecreate_order_abpackeinheit",
            menuTitle: "erp.lang_warecreate_order_abpackeinheit",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/settings/packages/Packages"),
        }, // end of packages
        //begin of unit management
        {
          path: "/erp/settings/unitManagement",
          name: "erp.settings.unitManagement",
          meta: {
            siteTitle: "erp.lang_unitManagement",
            menuTitle: "erp.lang_unitManagement",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "",
              hybridDisplay: "",
            },
          },
          component: () =>
            import("../views/erp/settings/unitManagement/UnitManagement"),
        },
        {
          path: "/erp/settings/unitManagement/create",
          name: "erp.settings.unitManagement.create",
          meta: {
            siteTitle: "erp.lang_createUnit",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "additives",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/unitManagement/UnitManagementCreate"),
        },
        {
          path: "/erp/settings/unitManagement/edit/:id",
          name: "erp.settings.unitManagement.edit",
          meta: {
            siteTitle: "erp.lang_editUnit",
            layout: "default",
            icon: "",
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: "additives",
              hybridDisplay: "Server",
            },
          },
          component: () =>
            import("../views/erp/settings/unitManagement/UnitManagementEdit"),
        },
        //end of unit management
        {
          path: "/erp/settings/voidReasons",
          name: "erp.settings.voidReasons",
          meta: {
            siteTitle: "accounting.lang_nav_retour_loss_reasons",
            menuTitle: "accounting.lang_nav_retour_loss_reasons",
            layout: "default",
            icon: "",
            permission: {
              requiredModule: 0,
              requiredPermission: "voidReasons",
              hybridDisplay: "",
            },
          },
          component: () => import("../views/erp/settings/VoidReasons.vue"),
        },
        /*{
                    path: '/erp/settings/arrange',
                    name: 'erp.settings.arrange',
                    meta: {
                        siteTitle: ('erp.lang_nav_accessories_rearrangegoods'),
                        menuTitle: ('erp.lang_nav_accessories_rearrangegoods'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/common/404.vue')
                },
                {
                    path: '/erp/settings/removeOldItems',
                    name: 'erp.settings.removeOldItems',
                    meta: {
                        siteTitle: ('erp.lang_nav_accessories_cleanUp'),
                        menuTitle: ('erp.lang_nav_accessories_cleanUp'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/common/404.vue')
                }*/
      ],
    },
  ],
  childs: [],
};
