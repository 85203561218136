<template>
  <v-card class="pa-0 rounded-lg overflow-hidden" elevation="1" :style="customStyle" height="250">
    <v-container fluid class="pa-1" style="height:100%">
      <v-row no-gutters style="height:100%">
        <v-col cols="6" align-self="center">
          <h2 v-text="groupName" class="pl-2 text-overflow-3"></h2>
        </v-col>
        <v-col cols="6" align="center" align-self="center">
          <v-img :src="groupImage" width="350" min-height="200" lazy-src="@/assets/images/defaultGroupImage.png"
            contain>
            <template v-slot:placeholder>
              <v-img contain height="100%" src="@/assets/images/defaultGroupImage.png" />
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "FeaturedGroup",
  props: {
    group: {
      type: Object,
      default: () => null,
      required: false
    },
  },
  data(){
    return {
      groupData: null
    }
  },
  watch:{
    "group.itemGroupId": async function(id){
      await this.getItemGroupData(id);
    }
  },
  computed: {
    ...mapGetters({
      imageUrl: "api/auth/imageUrl",
    }),
    groupImage() {
      if (this.groupData && this.groupData.tablebeeImage)
        return this.imageUrl(this.groupData.tablebeeImage);
      
      return null;
    },
    groupName() {
      if (!this.groupData)
        return "Group Name"+(Math.random()>0.5?"Very long one lets see how its being handled yeah you can't see this one LOL": "");

      return this.groupData.name.length > 50 ? this.groupData.name.substring(0, 50) + "..." : this.groupData.name;
    },
    customStyle() {
      return {
        backgroundColor: this.group.style.backgroundColor + ' !important',
        borderStyle: 'solid',
        borderColor: this.group.style.borderColor + ' !important',
        borderWidth: this.group.style.borderWidth + 'px !important',
        borderRadius: this.group.style.borderRadius + 'px !important',
      }
    }
  },
  methods: {
    async getItemGroupData(id){
      if (id) {
        const result = await this.$store.dispatch('itemgroups/getItemgroupByID', id)
        if (result) {
          this.groupData = result;
          return;
        }
      }
      this.groupData = null;
    }
  },
  mounted(){
    this.getItemGroupData(this.group?.itemGroupId);
  }
}
</script>

<style scoped>
/* Text overflow classes for 1 to 10 lines */

/* Single line overflow */
.text-overflow-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Multi-line overflow (2 to 10 lines) */
.text-overflow-2,
.text-overflow-3,
.text-overflow-4,
.text-overflow-5,
.text-overflow-6,
.text-overflow-7,
.text-overflow-8,
.text-overflow-9,
.text-overflow-10 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-overflow-2 { -webkit-line-clamp: 2; }
.text-overflow-3 { -webkit-line-clamp: 3; }
.text-overflow-4 { -webkit-line-clamp: 4; }
.text-overflow-5 { -webkit-line-clamp: 5; }
.text-overflow-6 { -webkit-line-clamp: 6; }
.text-overflow-7 { -webkit-line-clamp: 7; }
.text-overflow-8 { -webkit-line-clamp: 8; }
.text-overflow-9 { -webkit-line-clamp: 9; }
.text-overflow-10 { -webkit-line-clamp: 10; }
</style>