import Vue from 'vue';

// --- Vuex ---
import Vuex from 'vuex';
// --- Vuex Persist ---
import VuexPersistence from 'vuex-persist';

// --- LocalForage ---
import localforage from "localforage";
// --- Modules ---
import api from "./modules/api/index";
import app from "./modules/app";
import user from "./modules/user";
import cashierIDs from "./modules/cashierIDs";
import courses from "./modules/courses";
import deposit from "./modules/deposit";
import extras from "./modules/extras";
import extratabs from "./modules/extratabs";
import freetexttags from "./modules/freetexttags";
import itemgroups from "./modules/itemgroups";
import items from "./modules/items";
import mealsizes from "./modules/mealsizes";
import permissions from "./modules/permissions";
import printer from "./modules/printer";
import touchkeyboard from "./modules/touchkeyboard";
import tse from "./modules/tse";
import voidreasons from "./modules/voidreasons";
import pos from "./modules/pos/index";
import posLayoutTemplates from "./modules/posLayoutTemplates";
import posButtonsTemplates from "./modules/posButtonsTemplates";
import payments from "./modules/payments";
import settings from "./modules/settings";
import multiUser from "./modules/multiUser";
import datatable from "./modules/datatable";
import scale from "./modules/scale";
import hybrid from "./modules/hybrid";

import backgroundSync from "./modules/backgroundSync";

Vue.use(Vuex);

localforage.config({
    name: '3pos',
    version: 260.0,
    storeName: 'keyvaluepairs',
    description: 'Storage of 3POS'
});

const vuexLocal = new VuexPersistence({
    storage: localforage,
    asyncStorage: true
});

const store = new Vuex.Store({
    strict: false,
    plugins: [vuexLocal.plugin],
    modules: {
        api,
        app,
        multiUser,
        user,
        cashierIDs,
        courses,
        deposit,
        extras,
        extratabs,
        freetexttags,
        itemgroups,
        items,
        mealsizes,
        permissions,
        pos,
        printer,
        touchkeyboard,
        tse,
        voidreasons,
        posLayoutTemplates,
        posButtonsTemplates,
        settings,
        payments,
        datatable,
        backgroundSync,
        hybrid,
        scale,
    }
});

window.store = store;
export default store;
