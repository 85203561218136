<template>
  <div>
    <page-title
      :heading="$t('erp.lang_nav_warein')"
      :icon="icon"
      :subheading="$t('erp.lang_nav_warein')"
      show-previous-button
      url-previous="/erp/storage/GoodsReceipt"
    ></page-title>

    <div class="app-main__inner">
      <SimpleGoodsReceiptDetailsComponent></SimpleGoodsReceiptDetailsComponent>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import SimpleGoodsReceiptDetailsComponent from "../../../../components/erp/storage/goodreceipt/simpleGoods/SimpleGoodsReceiptDetailsComponent";

export default {
  components: {
    PageTitle,
    SimpleGoodsReceiptDetailsComponent,
  },
  props: ["id"],
  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
