<template>
  <v-container fluid>
    <v-row class="pa-0">
      <v-col class="pa-0" cols="12">
        <v-form ref="form" lazy-validation>
          <v-row>

            <v-col cols="12" sm="6">
              <BaseDateInput v-model="startDateTime" :disabled="this.dates_loading"
                            :label="$t('accounting.lang_dailyReportStart')"
                            :loading="this.dates_loading"
                            :rules="[v => !!v]" dense outlined
                            type="date"/>
            </v-col>
            <v-col cols="12" sm="6">
              <BaseDateInput v-model="endDateTime" :disabled="this.dates_loading"
                            :label="$t('accounting.lang_dailyReportEnd')"
                            :loading="this.dates_loading"
                            :rules="[v => !!v]" dense outlined
                            type="date"/>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
              <v-select :loading="this.departments_loader" outlined dense clearable
                        :label="$t('customers.lang_customerDepartments')" :items="this.departments"
                        item-value="id" item-text="name" v-model="customer_department"/>
            </v-col>
            <v-col cols="12" md="6" class="pt-0 pb-0">
              <v-select :label="$t('generic.lang_cashierID')" clearable
                        :items="filteredCashierIDs" item-value="value" item-text="name"
                        dense outlined v-model="cashierId"
              />
            </v-col>

            <v-col class="pt-0" cols="12" sm="12" md="5">
              <v-select
                v-model="paymentTypeID"
                :items="paymentTypes"
                item-value="paymentID"
                item-text="paymentName"
                :label="$t('accounting.lang_PaymentMethod')"
                clearable
                dense
                outlined
                :loading="this.payments_loading"
                :disabled="this.payments_loading"
              />
            </v-col>

            <v-col class="pt-0" cols="12" sm="8" md="5">
              <v-select v-model="dailyReportID" :disabled="this.reports_loading"
                        :items="dailyReportOptions"
                        :label="$t('accounting.lang_dailyReportID')" :loading="this.reports_loading"
                        :rules="[v => !!v]"
                        dense
                        item-text="text"
                        item-value="value" outlined></v-select>
            </v-col>

            <v-col class="pt-0 pb-2" cols="12" sm="4" md="2">
              <v-btn block class="mx-auto elevation-0" color="primary" @click="filter()">
                <v-icon>filter_list</v-icon>
                {{ $t('generic.lang_filter') }}
              </v-btn>
            </v-col>

          </v-row>
        </v-form>
      </v-col>
      <v-col class="pa-0" cols="12">
        <v-card outlined>
          <Datatable v-if="showTable" ref="salesTable" :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.SEARCHINVOICES"
                     :data="this.params"
                     :datatable-headers="datatableHeaders"
                     :excel-columns="excelColumns"
                     :excel-file-name="$t('accounting.lang_invoices')"
                     show-display-buttons
                     @displayEntry="displayEntry"
                     pdf-f-ile-name="invoices"
                     show-pdf
                     show-selected-pdf
                     @downloadSelectedPdf="downloadSelected" :pdf-selected-loading="loadingSelectedPDF"
          >
            <template v-slot:item.total="{ item }" >
              {{ item.total | currency }}
            </template>
          </Datatable>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidth"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import Datatable from "../datatable/Datatable";
import {ENDPOINTS} from '@/config'
import mixin from "../../mixins/KeyboardMixIns";
import DateTimePicker from "@/components/common/datetimepicker";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';
import {mapGetters} from "vuex";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  components: {
    Datatable,
    DateTimePicker,
    BaseDateInput
  },

  mixins: [mixin],

  data() {
    return {
      cashierId: null,
      dates_loading: false,
      departments_loader: false,
      departments: [],
      startDateTime: "",
      endDateTime: "",
      dailyReportOptions: [],
      dailyReportID: "",
      paymentTypes: [],
      paymentTypeID: "",
      payments_loading: false,
      ENDPOINTS,
      reports_loading: false,
      customer_department: null,
      id: null,
      uuid: null,
      customerIdAdd: null,
      addSearch: null,
      items: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      dataTable: null,
      loadingSelectedPDF:false,
      pdfSelectLimit:15, // the max number of selected invoices to export
    }
  },
  computed: {
    ...mapGetters({
      cashierIDs:"cashierIDs/cashiers",
    }),
    filteredCashierIDs(){
      return this.cashierIDs.map(item => ({
        name:this.$t('generic.lang_kasse') +' '+ item.id + (item.aliasName ? ` (${item.aliasName}) ` : ''),
        value:item.id
      }))
    },
    params: function () {
      return {
        start_date: this.startDateTime === "" ? "" : new Date(this.startDateTime).getTime() / 1000,
        end_date: this.endDateTime === "" ? "" : new Date(`${this.endDateTime}T23:59:59.000+00:00`).getTime() / 1000,
        daily_report_id: this.dailyReportID,
        department: this.customer_department,
        cashierId: this.cashierId,
        paymentTypeID: this.paymentTypeID,
      }
    },
    datatableHeaders() {
      return [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "id",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "ident", sort: "desc"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerid"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_totalsolditems'), value: "saleItems"},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
        {text: this.$t('accounting.lang_paymenthod'), value: "paymentType"},
        {text: this.$t('generic.lang_TableNo'), value: "tableNo", hide: false},
        {text: this.$t('generic.lang_parMaison'), value: "parMaison"},
        {text: this.$t('generic.lang_cashierID'), value: "cashierID"},
        {text: this.$t('generic.lang_receipttype'), value: "type"},
      ];
    },
    excelColumns() {
      return [
        {label: this.$t('generic.lang_id'), field: "ident"},
        {label: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_seller_id'), field: "sellerid"},
        {label: this.$t('generic.lang_customer'), field: "cust"},
        {label: this.$t('erp.lang_totalsolditems'), field: "saleItems"},
        {label: this.$t('erp.lang_ware_total'), field: "total"},
        {label: this.$t('accounting.lang_paymenthod'), field: "paymentType"},
        {label: this.$t('generic.lang_TableNo'), field: "tableNo"},
        {label: this.$t('generic.lang_parMaison'), field: "parMaison"},
        {label: this.$t('generic.lang_cashierID'), field: "cashierID"},
        {label: this.$t('generic.lang_receipttype'), field: "type"},
      ];
    }
  },
  mounted() {
    this.loadDepartments();
    this.loadPaymentTypes();
    this.loadData();
  },
  watch: {
    startDateTime(val) {
      if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() >= new Date(val).getTime()) {
        //this.$refs.salesTable.getDataFromApi();
        //this.loadData();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_before') + " : " + this.endDateTime
        });
        this.startDateTime = "";
      }
    },
    endDateTime(val) {
      if (this.startDateTime != "" && this.startDateTime != null && new Date(this.startDateTime).getTime() <= new Date(val).getTime()) {
        //this.loadData();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_after') + " : " + this.startDateTime
        });
        this.endDateTime = "";
      }
    },
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },
  methods: {
    loadDepartments() {
      this.departments_loader = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTDEPARTMENTS.GET_ALL).then((res) => {
        if (Array.isArray(res.data.results))
          this.departments = res.data.results.map((dp) => ({
            id: dp.id,
            name: dp.departmentName
          }))
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.departments_loader = false;
      })
    },
    loadPaymentTypes() {
      this.payments_loading = true;

      this.axios
        .post(ENDPOINTS.ACCOUNTING.SEARCHINVOICE.GET_PAYMENTTYPES)
        .then((res) => {
          if (res.data.status === true) {
            Object.entries(res.data.payment_types).forEach((row) => {
              this.paymentTypes.push({
                paymentID: row[0],
                paymentName: row[1],
              });
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.payments_loading = false;
        });
    },
    filter() {
      //if (!this.$refs.form.validate()) return;
      this.$refs.salesTable.getDataFromApi();
    },
    loadData() {
      let self = this;
      this.dates_loading = true;
      this.reports_loading = true;

      self.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.REPORTS)
          .then(function (result) {
            let data = result.data;
            let reportOptions = [];

            if (data.reports && data.reports != null && data.reports.length > 0) {
              for (let i = 0; i < data.reports.length; i++) {
                let item = data.reports[i];
                reportOptions.push({text: item[1], value: item[0]});
              }
            }
            self.dailyReportOptions = reportOptions;
          })
          .catch(function (error) {
            Events.$emit("showSnackbar", {
              color: "error",
              message: error.message
            });
          }).finally(() => {
        this.dates_loading = false;
        this.reports_loading = false;
      })
    },
    displayEntry(entry) {
      this.uuid = entry.id;
      this.text = entry.name;
      this.$router.push({name: 'accounting.DisplayInvoice', params: {id: this.uuid}})
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
    downloadSelected(ids){
      if(ids && ids.length>this.pdfSelectLimit){
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_downloadLimit')+' '+this.pdfSelectLimit+' '+this.$t('generic.lang_selected')+' '+ids.length,
          color: "warning"
        });
        this.$refs.salesTable.resetSelectedRows();
        return;
      }
      this.loadingSelectedPDF = true;
      this.axios.post("get/accounting/bulkInvoiceDataPDF/", {
            invoiceUUIDS:ids
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Invoice" + ".pdf");
        this.$refs.salesTable.resetSelectedRows();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSelectedPDF = false;
      })
    },
  },
}
</script>
